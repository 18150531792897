import { FullDossier } from "src/components/FullDossier/FullDossier";
import { Scoring } from "src/components/Scoring/Scoring";
import { ReportCommonProps } from "@entities/types";
import { ReportsScoringRetrieveResponse, ReportStatus } from "@api/report";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { useCallback, useState } from "react";
import { calculateReportStatus } from "../../utils/getReportStatus";
import s from "./RetrieveScoring.module.scss";
import { generatePdf } from "./generatePdf";

export type RetrieveScoringProps = {
  report: ReportsScoringRetrieveResponse;
} & ReportCommonProps;

export const RetrieveScoring = ({
  report,
  type,
  reportId,
}: RetrieveScoringProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);
  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.scoring_result?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <div className={s.container}>
        {report?.request_describe && (
          <FullDossier
            requestDescribe={report?.request_describe}
            type={type}
            handleGeneratePdf={handleGeneratePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
          />
        )}
        <Scoring
          scoringResult={report?.scoring_result}
          status={report?.scoring_result?.status}
        />
      </div>
    </RetrieveStatus>
  );
};
