import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Flex } from "antd";
import { FC, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { PiWarningBold } from "react-icons/pi";
import { Button } from "src/ui/Button/Button";
import { Input } from "src/ui/Input/Input";
import { Text } from "src/ui/Text/Text";
import { validationMessage, validationRegex } from "src/utils/constants";
import * as yup from "yup";
import { Toggle } from "src/ui/Toggle/Toggle";
import {
  Group,
  GroupsAddMemberRequest,
  GroupsGrantUserPermissionsRequest,
  Permission,
  useGroupsAddMember,
} from "@api/groups";
import { useNotification } from "src/hooks/useNotification";
import {
  useGroupsGetPermissions,
  useGroupsGrantUserPermissions,
} from "@api/groups/mutations/useGroupsGrantPermissions";
import { Drawer } from "src/ui/Drawer/Drawer";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { IoIosWarning } from "react-icons/io";
import { generatePassword } from "src/utils/generate/password";
import s from "./AccessesSettings.module.scss";
import { AccessesAddInvite } from "./components/AccessesAddInvite/AccessesAddInvite";
import { AccessesSettingsPermissionReports } from "./components/AccessesSettingsPermissionReports/AccessesSettingsPermissionReports";
import { AccessesSettingsFormValues } from "./types";
import { AllowedIpsInput } from "../AllowedIpsInput/AllowedIpsInput";
import { CommonModal } from "../CommonModal/CommonModal";

const hierarchyEnabled = false;
const analyticsPermissionsEnabled = false;
const pagePermissionsEnabled = false;

const schema = yup
  .object<AccessesSettingsFormValues>()
  .shape({
    group_id: yup.number().required(validationMessage.Required),
    name: yup.string().required(validationMessage.Required),
    login: yup.string().required(validationMessage.Required),
    password: yup
      .string()
      .min(8, validationMessage.MinLen({ min: 8 }))
      .matches(validationRegex.password, validationMessage.WrongPassword)
      .required(validationMessage.Required),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Пароль должен совпасть")
      .required(validationMessage.Required),
    request_quota: yup.number().required(validationMessage.Required),
    ...(Object.fromEntries(
      Object.values(Permission).map((permission) => [
        permission,
        yup.boolean().required(validationMessage.Required),
      ]),
    ) as Record<
      Permission,
      yup.BooleanSchema<
        NonNullable<boolean | undefined>,
        yup.AnyObject,
        undefined,
        ""
      >
    >),
    expires_at: yup.string().required(validationMessage.Required),
    /**
     * TODO remove it
     */
    lol: yup.bool().required(validationMessage.Required),
  })
  .required();

const permissionsSet = new Set<string>(Object.values(Permission));

interface AccessesSettingsProps {
  group: Group;
}

export const AccessesSettings: FC<AccessesSettingsProps> = ({ group }) => {
  const { data: permissionsResponse = [], error: permissionsError } =
    useGroupsGetPermissions({ group_id: group.group_id });
  /**
   * Empty string means that group members already have superuser permissions
   */
  const permissions = permissionsResponse.filter(Boolean);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<AccessesSettingsFormValues>({
    defaultValues: {
      group_id: group.group_id,
      name: "",
      login: "",
      password: "",
      passwordConfirm: "",
      request_quota: 0,
      expires_at: "01.01.9999 00:00:00",
      ...Object.fromEntries(
        permissions.map((permission) => [permission, false]),
      ),
      lol: false,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const login = watch("login");
  const password = watch("password");

  const { mutateAsync: addMember } = useGroupsAddMember();
  const { mutateAsync: addPermissions } = useGroupsGrantUserPermissions();

  const onGeneratePassword = useCallback(async () => {
    const generatedPassword = await generatePassword();

    clearErrors("password");
    clearErrors("passwordConfirm");
    setValue("password", generatedPassword);
    setValue("passwordConfirm", generatedPassword);
  }, [clearErrors, setValue]);

  const [api] = useNotification();

  const navigate = useNavigate();
  const { openModal, closeModal } = useModalMessage();

  const onCancel = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          title="Вы уверены, что хотите выйти без сохранения изменений?"
          icon={<IoIosWarning size={120} />}
          iconSize={120}
          iconWrapperClassName={s.form__icon}
          buttonsVertical
          primaryButtonProps={{
            children: "Да, продолжить",
            type: "primary",
            onClick: async () => {
              navigate(AppRoute.Accesses);
            },
            size: "big",
          }}
          secondaryButtonProps={{
            children: "Отменить",
            type: "flat_secondary",
            onClick: closeModal,
            size: "big",
          }}
        />
      ),
    });
  }, [navigate, openModal, closeModal]);

  const onSubmit = useCallback(
    (values: AccessesSettingsFormValues) => {
      const addMemberRequest: GroupsAddMemberRequest = {
        group_id: values.group_id,
        name: values.name,
        login: values.login,
        password: values.password,
        request_quota: values.request_quota,
        expires_at: values.expires_at,
      };

      addMember(addMemberRequest)
        .then((res) => {
          const grantUserPermissionsRequest: GroupsGrantUserPermissionsRequest =
            {
              group_id: values.group_id,
              user_id: res.user_id,
              permissions: Object.entries(values)
                .filter((el): el is [key: Permission, value: true] =>
                  Boolean(permissionsSet.has(el[0]) && el[1]),
                )
                .map(([permission]) => permission),
            };

          addPermissions(grantUserPermissionsRequest)
            .then(() => {
              api.success({
                key: "add-permission",
                message: "Пользователь успешно создан",
              });
            })
            .catch((error) => {
              api.error({
                key: "add-permission",
                message: "Не удалось добавить роли пользователю",
                description: JSON.stringify(String(error), null, 2),
              });
            });
        })
        .catch(() => {
          api.error({
            key: "add-member",
            message: "Не удалось создать пользователя",
          });
        });
    },
    [addMember, addPermissions, api],
  );

  return (
    <Flex vertical gap={60}>
      <Flex
        vertical
        gap={32}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {hierarchyEnabled && (
          <>
            <Flex vertical gap={16}>
              <Text variant="label-5">Права доступа:</Text>
            </Flex>
            <Flex vertical gap={16}>
              <Text variant="label-5">Под руководством:</Text>
              <Flex gap={32}>
                <div className={s.form__right}>
                  <Controller
                    name="login"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        placeholder="Выбрать"
                        required
                        validate={fieldState}
                      />
                    )}
                  />
                </div>
                <Alert
                  icon={<PiWarningBold size={16} />}
                  showIcon
                  type="warning"
                  message="Оставьте поле не заполненным, если руководитель для этого доступа не нужен"
                />
              </Flex>
            </Flex>
          </>
        )}
        <Flex vertical gap={16}>
          <Text variant="label-5">Данные сотрудника:</Text>
          <Flex gap={32}>
            <div className={s.form__right}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    placeholder="Иванов Иван Иванович"
                    required
                    validate={fieldState}
                  />
                )}
              />
            </div>
          </Flex>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-5">Логин:</Text>
          <div className={s.form__right}>
            <Controller
              name="login"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  placeholder="Логин"
                  required
                  validate={fieldState}
                />
              )}
            />
          </div>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-5">Пароль:</Text>
          <Flex gap={32}>
            <div className={s.form__right}>
              <Flex vertical gap={16}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      placeholder="Пароль"
                      required
                      validate={fieldState}
                    />
                  )}
                />
                <Controller
                  name="passwordConfirm"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      placeholder="Повторите пароль"
                      required
                      type="password"
                      validate={fieldState}
                    />
                  )}
                />
                <Button
                  size="small"
                  onClick={onGeneratePassword}
                  type="secondary"
                >
                  Сгенерировать случайный пароль
                </Button>
              </Flex>
            </div>
            <Alert
              icon={<PiWarningBold size={16} />}
              showIcon
              type="warning"
              message="Пароль должен содержать *Не менее 8 символов, верхний регистр, числа или символы*"
            />
          </Flex>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-5">Доступ по IP:</Text>
          <Flex gap={32}>
            <div className={s.form__right}>
              <AllowedIpsInput group={group} />
            </div>
            <Alert
              icon={<PiWarningBold size={16} />}
              showIcon
              type="warning"
              message="Введите IP через запятую, если хотите использовать несколько"
            />
          </Flex>
        </Flex>
        <Flex vertical gap={16}>
          <Text variant="label-5">Лимит использования проверок в месяц:</Text>
          <div className={s.form__right}>
            <Controller
              name="request_quota"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  placeholder="Введите количество"
                  required
                  validate={fieldState}
                />
              )}
            />
          </div>
        </Flex>
        {permissions.length || permissionsError ? (
          <Flex vertical gap={16}>
            <Text variant="label-5">Доступы к проверкам:</Text>
            <div className={s.form__right}>
              {permissionsError ? (
                <Text color="red">
                  Не удалось получить список доступов группы
                </Text>
              ) : (
                <AccessesSettingsPermissionReports
                  group={group}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                />
              )}
            </div>
          </Flex>
        ) : null}
        {analyticsPermissionsEnabled && (
          <>
            <Flex vertical gap={16}>
              <Text variant="label-5">Доступы к аналитике:</Text>
              <Flex gap={32}>
                <div className={s.form__right}>
                  <Controller
                    name="login"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        placeholder="IP адрес"
                        required
                        validate={fieldState}
                      />
                    )}
                  />
                </div>
              </Flex>
            </Flex>
            <Flex vertical gap={16}>
              <Text variant="label-5">Разделы аналитики:</Text>
              <Flex className={s.form__right} vertical gap={8}>
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Все проверки"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="По типам проверки"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Работа с проверками"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Проверки сотрудников"
                    />
                  )}
                />
                <Controller
                  name="lol"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Toggle
                      className={s.checkbox_extended}
                      {...field}
                      validate={fieldState}
                      title="Задачи сотрудников"
                    />
                  )}
                />
              </Flex>
            </Flex>
          </>
        )}
        {pagePermissionsEnabled && (
          <Flex vertical gap={16}>
            <Text variant="label-5">Доступ к страница:</Text>
            <Flex className={s.form__right} vertical gap={8}>
              <Controller
                name="lol"
                control={control}
                render={({ field, fieldState }) => (
                  <Toggle
                    className={s.checkbox_extended}
                    {...field}
                    validate={fieldState}
                    title="Мониторинг сотрудников"
                  />
                )}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <AccessesAddInvite
        login={errors.login ? "" : login}
        password={errors.password ? "" : password}
      />
      <Drawer
        placement="bottom"
        visible
        title={null}
        closable={false}
        height="78"
        mask={false}
      >
        <Flex gap={32} justify="flex-end" className={s.form__drawer}>
          <Button type="secondary" onClick={onCancel}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Создать
          </Button>
        </Flex>
      </Drawer>
    </Flex>
  );
};
