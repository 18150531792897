import { VscSettings } from "react-icons/vsc";
import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import { AppRoute } from "../../../app/routes/constants";
import { Button } from "../../../ui/Button/Button";
import s from "./Header.module.scss";

interface HeaderProps {
  openModalSettings: () => void;
}

export const Header = ({ openModalSettings }: HeaderProps) => (
  <div className={s.container}>
    <PageHeader
      title="Аналитика"
      mapPathToTitle={{ [AppRoute.Analytics]: "Аналитика" }}
      paths={[AppRoute.Analytics]}
    />

    <Button
      type="secondary"
      className={s.container__button}
      onClick={openModalSettings}
    >
      <VscSettings />
      Настроить временной отрезок
    </Button>
  </div>
);
