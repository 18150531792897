import { FC, useCallback, useMemo } from "react";
import { Flex, Image, Upload } from "antd";
import { Text } from "src/ui/Text/Text";
import { CurrentUser, EditMemberRequest, useUsersEdit } from "@api/groups";
import { FaPen } from "react-icons/fa";
import { Button } from "src/ui/Button/Button";
import { IoClose } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import { Input } from "src/ui/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationMessage } from "src/utils/constants";
import * as yup from "yup";
import { useBooleanState } from "src/hooks/useBooleanState";
import cn from "classnames";
import { useUsersProfilePictureRetrieveQuery } from "@api/groups/mutations/useUsersProfilePictureRetrieveQuery";
import { useNotification } from "src/hooks/useNotification";
import { useUsersProfilePictureMutation } from "@api/groups/mutations/useUsersProfilePictureMutation";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { FaCircleUser } from "react-icons/fa6";
import { useUsersProfilePictureDeleteMutation } from "@api/groups/mutations/useUsersProfilePictureDeleteMutation";
import s from "./UserCard.module.scss";

const schema = yup
  .object<EditMemberRequest>()
  .shape({
    user_id: yup.number().required(validationMessage.Required),
    name: yup.string().required(validationMessage.Required),
    expires_at: yup.string().required(validationMessage.Required),
  })
  .required();

interface UserCardViewProps {
  user: CurrentUser;
  photo?: string;
}

const UserCardView: FC<UserCardViewProps> = ({ user, photo }) => {
  const { login, name } = user;

  return (
    <Flex gap={12}>
      {photo ? (
        <Image
          rootClassName={s.card__avatar}
          preview={false}
          src={photo}
          alt={`${user.id} avatar`}
        />
      ) : (
        <FaCircleUser className={s.card__icon} />
      )}
      <Flex vertical gap={8}>
        <Text variant="subheader-4">{login}</Text>
        <Text variant="body-5">{name}</Text>
      </Flex>
    </Flex>
  );
};

interface UserCardEditProps {
  user: CurrentUser;
  photo?: string;
  onView: () => void;
}

const UserCardEdit: FC<UserCardEditProps> = ({ user, photo, onView }) => {
  const { name, id: user_id, expires_at } = user;
  const { control, handleSubmit } = useForm<EditMemberRequest>({
    defaultValues: {
      user_id,
      name,
      expires_at,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { mutateAsync: editUser } = useUsersEdit();
  const { mutateAsync: editPhoto } = useUsersProfilePictureMutation();
  const { mutateAsync: deletePhoto } = useUsersProfilePictureDeleteMutation();

  const [api] = useNotification();

  const onChangeUser = useCallback(
    (data: EditMemberRequest) => {
      editUser(data)
        .then(() => {
          onView();

          api.success({
            message: "Пользователь успешно отредактирован",
          });
        })
        .catch((error) => {
          handleBackendErrors({
            api,
            error,
            message4xx: "Не удалось отредактировать пользователя",
          });
        });
    },
    [editUser, onView, api],
  );

  const onDeletePhoto = useCallback(async () => {
    await deletePhoto({ user_id })
      .then(() => {
        api.success({
          message: "Аватар успешно удалён",
        });
      })
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось удалить аватар",
        });
      });
  }, [user_id, api, deletePhoto]);

  const onEditPhoto = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", String(user.id));

      editPhoto(formData)
        .then(() => {
          api.success({
            message: "Аватар успешно загружен",
          });
        })
        .catch((error) => {
          handleBackendErrors({
            api,
            error,
            message4xx: "Не удалось загрузить аватар",
          });
        });

      return false; // Prevent default upload behavior
    },
    [user, api, editPhoto],
  );

  const uploadProps = useMemo(
    () => ({
      beforeUpload: onEditPhoto,
      showUploadList: false,
      accept: "image/png, image/jpeg",
    }),
    [onEditPhoto],
  );

  return (
    <form className={s.card_edit} onSubmit={handleSubmit(onChangeUser)}>
      <Flex gap={12} vertical>
        <Flex gap={16} align="center">
          <Upload {...uploadProps}>
            {photo ? (
              <Image
                rootClassName={s.card__avatar}
                preview={false}
                src={photo}
                alt={`${user.id} avatar`}
              />
            ) : (
              <FaCircleUser className={s.card__icon} />
            )}
          </Upload>
          <Upload {...uploadProps}>
            <Button type="text">
              <Text variant="body-5">Заменить</Text>
            </Button>
          </Upload>
          <Button type="text" onClick={onDeletePhoto}>
            <Text variant="body-5" color="darkGray">
              Удалить
            </Text>
          </Button>
        </Flex>
        {/* <Flex vertical gap={16}>
                <Text variant='subheader-4'>Логин</Text>
                <Controller
                    name="login"
                    control={control}
                    render={({ field, fieldState }) => (
                        <Input
                            className={s.input}
                            {...field}
                            placeholder="Логин"
                            // required
                            disabled
                            validate={fieldState}
                        />
                    )}
                />
            </Flex> */}
        <Flex vertical gap={16}>
          <Text variant="subheader-4">Имя</Text>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                {...field}
                placeholder="Имя"
                required
                validate={fieldState}
              />
            )}
          />
        </Flex>
        <Button size="small" htmlType="submit">
          Сохранить
        </Button>
      </Flex>
    </form>
  );
};

interface UserCardProps {
  user: CurrentUser;
  editable?: boolean;
  type: "flat" | "border-box";
  size: "s" | "m";
}

export const UserCard: FC<UserCardProps> = ({ user, editable, type, size }) => {
  const { state: isEdit, toggle } = useBooleanState(false);

  const { data: photo } = useUsersProfilePictureRetrieveQuery({
    user_id: user.id,
  });

  return (
    <Flex
      className={cn(s.card, s[`card_type_${type}`], s[`card_size_${size}`])}
    >
      {editable ? (
        <Button
          type="flat"
          pin="round"
          onClick={toggle}
          className={s.card_button}
        >
          {isEdit ? <IoClose /> : <FaPen />}
        </Button>
      ) : null}
      {isEdit ? (
        <UserCardEdit user={user} onView={toggle} photo={photo} />
      ) : (
        <UserCardView user={user} photo={photo} />
      )}
    </Flex>
  );
};
