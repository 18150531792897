import React, { FC, PropsWithChildren } from "react";
import { Drawer as DrawerComponent } from "antd";
import cn from "classnames";

import s from "./Drawer.module.scss";

interface DrawerProps {
  visible: boolean;
  onClose?: () => void;
  placement: "left" | "right" | "top" | "bottom";
  afterOpenChange?: (_open: boolean) => void;
  title?: React.ReactNode;
  closable?: boolean;
  width?: number | string;
  height?: number | string;
  mask?: boolean;
}

export const Drawer: FC<PropsWithChildren<DrawerProps>> = ({
  children,
  placement = "bottom",
  afterOpenChange,
  title,
  visible,
  onClose,
  closable,
  height,
  width,
  mask,
}) => (
  <DrawerComponent
    rootClassName={cn(s.drawer)}
    classNames={{
      body: s.body,
    }}
    afterOpenChange={afterOpenChange}
    placement={placement}
    open={visible}
    onClose={onClose}
    title={title}
    closable={closable}
    height={height}
    width={width}
    mask={mask}
  >
    {children}
  </DrawerComponent>
);
