import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Button } from "src/ui/Button/Button";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import cn from "classnames";
import s from "./Pagination.module.scss";

interface PaginationProps {
  pagesCount: number;
  page: number;
  onUpdate: (newPage: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  pagesCount,
  page,
  onUpdate,
}) => {
  const onBack = useCallback(() => {
    onUpdate(page - 1);
  }, [page, onUpdate]);

  const onForward = useCallback(() => {
    onUpdate(page + 1);
  }, [page, onUpdate]);

  return (
    <Flex gap={6} align="center">
      <Button type="flat" pin="round" onClick={onBack} disabled={page <= 1}>
        <LuChevronLeft className={s.button} />
      </Button>
      {Array.from({ length: pagesCount }).map((_v, pageIdx) => (
        <Button
          // Количесто на момент рендера фиксировано
          // eslint-disable-next-line react/no-array-index-key
          key={pageIdx}
          type="flat"
          pin="round"
          className={cn(s.page_button, {
            [s.page_button_active]: page === pageIdx + 1,
          })}
          onClick={() => {
            onUpdate(pageIdx + 1);
          }}
        >
          {pageIdx + 1}
        </Button>
      ))}
      <Button
        type="flat"
        pin="round"
        onClick={onForward}
        disabled={page <= 1 || page >= pagesCount}
      >
        <LuChevronRight className={s.button} />
      </Button>
    </Flex>
  );
};
