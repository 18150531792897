import { ESortType } from "@pages/Employees/types";

export const sortingRulesOrder: ESortType[] = [
  ESortType.ascend,
  ESortType.descend,
  ESortType.updateDateAscend,
  ESortType.updateDateDescend,
];

export const sortingRules: Record<ESortType, string> = {
  [ESortType.ascend]: "Сортировать А — Я",
  [ESortType.descend]: "Сортировать Я — А",
  [ESortType.updateDateAscend]: "По дате обновления, сначала новые",
  [ESortType.updateDateDescend]: "По дате обновления, сначала старые",
};
