import { Modal, Flex } from "antd";
import { Button } from "src/ui/Button/Button";
import { IoMdDocument } from "react-icons/io";
import { Text } from "src/ui/Text/Text";
import { DropZone, DropZoneFile } from "src/ui/DropZone/DropZone";
import { useCallback } from "react";
import styles from "./SOPDModal.module.scss";

interface SOPDModalProps {
  open: boolean;
  onClose: () => void;
  fileList: DropZoneFile[];
  onFileListChange: (fileList: DropZoneFile[]) => void;
}

export const SOPDModal = ({
  open,
  onClose,
  fileList,
  onFileListChange,
}: SOPDModalProps) => {
  const file = fileList?.[0]?.originFileObj;

  const onClear = useCallback(() => {
    onFileListChange([]);
  }, [onFileListChange]);

  return (
    <Modal
      title={<Text variant="header-6">Загрузка СОПД</Text>}
      open={open}
      className={styles.modal}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button
          key="clear"
          type="flat_secondary"
          size="small"
          onClick={onClear}
        >
          Очистить
        </Button>,
        <Button key="submit" type="primary" size="small" onClick={onClose}>
          Применить
        </Button>,
      ]}
    >
      <Flex vertical gap={12}>
        <Text color="darkGray">
          Загрузите файл в формате PDF подтверждающий согласие на обработку
          персональных данных
        </Text>
        <DropZone
          value={fileList}
          onChange={onFileListChange}
          accept=".pdf"
          multiple={false}
        >
          <Flex align="center" vertical gap={24} className={styles.dropZone}>
            <Flex align="center" vertical gap={16}>
              <IoMdDocument size={48} className={styles.icon} />
              <Text color="aqua" variant="body-6">
                {file
                  ? file.name
                  : "Загрузите или перетащите файл в формате: PDF"}
              </Text>
            </Flex>
            <Button type="flat">
              <Text variant="body-4" color="green">
                {file ? "Поменять файл" : "Выбрать файл"}
              </Text>
            </Button>
          </Flex>
        </DropZone>
      </Flex>
    </Modal>
  );
};
