import dayjs from "dayjs";

export function toHumanReadableDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);

    return date.format("DD.MM.YYYY в HH:mm");
  } catch {
    return "";
  }
}
