import { useParams } from "react-router-dom";
import { ReportStatus, useReportsDriverLicenseRetrieve } from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { useEffect, useState } from "react";

export const SearchDriverLicenseWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: driverLicenseReport, isLoading } =
    useReportsDriverLicenseRetrieve({
      id: reportId,
      status,
    });

  useEffect(
    () => setStatus(driverLicenseReport?.status),
    [driverLicenseReport?.status],
  );

  return (
    <Skeleton loading={isLoading}>
      {driverLicenseReport && (
        <SearchFull
          report={driverLicenseReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
