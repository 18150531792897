import cn from "classnames";
import { Button } from "src/ui/Button/Button";
import { Flex } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import s from "./PhotoGallery.module.scss";
import { PhotoGalleryModal } from "./PhotoGalleryModal/PhotoGalleryModal";
import { GalleryItem } from "./types";

const VIEW_PARAM = "view";
const VIEW_PARAM_GALLERY = "gallery";

export interface PhotoGalleryProps {
  photos: GalleryItem[];
}

export const PhotoGallery = ({ photos }: PhotoGalleryProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = useMemo(() => {
    const result = [...searchParams.entries()];
    result.push([VIEW_PARAM, VIEW_PARAM_GALLERY]);
    return new URLSearchParams(result);
  }, [searchParams]);

  const openGallery = useCallback(() => {
    setSearchParams((currSearchParams) => {
      currSearchParams.append(VIEW_PARAM, VIEW_PARAM_GALLERY);
      return currSearchParams;
    });
  }, [setSearchParams]);

  const closeGallery = useCallback(() => {
    setSearchParams((currSearchParams) => {
      currSearchParams.delete(VIEW_PARAM);
      return currSearchParams;
    });
  }, [setSearchParams]);

  if (!photos.length) {
    return null;
  }

  return (
    <Flex vertical gap={18} align="flex-start">
      <Link to={`${location.pathname}?${newSearchParams.toString()}`}>
        <Flex vertical gap={18}>
          <div className={s.images}>
            {photos[0]?.faces[0] && (
              <img
                src={photos[0].faces[0]}
                alt="Some"
                className={cn(s.images__image, s.images__image_first)}
              />
            )}
            {photos[1]?.faces[1] && (
              <img
                src={photos[1].faces[1]}
                alt="Some"
                className={cn(s.images__image, s.images__image_second)}
              />
            )}
            {photos[2]?.faces[2] && (
              <img
                src={photos[2].faces[2]}
                alt="Some"
                className={cn(s.images__image, s.images__image_third)}
              />
            )}
          </div>
          <Button type="text_secondary" onClick={openGallery}>
            {photos.length}+ Смотреть
          </Button>
        </Flex>
      </Link>
      <PhotoGalleryModal
        onClose={closeGallery}
        open={searchParams.get(VIEW_PARAM) === VIEW_PARAM_GALLERY}
        photos={photos}
      />
    </Flex>
  );
};
