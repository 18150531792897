import React from "react";
import { ReportStatus } from "@api/report";
import { FaCheckCircle } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { ReactComponent as Loading } from "src/assets/loading.svg";
import { Text } from "src/ui/Text/Text";
import cn from "classnames";
import s from "./StatusPaper.module.scss";

export interface StatusPaperProps {
  status: ReportStatus;
}

export const StatusPaper = ({ status }: StatusPaperProps) => (
  <div
    className={cn(
      s.container,
      status === ReportStatus.Finished && s.container_finished,
      status === ReportStatus.InProgress && s.container_in_progress,
      status === ReportStatus.Canceled && s.container_canceled,
      status === ReportStatus.NoData && s.container_no_data,
    )}
  >
    {status === ReportStatus.Finished && <FaCheckCircle />}
    {status === ReportStatus.InProgress && (
      <Loading className={s.container__loading} />
    )}
    {status === ReportStatus.Canceled && <IoWarning />}
    {status === ReportStatus.NoData && <FaCheckCircle />}

    <Text variant="subheader-4">
      {status === ReportStatus.Finished && "Отчет полностью сформирован"}
      {status === ReportStatus.InProgress &&
        "В процессе. Сбор некоторых данных может занять несколько минут, данные появятся автоматически."}
      {status === ReportStatus.Canceled &&
        "Что-то пошло не так. Нам не удалось найти некоторые данные, проверьте правильность введенного запроса"}
      {status === ReportStatus.NoData && "Информация не найдена"}
    </Text>
  </div>
);
