import { useCallback, useState, FC, useEffect, useRef, useMemo } from "react";
import { Flex, List } from "antd";
import { Input } from "src/ui/Input/Input";
import {
  Report,
  ReportStatus,
  useReportsRetrieveAll,
  useReportsTotalCount,
} from "@api/report";
import { Pagination } from "src/ui/Pagination/Pagination";
import debounce from "lodash/debounce";
import {
  ENavMenuItem,
  MainTab,
  mapMainTabToReportTypes,
} from "@pages/Main/constants";
import { motion, AnimatePresence } from "framer-motion";
import { HistoryListCard } from "./components/HistoryListCard/HistoryListCard";
import s from "./HistoryList.module.scss";
import { getReportStatus } from "../../utils/getReportStatus";

const DURATION_MS = 500;

interface HistoryListViewProps {
  reports: Report[];
}

const HistoryListView: FC<HistoryListViewProps> = ({ reports }) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated(true);

    setTimeout(() => {
      setIsAnimated(false);
    }, DURATION_MS);
  }, []);

  if (!isAnimated) {
    return (
      <>
        {reports.map((report) => (
          <List.Item key={report.id}>
            <HistoryListCard key={report.id} report={report} />
          </List.Item>
        ))}
      </>
    );
  }

  return (
    <AnimatePresence>
      {reports.map((report) => (
        <motion.li
          key={report.id}
          layout
          transition={{ ease: "easeOut", duration: DURATION_MS / 1_000 }}
          initial={{ opacity: 0, y: -215 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 215 }}
        >
          <HistoryListCard key={report.id} report={report} />
        </motion.li>
      ))}
    </AnimatePresence>
  );
};

interface HistoryListProps {
  withPagination?: boolean;
  limit?: number;
  tab: MainTab | ENavMenuItem | null;
}

export const HistoryList: FC<HistoryListProps> = ({
  withPagination = false,
  limit = 5,
  tab,
}) => {
  const [filterUi, setFilterUi] = useState("");
  const [filter, setFilter] = useState("");
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const prevFilter = useRef<string>("");
  const request_contains = filter || undefined;

  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
      if (value !== prevFilter.current) {
        setPage(1);
      }
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const { data: countReports, isSuccess: isReportsTotalCountSuccess } =
    useReportsTotalCount(
      {
        request_contains,
      },
      {
        enabled: withPagination,
      },
    );
  const { data: reports, isSuccess: isReportsSuccess } = useReportsRetrieveAll({
    limit,
    offset: Number.isInteger(offset) ? offset : undefined,
    request_contains,
    report_task_types: tab
      ? mapMainTabToReportTypes[tab] || undefined
      : undefined,
    isInProgress,
  });

  useEffect(() => {
    const reportInProgress = reports?.find(
      (report) =>
        getReportStatus({
          sourceStatuses: report?.source_statuses,
          found: report?.found,
        }) === ReportStatus.InProgress,
    );

    setIsInProgress(Boolean(reportInProgress));
  }, [reports]);

  const pagination = useMemo(() => {
    if (
      withPagination &&
      isReportsTotalCountSuccess &&
      countReports.total_count
    ) {
      const pagesCount = Math.ceil(countReports.total_count / limit);

      if (pagesCount < 2) {
        return null;
      }

      return (
        <Pagination pagesCount={pagesCount} page={page} onUpdate={setPage} />
      );
    }

    return null;
  }, [
    withPagination,
    isReportsTotalCountSuccess,
    countReports?.total_count,
    limit,
    page,
  ]);

  return (
    <Flex vertical gap={32}>
      <Input
        placeholder="Поиск по истории"
        value={filterUi}
        onUpdate={onFilterUpdate}
      />
      <Flex vertical gap={16} component="ul" className={s.list}>
        {isReportsSuccess && reports ? (
          <HistoryListView reports={reports} />
        ) : (
          Array.from({ length: 15 }).map((_v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <List.Item key={i}>
              <HistoryListCard isLoading />
            </List.Item>
          ))
        )}
      </Flex>
      {pagination}
    </Flex>
  );
};
