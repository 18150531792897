import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { AxiosError } from "axios";
import { UsersSetSuspendedRequest, UsersSetSuspendedResponse } from "../types";
import { usersSetSuspended } from "../queries";

export const useUsersSetSuspendedMutation = () =>
  useMutation<UsersSetSuspendedResponse, AxiosError, UsersSetSuspendedRequest>({
    mutationKey: ["currentUser"],
    mutationFn: (data: UsersSetSuspendedRequest) => usersSetSuspended(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
