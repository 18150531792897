import { ChangeEventHandler, FC, forwardRef, useCallback, useId } from "react";
import {
  Input as AntdInput,
  InputProps as AntdInputProps,
  InputRef,
} from "antd";
import cn from "classnames";
import { MaskedInput } from "antd-mask-input";
import { ControllerFieldState } from "react-hook-form";
import { parseError } from "src/utils/parseError";
import styles from "./Input.module.scss";

const DEFINITIONS_DEFAULT = {
  "*": /^[A-Za-z0-9]+$/,
};

interface InputProps extends AntdInputProps {
  validate?: Partial<ControllerFieldState>;
  className?: string;
  mask?: string | RegExp;
  label?: string;
  supportingText?: string;
  onUpdate?: (value: string) => void;
  isError?: boolean;
  definitions?: Record<string, string>;
}

export const Input: FC<InputProps> = forwardRef<InputRef, InputProps>(
  ({ onUpdate, isError, ...props }, ref) => {
    const {
      validate,
      className,
      mask,
      label,
      supportingText,
      disabled,
      variant = "filled",
      placeholder,
      onChange,
      required,
      definitions = DEFINITIONS_DEFAULT,
    } = props;
    const { error } = validate || {};
    const id = useId();

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        e.target.value = e.target.value.trim();
        if (e.currentTarget !== e.target) {
          e.currentTarget.value = e.currentTarget.value.trim();
        }
        onChange?.(e);
        onUpdate?.(e.target.value);
      },
      [onUpdate, onChange],
    );

    return (
      <div
        className={cn(
          styles.input_wrapper,
          { disabled, [styles.input_wrapper_placeholder]: placeholder },
          className,
        )}
      >
        {mask ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <MaskedInput
            mask={mask}
            definitions={definitions}
            {...props}
            ref={ref}
            onChange={handleChange}
            variant={variant}
            className={cn(styles.className, styles.input, {
              [styles.input_wrapper_error]: isError || error,
            })}
            id={id}
          />
        ) : (
          <AntdInput
            {...props}
            ref={ref}
            onChange={handleChange}
            variant={variant}
            className={cn(props.className, styles.input, {
              [styles.input_wrapper_error]: isError || error,
            })}
            id={id}
          />
        )}
        {placeholder && (
          <label
            htmlFor={id}
            className={cn({ [styles.input_required]: required })}
          >
            {placeholder}
          </label>
        )}
        {label && <span className={styles.error}>{label}</span>}
        {error && (
          <span className={styles.input_error_text}>{parseError(error)}</span>
        )}
        {supportingText && (
          <span className={styles.supporting_text}>{supportingText}</span>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
