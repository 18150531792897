import { Text } from "src/ui/Text/Text";
import { TelegramUserBotStatus } from "@api/telegram/types";
import classNames from "classnames";
import { Flex } from "antd";
import styles from "./Status.module.scss";

enum TelegramUserBotStatusInner {
  AwaitingApproval = "AWAITING_APPROVAL",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Declined = "DECLINED",
}

const mapTelegramUserBotStatusToLabel: Record<
  TelegramUserBotStatusInner,
  string
> = {
  [TelegramUserBotStatusInner.Active]: "Активный",
  [TelegramUserBotStatusInner.Inactive]: "Отключен",
  [TelegramUserBotStatusInner.AwaitingApproval]: "Не подтвержден",
  [TelegramUserBotStatusInner.Declined]: "Отклонен",
};

const mapTelegramUserBotStatusToInner: Record<
  TelegramUserBotStatus,
  TelegramUserBotStatusInner
> = {
  [TelegramUserBotStatus.Approved]: TelegramUserBotStatusInner.Inactive,
  [TelegramUserBotStatus.AwaitingApproval]:
    TelegramUserBotStatusInner.AwaitingApproval,
  [TelegramUserBotStatus.Declined]: TelegramUserBotStatusInner.Declined,
};

interface StatusProps {
  status: TelegramUserBotStatus;
  active: boolean;
}

export const Status = ({ status, active }: StatusProps) => {
  let innerStatus = mapTelegramUserBotStatusToInner[status];
  if (status === TelegramUserBotStatus.Approved && active) {
    innerStatus = TelegramUserBotStatusInner.Active;
  }

  return (
    <Flex
      className={classNames(
        styles.container,
        styles[`container_type_${innerStatus}`],
      )}
      justify="center"
      align="center"
    >
      <Text variant="body-2">
        {mapTelegramUserBotStatusToLabel[innerStatus]}
      </Text>
    </Flex>
  );
};
