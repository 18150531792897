import { Text } from "src/ui/Text/Text";
import {
  crossingBorderItemEnumOrder,
  crossingBorderItemTitles,
  CrossingBorders,
  CrossingBordersSummaryKeys,
  crossingBordersSummaryTexts,
  summaryKeys,
} from "@api/report";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import s from "./CrossingBordersSection.module.scss";
import { SummaryDetail } from "./SummaryDetail/SummaryDetail";
import { CrossingBordersTable } from "./CrossingBordersTable/CrossingBordersTable";

type CrossingBordersProps = {
  crossingBorders?: CrossingBorders;
} & RetrieveCardCommonProps;

export const CrossingBordersSection = ({
  crossingBorders,
  status,
}: CrossingBordersProps) => (
  <RetrieveCard title="Пересечение границы" status={status}>
    <div className={s.container}>
      <div className={s.container__summary}>
        <Text variant="subheader-4">Краткая сводка</Text>
        <div className={s.container__summary__table}>
          {summaryKeys.map((summaryKey: CrossingBordersSummaryKeys) => (
            <div className={s.container__summary__table__item} key={summaryKey}>
              <Text variant="subheader-3" color="darkGray">
                {crossingBordersSummaryTexts[summaryKey]}
              </Text>
              <SummaryDetail
                summaryKey={summaryKey}
                summary={crossingBorders?.result[summaryKey]}
              />
            </div>
          ))}
        </div>
      </div>
      {crossingBorders?.result.crossing_border_items && (
        <CrossingBordersTable
          headersOrder={crossingBorderItemEnumOrder}
          headers={crossingBorderItemTitles}
          data={crossingBorders.result.crossing_border_items}
          limit={4}
        />
      )}
    </div>
  </RetrieveCard>
);
