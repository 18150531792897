export enum ReportStatus {
  InProgress = "IN_PROGRESS",
  Canceled = "CANCELED",
  Finished = "FINISHED",
  NoData = "NO_DATA",
}

export enum FoundStatus {
  found = 1,
  notFound = 0,
  error = -1,
}

export enum OpenSourcesCategories {
  bank_ruptcy_check = "Банкротство",
  disqualified_check = "Дисквалификация",
  enforcement_proceedings_check = "Наличие исполнительного производства",
  inn_check = "ИНН",
  ip_check = "ИП",
  self_employment_check = "Самозанятость",
  ul_check = "Участие в ЮЛ",
  ul_restriction_check = "Ограничения в ЮЛ",
}

export enum ReportSource {
  Web = "WEB",
  Api = "API",
  Telegram = "TELEGRAM",
}

export enum ReportType {
  SEARCH_FIO = "SEARCH_FIO",
  SEARCH_PHONE = "SEARCH_PHONE",
  SEARCH_CREDIT_REPORT = "SEARCH_CREDIT_REPORT",
  SEARCH_FULL_CREDIT_REPORT = "SEARCH_FULL_CREDIT_REPORT",
  SEARCH_INN_UL = "SEARCH_INN_UL",
  SEARCH_INN_FL = "SEARCH_INN_FL",
  SEARCH_EMAIL = "SEARCH_EMAIL",
  SEARCH_VIN = "SEARCH_VIN",
  SEARCH_OGRN = "SEARCH_OGRN",
  SEARCH_SNILS = "SEARCH_SNILS",
  SEARCH_PASSPORT = "SEARCH_PASSPORT",
  SEARCH_PHOTO_BY_IMAGE = "SEARCH_PHOTO_BY_IMAGE",
  SEARCH_ADDRESS = "SEARCH_ADDRESS",
  SEARCH_KAD_NUMBER = "SEARCH_KAD_NUMBER",
  SEARCH_GRN = "SEARCH_GRN",
  SEARCH_DRIVER_LICENSE = "SEARCH_DRIVER_LICENSE",
  SEARCH_INFO = "SEARCH_INFO",
  SEARCH_SCORING = "SEARCH_SCORING",
}
