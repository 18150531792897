import { ReportStatus } from "@api/report";
import { ReactComponent as Loading } from "src/assets/loading.svg";
import { ReactComponent as Cross } from "src/assets/cross.svg";
import { ReactComponent as Finished } from "src/assets/finished.svg";
import { Text } from "src/ui/Text/Text";
import s from "./Status.module.scss";

interface StatusProps {
  reportStatus?: ReportStatus | null;
}

export const Status = ({ reportStatus }: StatusProps) => (
  <div className={s.container}>
    {reportStatus === ReportStatus.InProgress && (
      <>
        <Loading className={s.container__loading} />
        <Text variant="subheader-4">В процессе сбора данных</Text>
      </>
    )}
    {reportStatus === ReportStatus.Canceled && (
      <>
        <Cross />
        <Text variant="subheader-4">Отчет не сформирован</Text>
      </>
    )}
    {reportStatus === ReportStatus.Finished && (
      <>
        <Finished />
        <Text variant="subheader-4">Полностью сформирован</Text>
      </>
    )}
    {reportStatus === ReportStatus.NoData && (
      <>
        <Finished />
        <Text variant="subheader-4">Информация не найдена</Text>
      </>
    )}
  </div>
);
