import { FC, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { env } from "src/utils/env";
import { router } from "src/app/routes/router";

export const App: FC = () => {
  useEffect(() => {
    document.documentElement.dataset.theme = env.REACT_APP_THEME;
  });

  return <RouterProvider router={router} />;
};
