import { FC, MouseEvent, useCallback, useState } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Score } from "src/components/Score/Score";
import {
  IoArrowForwardOutline,
  IoArrowBackOutline,
  IoCloseSharp,
} from "react-icons/io5";
import classNames from "classnames";
import { GalleryItem } from "../types";
import s from "./PhotoGalleryModal.module.scss";

export interface PhotoGalleryModalProps {
  photos: GalleryItem[];
  open: boolean;
  onClose: () => void;
}

export const PhotoGalleryModal: FC<PhotoGalleryModalProps> = ({
  photos,
  open,
  onClose,
}) => {
  const onImageInner = useCallback((e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
  }, []);

  const [photoIdx, setPhotoIdx] = useState(0);
  const [faceIdx, setFaceIdx] = useState(0);

  const goBackPhoto = useCallback(() => {
    setPhotoIdx(
      (currPhotoIdx) => (currPhotoIdx - 1 + photos.length) % photos.length,
    );
  }, [photos.length]);

  const goForwardPhoto = useCallback(() => {
    setPhotoIdx((currPhotoIdx) => (currPhotoIdx + 1) % photos.length);
  }, [photos.length]);

  if (!open) {
    return null;
  }

  const photoSrc = photos[photoIdx];
  const src = photoSrc?.faces[faceIdx];

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={s.modal} onClick={onClose}>
      <Flex vertical gap={28} className={s.modal__inner} onClick={onImageInner}>
        <Flex className={s.modal__container} align="center" justify="center">
          <div className={s.modal__image}>
            <button
              type="button"
              aria-label="back"
              onClick={onClose}
              className={s.modal__imageActions}
            >
              <Flex
                className={s.modal__imageAction}
                align="center"
                justify="center"
                vertical
              >
                <IoCloseSharp size={20} />
              </Flex>
            </button>
            <img src={src} className={s.modal__imageImg} alt={src} />
            <button
              type="button"
              aria-label="back"
              onClick={goBackPhoto}
              className={classNames(
                s.modal__imageNavigate,
                s.modal__imageNavigate_back,
              )}
            >
              <Flex
                className={classNames(
                  s.modal__imageArrow,
                  s.modal__imageArrow_back,
                )}
                align="center"
                justify="center"
                vertical
              >
                <IoArrowBackOutline size={16} />
              </Flex>
            </button>
            <button
              type="button"
              aria-label="forward"
              onClick={goForwardPhoto}
              className={classNames(
                s.modal__imageNavigate,
                s.modal__imageNavigate_forward,
              )}
            >
              <Flex
                className={classNames(
                  s.modal__imageArrow,
                  s.modal__imageArrow_forward,
                )}
                align="center"
                justify="center"
                vertical
              >
                <IoArrowForwardOutline size={16} />
              </Flex>
            </button>
            <Flex
              className={s.modal__imageDescription}
              align="center"
              justify="center"
              vertical
              gap={16}
            >
              <Flex gap={16} align="center">
                <Score score={photoSrc.score || 0} />
                <Text variant="label-3" color="white" align="center">
                  {photoSrc.description || "нет описания"}
                </Text>
              </Flex>
            </Flex>
          </div>
        </Flex>
        <Flex className={s.modal__previews} gap={16}>
          {photos.map(({ faces }, photoI) =>
            faces.map((face, faceI) => (
              <button
                onClick={() => {
                  setPhotoIdx(photoI);
                  setFaceIdx(faceI);
                }}
                key={face}
                type="button"
                className={s.modal__preview}
              >
                <img src={face} className={s.modal__previewImg} alt={face} />
              </button>
            )),
          )}
        </Flex>
      </Flex>
    </div>
  );
};
