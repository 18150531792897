import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import { ReportsSnilsSearchRequest, useReportsSnilsSearch } from "@api/report";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants";
import { validationMessage } from "src/utils/constants";
import { correctSnilsRegexCallback } from "src/utils/validation";
import s from "./MainTabSnils.module.scss";

interface ReportsSnilsFieldValues
  extends ReportsSnilsSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    snils: yup
      .string()
      .test(
        "snils-check",
        validationMessage.WrongSnils,
        correctSnilsRegexCallback,
      )
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabSnilsProps = MainTabProps;

export const MainTabSnils: FC<MainTabSnilsProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      snils: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsSnilsSearch();

  const onSubmitInner = useCallback(
    (data: ReportsSnilsFieldValues) => {
      const requestData = {
        ...data,
        snils: data.snils.replace(/\D/g, ""),
      };

      return onSubmit({ requestData, search, withSopd: true }).then(() =>
        reset(),
      );
    },
    [search, onSubmit, reset],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12}>
        <Controller
          name="snils"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              mask="000-000-000 00"
              placeholder="СНИЛС"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
