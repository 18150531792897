import { PropsWithChildren } from "react";
import {
  StatusPaper,
  StatusPaperProps,
} from "src/components/StatusPaper/StatusPaper";
import s from "./RetrieveStatus.module.scss";

export type RetrieveStatusProps = StatusPaperProps;

export const RetrieveStatus = ({
  status,
  children,
}: PropsWithChildren<RetrieveStatusProps>) => (
  <div className={s.container}>
    <StatusPaper status={status} />
    {children}
  </div>
);
