import { useCallback, useState } from "react";
import { Text } from "src/ui/Text/Text";

import { Flex, Radio } from "antd";
import { intervals, intervalsOrder } from "@pages/Analytics/constants";
import { Interval } from "@pages/Analytics/types";
import { Button } from "../../../../ui/Button/Button";
import s from "./SettingsModal.module.scss";

export interface SettingsModalProps {
  intervalValue: Interval;
  onChangeIntervalValue: (interval: Interval) => void;
  onClearFilters: () => void;
  closeModal: () => void;
}

export const SettingsModal = ({
  intervalValue,
  onChangeIntervalValue,
  onClearFilters,
  closeModal,
}: SettingsModalProps) => {
  const [intervalModalValue, setIntervalModalValue] =
    useState<Interval>(intervalValue);

  const handleClearFilters = () => {
    onClearFilters();
    setIntervalModalValue(Interval.all);
    onChangeIntervalValue(Interval.all);
  };

  const submit = useCallback(() => {
    onChangeIntervalValue(intervalModalValue);
    closeModal();
  }, [intervalModalValue, closeModal, onChangeIntervalValue]);

  return (
    <Flex className={s.container} vertical gap={24}>
      <Text variant="header-6">Настройки отображения</Text>
      <Flex vertical gap={8}>
        <Text variant="subheader-4">Показывать добавленные в папки:</Text>
        {intervalsOrder.map((interval: Interval) => (
          <Radio
            key={interval}
            checked={intervalModalValue === interval}
            onChange={(e) => {
              if (e.target.checked) setIntervalModalValue(interval);
            }}
          >
            {intervals[interval]}
          </Radio>
        ))}
      </Flex>
      <Flex justify="flex-end" gap={24} className={s.container__buttons}>
        <Button type="secondary" onClick={handleClearFilters}>
          Очистить фильтры
        </Button>
        <Button onClick={submit}>Применить</Button>
      </Flex>
    </Flex>
  );
};
