import cn from "classnames";
import { ReactComponent as ExpandLess } from "src/assets/expand_less.svg";
import { ReactNode } from "react";
import s from "./Expand.module.scss";

export interface ExpandProps {
  state: boolean;
  toggle: () => void;
  children?: ReactNode;
  className?: string;
}

export const Expand = ({ state, toggle, children, className }: ExpandProps) => (
  <button type="button" className={cn(s.container, className)} onClick={toggle}>
    {children}
    {state ? (
      <ExpandLess />
    ) : (
      <span className={s.container_more}>
        <ExpandLess />
      </span>
    )}
  </button>
);
