import { Main } from "@pages/Main/Main";
import { Admin } from "@pages/Admin/Admin";
import { History } from "@pages/History/History";
import { Telegram } from "@pages/Telegram/Telegram";
import { Search } from "@pages/Search/Search";
import { Report } from "@pages/Report/Report";
import { Root } from "@pages/Root/Root";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { UserSettings } from "@pages/UserSettings/UserSettings";
import { Analytics } from "@pages/Analytics/Analytics";
import { Accesses } from "@pages/Accesses/Accesses";
import { AccessesAdd } from "@pages/AccessesAdd/AccessesAdd";
import { AccessesEdit } from "@pages/AccessesEdit/AccessesEdit";
import { Contacts } from "@pages/Contacts/Contacts";
import React from "react";
import Alerts from "@pages/Alerts/Alerts";
import Tariffs from "@pages/Tariffs/Tariffs";
import { Documents } from "@pages/Documents/Documents";
import { SupportChat } from "@pages/SupportChat/SupportChat";
import Employees from "@pages/Employees/Employees";
import { AppRoute } from "./constants";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

export const router = createBrowserRouter([
  {
    path: AppRoute.Root,
    element: <Root />,
    children: [
      {
        path: "",
        element: <Navigate to={AppRoute.Lk} replace />,
      },
      {
        path: AppRoute.Report,
        element: <Report />,
      },
      {
        path: AppRoute.Lk,
        element: <Main />,
      },
      {
        path: AppRoute.Lk,
        element: <PrivateRoute />,
        children: [
          {
            path: AppRoute.Admin,
            element: <Admin />,
          },
          {
            path: AppRoute.User,
            element: <UserSettings />,
          },
          {
            path: AppRoute.Analytics,
            element: <Analytics />,
          },
          {
            path: AppRoute.Employees,
            element: <Employees />,
          },
          {
            path: AppRoute.Accesses,
            element: <Accesses />,
          },
          {
            path: AppRoute.AccessesAdd,
            element: <AccessesAdd />,
          },
          {
            path: AppRoute.AccessesEdit,
            element: <AccessesEdit />,
          },
          {
            path: AppRoute.Search,
            element: <Search />,
          },
          {
            path: AppRoute.Telegram,
            element: <Telegram />,
          },
          {
            path: AppRoute.History,
            element: <History />,
          },
          {
            path: AppRoute.Contacts,
            element: <Contacts />,
          },
          {
            path: AppRoute.Alerts,
            element: <Alerts />,
          },
          {
            path: AppRoute.Documents,
            element: <Documents />,
          },
          {
            path: AppRoute.Support,
            element: <SupportChat />,
          },
        ],
      },
      { path: AppRoute.Tariffs, element: <Tariffs /> },
    ],
  },
]);
