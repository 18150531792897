import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
import { Text } from "src/ui/Text/Text";
import s from "./Main.module.scss";
import { MainTabs } from "./components/MainTabs/MainTabs";
import { MainHistory } from "./components/MainHistory/MainHistory";
import { useMainTabSearchParam } from "./hooks/useMainTabSearchParam";

export const Main = () => {
  const [tab, setTab] = useMainTabSearchParam();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Block>
        <Flex vertical gap={24}>
          <Text variant="header-7">
            Находим данные и собираем их в единый отчет
          </Text>
          <MainTabs tab={tab} setTab={setTab} />
        </Flex>
      </Block>
      <Block>
        <MainHistory tab={tab} />
      </Block>
    </Flex>
  );
};
