import { FC, useCallback } from "react";
import { Alert, Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { useReportsAddressSearch } from "@api/report";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants";
import s from "./MainTabAddressNormal.module.scss";

interface ReportsAddressSearchForm extends MainTabFieldValues {
  city?: string;
  street?: string;
  house?: string;
  apartment?: string;
}

const schema = yup
  .object<ReportsAddressSearchForm>()
  .shape({
    city: yup.string(),
    street: yup.string(),
    house: yup.string(),
    apartment: yup.string(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required()
  .test(
    "at-least-one-required-fields",
    "Должно быть заполнено хотя бы одно поле",
    (value) => Object.values(value).filter(Boolean).length >= 1,
  );

type MainTabAddressNormalProps = MainTabProps;

export const MainTabAddressNormal: FC<MainTabAddressNormalProps> = ({
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      city: "",
      street: "",
      house: "",
      apartment: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsAddressSearch();

  const onSubmitInner = useCallback(
    async ({
      apartment,
      city,
      house,
      street,
      sopdFileList,
    }: ReportsAddressSearchForm) => {
      const requestData = {
        address: [city, street, house, apartment].join(" "),
        sopdFileList,
      };

      return onSubmit({ requestData, search, withSopd: true }).then(() =>
        reset(),
      );
    },
    [search, onSubmit, reset],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex vertical gap={24}>
        <Flex gap={12} wrap="wrap">
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                placeholder="Город"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="street"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                placeholder="Улица"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="house"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                placeholder="Дом"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="apartment"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                placeholder="Квартира"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <div className={s.noop} />
          <Controller
            name="sopdFileList"
            control={control}
            render={({ field }) => (
              <SubmitButton
                fileList={field.value}
                onFileListChange={field.onChange}
                disabled={!isValid || isSearchPending}
              />
            )}
          />
        </Flex>
        {Object.entries(errors)
          .filter(([key]) => !key)
          .map(([, value], index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Alert key={index} type="error" message={value.message} />
          ))}
      </Flex>
    </form>
  );
};
