import { FC, useCallback } from "react";
import { Alert, Flex, Tabs, TabsProps } from "antd";
import {
  IoIosWarning,
  IoMdPerson,
  IoMdCar,
  IoMdBriefcase,
  IoMdInformationCircle,
} from "react-icons/io";
import { Text } from "src/ui/Text/Text";
import {
  MdPhoneIphone,
  MdMapsHomeWork,
  MdOutlinePlaylistAddCheck,
  MdOutlineSpeed,
  MdPortrait,
  MdFeaturedVideo,
} from "react-icons/md";
import { BsPassportFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { fileToBase64 } from "src/utils/files/fileToBase64";
import { PossibleNetworkResults, SearchRequestWithSopd } from "@api/report";
import { SetMainTabAction } from "@pages/Main/hooks/useMainTabSearchParam";
import { isAxiosError } from "axios";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import {
  mainTabFioFormKey,
  useSessionStorage,
} from "src/hooks/useSessionStorage";
import s from "./MainTabs.module.scss";
import { MainTabDriverLicense } from "./tabs/MainTabDriverLicense/MainTabDriverLicense";
import { MainTabCreditRating } from "./tabs/MainTabCreditRating/MainTabCreditRating";
import { MainTabCompany } from "./tabs/MainTabCompany/MainTabCompany";
import { MainTabPhoto } from "./tabs/MainTabPhoto/MainTabPhoto";
import { MainTabScoring } from "./tabs/MainTabScoring/MainTabScoring";
import { MainTabEmail } from "./tabs/MainTabEmail/MainTabEmail";
import { MainTabAuto } from "./tabs/MainTabAuto/MainTabAuto";
import { MainTabPassport } from "./tabs/MainTabPassport/MainTabPassport";
import { MainTabInnOrSnils } from "./tabs/MainTabInnOrSnils/MainTabInnOrSnils";
import { MainTabPhone } from "./tabs/MainTabPhone/MainTabPhone";
import { MainTabAddress } from "./tabs/MainTabAddress/MainTabAddress";
import { MainTabFio } from "./tabs/MainTabFio/MainTabFio";
import { ENavMenuItem, MainTab } from "../../constants";
import {
  MainTabFieldValues,
  MainTabOnSubmitProps,
  MainTabProps,
} from "./types";
import { MainTabInfo } from "./tabs/MainTabInfo/MainTabInfo";

const items = [
  // {
  //     key: MainTab.Dossier,
  //     Icon: HiMiniGlobeAmericas,
  //     label: 'Полное досье',
  //     TabComponent: MainTabDossier,
  //     hint: 'Проверка чувствительна к количеству заполненных полей. Чем больше информации вы предоставите, тем более точное досье получите.',
  // },
  {
    key: MainTab.Fio,
    Icon: IoMdPerson,
    label: "По ФИО",
    TabComponent: MainTabFio,
    hint: "Доступен поиск по неполным данным",
  },
  {
    key: MainTab.Phone,
    Icon: MdPhoneIphone,
    label: "По телефону",
    TabComponent: MainTabPhone,
    hint: "",
  },
  {
    key: MainTab.Address,
    Icon: MdMapsHomeWork,
    label: "По адресу",
    TabComponent: MainTabAddress,
    hint: "Используйте переключатель для переключения на поиск по кадастровому номеру или адресу",
  },
  {
    key: MainTab.InnOrSnils,
    Icon: MdMapsHomeWork,
    label: "По ИНН или СНИЛС",
    TabComponent: MainTabInnOrSnils,
    hint: "Используйте переключатель для переключения на поиск по номеру СНИЛС или по номеру ИНН",
  },
  {
    key: MainTab.Passport,
    Icon: BsPassportFill,
    label: "По паспорту",
    TabComponent: MainTabPassport,
    hint: "Заполните поле без пробелов и знаков препинания",
  },
  {
    key: MainTab.Auto,
    Icon: IoMdCar,
    label: "По номеру авто",
    TabComponent: MainTabAuto,
    hint: "Используйте переключатель для поиска по номеру VIN или номеру авто",
  },
  {
    key: MainTab.DriverLicense,
    Icon: MdFeaturedVideo,
    label: "По ВУ",
    TabComponent: MainTabDriverLicense,
    hint: "Введите номер без пробелов",
  },
  {
    key: MainTab.Email,
    Icon: IoMail,
    label: "По Email",
    TabComponent: MainTabEmail,
    hint: "",
  },
  {
    key: MainTab.Company,
    Icon: IoMdBriefcase,
    label: "По юридическим лицам",
    TabComponent: MainTabCompany,
    hint: "Используйте переключатель для поиска по номеру ОГРН или номеру ИНН для юридического лица",
  },
  {
    key: MainTab.Photo,
    Icon: MdPortrait,
    label: "По фото",
    TabComponent: MainTabPhoto,
    hint: "",
  },
  {
    key: MainTab.CreditRating,
    Icon: MdOutlinePlaylistAddCheck,
    label: "Кредитный рейтинг",
    TabComponent: MainTabCreditRating,
    hint: "",
  },
  {
    key: MainTab.Scoring,
    Icon: MdOutlineSpeed,
    label: "Скоринг",
    TabComponent: MainTabScoring,
    hint: "",
  },
  {
    key: MainTab.Info,
    Icon: IoMdInformationCircle,
    label: "По ключевому слову",
    TabComponent: MainTabInfo,
    hint: "",
  },
];

interface TabWrapperProps {
  hint: string;
  TabComponent: FC<MainTabProps>;
}

const TabWrapper: FC<TabWrapperProps> = ({ hint, TabComponent }) => {
  const [api] = useNotification();

  const { openModal, closeModal } = useModalMessage();

  const [, setValue] = useSessionStorage<PossibleNetworkResults | null>(
    mainTabFioFormKey,
    null,
  );

  const onSubmit = useCallback(
    async <T extends MainTabFieldValues>({
      requestData,
      search,
      withSopd,
    }: MainTabOnSubmitProps<T>) => {
      const data: SearchRequestWithSopd & T = {
        ...requestData,
        sopd: undefined,
        sopdFileList: undefined,
      };

      if (withSopd) {
        const sopdFile: File | undefined =
          requestData.sopdFileList?.[0].originFileObj;
        data.sopd = sopdFile
          ? await fileToBase64(sopdFile).then((b) =>
              b?.replace(/^data:application\/pdf;base64,/, ""),
            )
          : undefined;
      }

      return search(data)
        .then(() => {
          api.success({
            message: "Запрос успешно создан!",
          });

          setValue(null);
        })
        .catch((error: unknown) => {
          handleBackendErrors({
            api,
            error,
            message4xx: "Не удалось создать запрос",
          });

          if (isAxiosError(error) && error.response?.status === 401) {
            openModal({
              children: (
                <AuthForm onSuccess={closeModal} onClose={closeModal} />
              ),
              maskClosable: false,
              closable: false,
            });
          }
        });
    },
    [api, openModal, closeModal, setValue],
  );

  return (
    <Flex vertical gap={24}>
      {!!hint && (
        <Alert
          message={
            <Flex gap={12} align="center">
              <Flex className={s.icon_wrapper} align="center" justify="center">
                <IoIosWarning size={20} />
              </Flex>
              {hint}
            </Flex>
          }
          type="info"
        />
      )}
      <TabComponent onSubmit={onSubmit} />
    </Flex>
  );
};

const tabItems: TabsProps["items"] = items.map(
  ({ key, label, Icon, TabComponent, hint }) => ({
    key,
    label: (
      <Flex gap={12}>
        <Text variant="body-2">{label}</Text>
        <Icon size={20} />
      </Flex>
    ),
    children: <TabWrapper TabComponent={TabComponent} hint={hint} />,
  }),
);

interface MainTabsProps {
  tab: MainTab | ENavMenuItem;
  setTab: SetMainTabAction;
}

export const MainTabs: FC<MainTabsProps> = ({ tab, setTab }) => (
  <Tabs
    activeKey={tab || MainTab.Fio}
    destroyInactiveTabPane
    items={tabItems}
    type="line"
    onChange={setTab as TabsProps["onChange"]}
    className={s.tabs}
  />
);
