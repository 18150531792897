import {
  IEmployeeDto,
  IEmployeeId,
  IEmployeeRequest,
  IGetEmployeesRequest,
  TGetEmployeesResponse,
} from "@api/employees/types";
import { client } from "@api/client";
import { EEmployeesEndpoint } from "@api/employees/endpoints";

export const employees = (data: IGetEmployeesRequest) =>
  client.post<undefined, TGetEmployeesResponse, IGetEmployeesRequest>(
    EEmployeesEndpoint.GetEmployees,
    data,
  );

export const employeeCreate = (data: IEmployeeRequest) =>
  client.post<undefined, IEmployeeDto, IEmployeeRequest>(
    EEmployeesEndpoint.CreateEmployee,
    data,
  );

export const employeeUpdate = (data: IEmployeeRequest) =>
  client.post<undefined, IEmployeeDto, IEmployeeRequest>(
    EEmployeesEndpoint.UpdateEmployee,
    data,
  );

export const employeeRetrieve = (id: IEmployeeDto["id"]) =>
  client.post<undefined, IEmployeeDto, IEmployeeId>(
    EEmployeesEndpoint.RetrieveEmployee,
    { id },
  );

export const employeeDelete = (id: IEmployeeDto["id"]) =>
  client.post<undefined, undefined, IEmployeeId>(
    EEmployeesEndpoint.DeleteEmployee,
    { id },
  );
