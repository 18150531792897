import { FC, PropsWithChildren } from "react";
import cn from "classnames";
import s from "./Block.module.scss";

export interface BlockProps extends PropsWithChildren {
  className?: string;
  id?: string;
}

export const Block: FC<BlockProps> = ({ children, className, id }) => (
  <div id={id} data-name="block-report" className={cn(s.block, className)}>
    {children}
  </div>
);
