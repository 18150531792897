import cn from "classnames";
import { Block } from "src/ui/Block/Block";
import React, { PropsWithChildren, ReactNode } from "react";
import { useBooleanState } from "src/hooks/useBooleanState";
import { StatusPaper } from "src/components/StatusPaper/StatusPaper";
import { ReportStatus } from "@api/report";
import { Expand } from "../../ui/Expand/Expand";
import { Text } from "../../ui/Text/Text";
import s from "./RetrieveCard.module.scss";
import { RetrieveCardPrint, RetrieveCardVariant } from "./types";
import { getSourceTitleColor } from "../../utils/reports/getSourceTitleColor";

export interface RetrieveCardCommonProps {
  status?: ReportStatus;
  print?: RetrieveCardPrint;
}

export type RetrieveCardProps = PropsWithChildren<
  {
    title: string;
    header?: ReactNode;
    subtitle?: ReactNode;
    variant?: RetrieveCardVariant;
  } & RetrieveCardCommonProps
>;

export const RetrieveCard = ({
  title,
  header,
  subtitle,
  variant = RetrieveCardVariant.withBorder,
  print = RetrieveCardPrint.device,
  status,
  children,
}: RetrieveCardProps) => {
  const { state, toggle } = useBooleanState(true);

  return (
    <Block
      id={title}
      className={cn(
        s.container,
        print === RetrieveCardPrint.print && s.container_print,
      )}
    >
      {header}

      {status === ReportStatus.Finished && (
        <div
          className={cn(
            s.container__title,
            state &&
              variant === RetrieveCardVariant.withBorder &&
              s.container__title_withBorder,
            print === RetrieveCardPrint.print && s.container__title_print,
          )}
        >
          {title ? (
            <>
              {print === RetrieveCardPrint.device && (
                <Expand state={state} toggle={toggle}>
                  <Text
                    variant="subheader-4"
                    color={getSourceTitleColor(title)}
                  >
                    {title}
                  </Text>
                </Expand>
              )}
              {print === RetrieveCardPrint.print && (
                <Text variant="subheader-4" color={getSourceTitleColor(title)}>
                  {title}
                </Text>
              )}
            </>
          ) : null}
          {state && subtitle}
        </div>
      )}
      {status !== ReportStatus.Finished && title && (
        <Text variant="subheader-4" color={getSourceTitleColor(title)}>
          {title}
        </Text>
      )}

      {status && status !== ReportStatus.Finished && (
        <StatusPaper status={status} />
      )}

      {state && status === ReportStatus.Finished && children}
    </Block>
  );
};
