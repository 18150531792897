import React from "react";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import s from "./MainLayoutAlerts.module.scss";
import { Text } from "../../../../../ui/Text/Text";
import { AppRoute } from "../../../../routes/constants";
import AlertsList from "../../../../../ui/AlertsList/AlertsList";

const MainLayoutAlerts = ({ hideAlerts }: { hideAlerts: () => void }) => {
  const navigate = useNavigate();

  const handleShowAll = () => {
    hideAlerts();
    navigate(AppRoute.Alerts);
  };

  return (
    <Flex
      vertical
      gap={16}
      className={s.alert}
      onClick={(e) => e.stopPropagation()}
    >
      <Text variant="label-5">Системные оповещения</Text>
      <Flex vertical gap={16} className={s.alert_content}>
        <AlertsList maxVisible={6} />
        <Text
          variant="subheader-2"
          color="green"
          className={s.alert_content_showAll}
          onClick={() => handleShowAll()}
        >
          Показать все
        </Text>
      </Flex>
    </Flex>
  );
};

export default MainLayoutAlerts;
