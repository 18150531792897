import { Button } from "src/ui/Button/Button";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { useCallback } from "react";
import {
  PhotoData,
  PhotoDataProps,
} from "@entities/RetrievePhotoByFace/components/SearchResults/Row/Buttons/PhotoData/PhotoData";
import { Groups } from "@entities/RetrievePhotoByFace/components/SearchResults/Row/Buttons/Groups/Groups";
import s from "./Buttons.module.scss";

export type ButtonsProps = PhotoDataProps;

export const Buttons = ({ id, meta }: ButtonsProps) => {
  const { openModal } = useModalMessage();

  const openPhotoData = useCallback(
    () =>
      openModal({
        children: <PhotoData id={id} meta={meta} />,
        maskClosable: true,
        closable: true,
      }),
    [id, meta, openModal],
  );

  const openGroups = useCallback(
    () =>
      openModal({
        children: <Groups />,
        maskClosable: true,
        closable: true,
      }),
    [openModal],
  );

  return (
    <div className={s.container}>
      <div className={s.container__inner}>
        <Button type="secondary" size="small" onClick={openPhotoData}>
          Данные фото
        </Button>
        <Button type="secondary" size="small" onClick={openGroups}>
          Группы и чаты
        </Button>
      </div>
    </div>
  );
};
