import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
// import { AccessesApi } from './components/AccessesApi/AccessesApi';
import { AppRoute } from "src/app/routes/constants";
import { useParams } from "react-router-dom";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { AccessesList } from "src/components/AccessesList/AccessesList";
import { useGroup } from "@api/groups/queries/useGroup";
import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import s from "./Accesses.module.scss";

export const Accesses = () => {
  const { data: user } = useCurrentUserQuery();
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  if (!user) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Доступы"
        mapPathToTitle={{ [AppRoute.Accesses]: "Доступы" }}
        paths={[AppRoute.Accesses]}
      />
      <Block>
        <AccessesList withPagination limit={10} group={group} />
      </Block>
    </Flex>
  );
};
