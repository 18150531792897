import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { useSessionsDeleteOther, useSessionsSearchQuery } from "@api/session";
import { Button } from "src/ui/Button/Button";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { CommonModal } from "src/components/CommonModal/CommonModal";
import { IoIosWarning } from "react-icons/io";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { SessionCard } from "./components/SessionCard/SessionCard";
import s from "./UserSettingsSessions.module.scss";

interface UserSettingsSessionsProps {}

export const UserSettingsSessions: FC<UserSettingsSessionsProps> = () => {
  const { data: sessions } = useSessionsSearchQuery({});
  const { mutateAsync: deleteOther, isPending: isSessionsDeleting } =
    useSessionsDeleteOther();
  const { openModal } = useModalMessage();

  const [api] = useNotification();

  const onDeleteConfirm = useCallback(() => {
    deleteOther({}).catch((error) => {
      handleBackendErrors({
        api,
        error,
        message4xx: "Не удалось удалить сеанс",
      });
    });
  }, [deleteOther, api]);

  const onDeleteOther = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          icon={<IoIosWarning size={120} />}
          iconSize={120}
          iconWrapperClassName={s.confirm_modal__icon}
          buttonsVertical
          buttonsGap={16}
          title="Вы уверены, что хотите завершить сеансы? Действие невозможно будет отменить"
          primaryButtonProps={{
            type: "primary",
            children: "Да, продолжить",
            size: "big",
            onClick: onDeleteConfirm,
          }}
          secondaryButtonProps={{
            type: "flat_secondary",
            children: "Отменить",
            size: "big",
          }}
        />
      ),
    });
  }, [onDeleteConfirm, openModal]);

  return (
    <Flex vertical gap={32}>
      <Text variant="header-6">Сеансы</Text>
      <Flex vertical gap={16}>
        {sessions
          ?.filter(Boolean)
          .map((session) => <SessionCard key={session.id} session={session} />)}
      </Flex>
      <Flex justify="center">
        <Button
          type="secondary"
          onClick={onDeleteOther}
          disabled={isSessionsDeleting}
        >
          Завершить все сеансы
        </Button>
      </Flex>
    </Flex>
  );
};
