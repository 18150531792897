import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Chat, Message } from "@api/support/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetChats } from "@api/support/mutations/useGetChats";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Text } from "src/ui/Text/Text";
import * as yup from "yup";
import { ReactComponent as Send } from "src/assets/send.svg";
import { ReactComponent as Loading } from "src/assets/loading.svg";
import { ReactComponent as AddTicket } from "src/assets/add_ticket.svg";
import { Input } from "src/ui/Input/Input";
import { TextArea } from "src/ui/TextArea/TextArea";
import { Button } from "src/ui/Button/Button";
import { useCreateChat } from "@api/support/mutations/useCreateChat";
import { useGetMessages } from "@api/support/mutations/useGetMessages";
import { useCreateMessage } from "@api/support/mutations/useCreateMessage";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import s from "./SupportChat.module.scss";
import { AppRoute } from "../../../../app/routes/constants";
import { CardChat } from "../CardChat/CardChat";
import { CardMessage } from "../CardMessage/CardMessage";
import { CurrentDate } from "../CurrentDate/CurrentDate";
import { Sentinel } from "../Sentinel/Sentinel";
import { AddTicketModal } from "../AddTicketModal/AddTicketModal";

function getDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.format("DD.MM.YYYY");
  } catch {
    return "";
  }
}

function getLocaleDate(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.locale("ru").format("DD MMMM YYYY");
  } catch {
    return "";
  }
}

function getTime(isoDateStr: string): string {
  try {
    const date = dayjs(isoDateStr);
    return date.format("HH:mm");
  } catch {
    return "";
  }
}

function isNowDate(isoDateStr: string): boolean {
  try {
    const date = dayjs(isoDateStr);
    const now = dayjs(new Date().toISOString());
    return date.format("DD.MM.YYYY") === now.format("DD.MM.YYYY");
  } catch {
    return false;
  }
}

function isLocaleNowDate(isoDateStr: string): boolean {
  try {
    const now = dayjs(new Date().toISOString());
    return isoDateStr === now.locale("ru").format("DD MMMM YYYY");
  } catch {
    return false;
  }
}

const sortChats = (a: Chat, b: Chat) => {
  const bDate = new Date(b.created_at).getTime();
  const aDate = new Date(a.created_at).getTime();
  return bDate - aDate;
};

const sortMessages = (a: Message, b: Message) => {
  const bDate = new Date(b.created_at).getTime();
  const aDate = new Date(a.created_at).getTime();
  return aDate - bDate;
};

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    message: yup.string().required(),
    question: yup.string(),
  })
  .required();

type MapMessageType = { date: string; messages: Message[] }[];

export const SupportChat = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const [hiddenDates, setHiddenDates] = useState<string[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentChat, setCurrentChat] = useState<Chat | undefined>();
  const { mutateAsync: getChats, isPending: isLoadingChats } = useGetChats();
  const { mutateAsync: getMessages, isPending: isLoadingMessages } =
    useGetMessages();
  const { mutateAsync: createChat } = useCreateChat();
  const { mutateAsync: createMessage } = useCreateMessage();

  const { control, watch, resetField } = useForm<{
    name: string;
    message: string;
    question?: string;
  }>({
    defaultValues: {
      name: "",
      message: "",
      question: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const getChatsHandler = async () => {
    const data = await getChats({
      limit: 500,
      offset: 0,
    });
    setChats(data);
  };

  const getMessagesHandler = async () => {
    if (currentChat) {
      const data = await getMessages({
        support_chat_id: currentChat.id,
        limit: 500,
        offset: 0,
      });
      setMessages(data);
    }
  };

  useEffect(() => {
    getChatsHandler();
  }, []);

  useEffect(() => {
    getMessagesHandler();
  }, [currentChat]);

  useEffect(() => {
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
  }, [messages]);

  const onHiddenHandler = (value: boolean, date: string) => {
    setHiddenDates((dates) =>
      value ? [...dates, date] : dates.filter((item) => item !== date),
    );
  };

  const isHidden = (date: string) =>
    !!hiddenDates.find((value) => value === date);

  const mapMessages: MapMessageType = useMemo(
    () =>
      [
        ...new Set(
          messages
            .sort(sortMessages)
            .map((value) => getLocaleDate(value.created_at)),
        ),
      ].map((date) => ({
        date,
        messages: messages.filter(
          (message) => date === getLocaleDate(message.created_at),
        ),
      })),
    [messages],
  );

  const onCreateChatHandler = async () => {
    const message = watch("message");
    const name = watch("name");
    const data = await createChat({
      subject: name,
      question: message,
    });
    if (data) {
      resetField("name");
      resetField("message");
      getChatsHandler();
    }
  };

  const onCurrentChatHandler = (chat: Chat) => {
    setCurrentChat(chat);
  };

  const onCreateMessageHandler = async () => {
    if (currentChat) {
      const question = watch("question");
      const data = await createMessage({
        support_chat_id: currentChat.id,
        message: question ?? "",
      });
      if (data) {
        resetField("question");
        getMessagesHandler();
      }
    }
  };

  if (isLoadingChats) {
    return <Loading className={s.loading} />;
  }
  return (
    <div className={s.container}>
      <PageHeader
        title="Техническая поддержка"
        mapPathToTitle={{
          [AppRoute.Support]: "Техническая поддержка",
        }}
        paths={[AppRoute.Support]}
      />
      <div className={s.wrapper}>
        <div
          className={cn(
            s.block,
            s.chats,
            chats.length > 0 ? s.height_block : s.without_chats,
          )}
        >
          <div className={s.header}>Тикеты</div>
          {chats.length > 0 ? (
            <>
              <div className={s.list}>
                {chats.sort(sortChats).map((chat) => (
                  <CardChat
                    key={chat.id}
                    date={
                      isNowDate(chat.created_at)
                        ? `Сегодня ${getTime(chat.created_at)}`
                        : getDate(chat.created_at)
                    }
                    title={chat.subject}
                    message={chat.question}
                    onClick={() => onCurrentChatHandler(chat)}
                  />
                ))}
              </div>
              <div className={s.footer}>
                <Button
                  iconRight={<AddTicket width={20} height={20} />}
                  size="big"
                  width="full"
                  onClick={openModal}
                >
                  Создать тикет
                </Button>
              </div>
            </>
          ) : (
            <div className={s.list}>
              <Text variant="subheader-3" color="darkGray">
                Добавьте тикет, чтоб мы могли сделать наш сервис лучше
              </Text>
            </div>
          )}
        </div>
        {chats.length > 0 && !currentChat && (
          <div className={cn(s.messages, s.height_block)} />
        )}
        {chats.length === 0 && (
          <div className={cn(s.block, s.create_tiket)}>
            <div className={s.header}>Создать тикет</div>
            <div className={s.list}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    placeholder="Название тикета"
                    required
                    validate={fieldState}
                  />
                )}
              />
              <Controller
                name="message"
                control={control}
                render={({ field, fieldState }) => (
                  <TextArea
                    {...field}
                    placeholder="Сообщение"
                    required
                    rows={10}
                    validate={fieldState}
                  />
                )}
              />
            </div>
            <div className={s.footer}>
              <Button
                iconRight={<AddTicket width={20} height={20} />}
                onClick={onCreateChatHandler}
                size="big"
                width="full"
              >
                Создать тикет
              </Button>
            </div>
          </div>
        )}
        {chats.length > 0 && !!currentChat && (
          <div className={cn(s.block, s.messages, s.height_block)}>
            <div className={s.header}>{currentChat.subject}</div>
            <div ref={chatRef} className={s.list_messages}>
              {mapMessages.map((item) => (
                <div className={s.messages_wrapper} key={item.date}>
                  <Sentinel
                    onHidden={(value) => onHiddenHandler(value, item.date)}
                  />
                  <CurrentDate
                    isHidden={isHidden(item.date)}
                    date={isLocaleNowDate(item.date) ? `Сегодня` : item.date}
                  />
                  {item.messages.sort(sortMessages).map((message) => (
                    <CardMessage
                      key={message.id}
                      date={getTime(message.created_at)}
                      message={message.message}
                      isSupport={message.support_reply}
                    />
                  ))}
                </div>
              ))}
              {isLoadingMessages && <Loading className={s.loading} />}
            </div>
            <div className={s.footer}>
              <div className={s.button} onClick={onCreateMessageHandler}>
                <Send width={20} height={20} />
              </div>
              <Controller
                name="question"
                control={control}
                render={({ field, fieldState }) => (
                  <TextArea
                    {...field}
                    placeholder="Сообщение"
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    validate={fieldState}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
      <AddTicketModal
        getChats={getChatsHandler}
        onClose={closeModal}
        isModalOpen={isModalOpen}
      />
    </div>
  );
};
