import { FC, useCallback, useMemo } from "react";
import { Card as AntCard, Flex, Skeleton } from "antd";
import { Text } from "src/ui/Text/Text";
import { Report, useReportsDelete } from "@api/report";
import { BiSolidTrashAlt } from "react-icons/bi";
import { Button } from "src/ui/Button/Button";
import { LuChevronRight } from "react-icons/lu";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { CommonModal } from "src/components/CommonModal/CommonModal";
import { AppRoute } from "src/app/routes/constants";
import { generatePath, Link } from "react-router-dom";
import { mapReportTypeToLabel } from "src/utils/reports/mapReportTypeToLabel";
import { getReportTypeToTitleGetter } from "src/utils/reports/getReportTypeToTitleGetter";
import { toHumanReadableDate } from "src/utils/date/toHumanReadableDate";
import { ReportType } from "@api/report/constants";
import { getReportStatus } from "src/utils/getReportStatus";
import { MdStickyNote2 } from "react-icons/md";
import styles from "./HistoryListCard.module.scss";
import { Status } from "./components/Status/Status";

interface HistoryListCardProps {
  report?: Report;
  isLoading?: boolean;
}

export const HistoryListCard: FC<HistoryListCardProps> = ({
  report,
  isLoading,
}) => {
  const {
    created_at = "",
    id = "",
    request = "",
    request_source = "",
    has_sopd,
    type = ReportType.SEARCH_ADDRESS,
  } = report || {};
  const { mutateAsync: reportDelete, isPending: isReportDeleting } =
    useReportsDelete();
  const { openModal } = useModalMessage();

  const onDelete = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          title={
            <>
              Вы уверены, что хотите удалить проверку?
              <br />
              Это действие необратимо, и отчет также
              <br />
              будет удален.
            </>
          }
          primaryButtonProps={{
            children: "Да, удалить",
            onClick: () => reportDelete({ id }),
            disabled: isReportDeleting,
          }}
          secondaryButtonProps={{ children: "Отменить" }}
        />
      ),
    });
  }, [id, reportDelete, openModal, isReportDeleting]);

  // TODO implement after add groups
  const username = "";

  const label = mapReportTypeToLabel[type];
  const title = useMemo(
    () => getReportTypeToTitleGetter(type)?.(request),
    [type, request],
  );

  const reportStatus = getReportStatus({
    sourceStatuses: report?.source_statuses,
    found: report?.found,
  });

  return (
    <AntCard className={styles.card}>
      <Skeleton loading={isLoading}>
        <Flex component="section" vertical gap={12}>
          <Status reportStatus={reportStatus} />
          {created_at || username || has_sopd ? (
            <Flex
              component="h3"
              className={styles.header}
              gap={8}
              align="center"
            >
              {created_at ? (
                <Text variant="body-2" color="darkGray">
                  {toHumanReadableDate(created_at)}
                </Text>
              ) : null}
              {username ? (
                <Text variant="body-2" color="darkGray">
                  {username}
                </Text>
              ) : null}
              {has_sopd ? (
                <Flex gap={2} className={styles.header_sopd}>
                  <MdStickyNote2 />
                  <Text variant="body-2" color="darkGray">
                    СОПД
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          ) : null}
          <Button
            pin="round"
            type="flat"
            onClick={onDelete}
            disabled={isReportDeleting}
            className={styles.button_delete}
          >
            <BiSolidTrashAlt size={20} />
          </Button>
          <Flex vertical gap={4}>
            {label ? (
              <Text variant="body-2" color="darkGray">
                {label}
              </Text>
            ) : null}
            {title ? (
              <Text variant="subheader-4" ellipsisLines={1}>
                {title}
              </Text>
            ) : null}
          </Flex>
          <Flex vertical gap={4}>
            <Text variant="body-2" color="darkGray">
              Источник запроса
            </Text>
            <Text variant="body-2">{request_source}</Text>
          </Flex>
          <div className={styles.link}>
            <Link
              to={`${generatePath(AppRoute.Report, {
                reportId: id,
              })}?type=${type}`}
              state={{ id, type }}
              replace
            >
              <Button
                size="small"
                type="secondary"
                disabled={isReportDeleting}
                iconRight={<LuChevronRight strokeWidth={3} />}
              >
                Смотрите отчёт
              </Button>
            </Link>
          </div>
        </Flex>
      </Skeleton>
    </AntCard>
  );
};
