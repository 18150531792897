import { useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";

export const PrivateRoute = () => {
  const {
    isSuccess: isUserSuccess,
    refetch: refetchUser,
    isPending,
  } = useCurrentUserQuery();
  const { openModal, closeModal } = useModalMessage();

  const onSuccess = useCallback(() => {
    refetchUser().catch(() => {});

    closeModal();
  }, [refetchUser, closeModal]);

  useEffect(() => {
    if (!isPending && !isUserSuccess) {
      openModal({
        children: <AuthForm onSuccess={onSuccess} onClose={closeModal} />,
        maskClosable: false,
        closable: false,
      });
    }
  }, [openModal, isUserSuccess, onSuccess, closeModal, isPending]);

  return <Outlet />;
};
