export enum Permission {
  SuperuserPermission = "SUPERUSER",
  AdminPermission = "ADMIN",
  MemberPermission = "MEMBER",

  ReportFioPermission = "REPORT_FIO",
  ReportPhonePermission = "REPORT_PHONE",
  ReportInnUlPermission = "REPORT_INN_UL",
  ReportInnFlPermission = "REPORT_INN_FL",
  ReportEmailPermission = "REPORT_EMAIL",
  ReportPassportPermission = "REPORT_PASSPORT",
  ReportSnilsPermission = "REPORT_SNILS",
  ReportVinPermission = "REPORT_VIN",
  ReportDriverLicensePermission = "REPORT_DRIVER_LICENSE",
  ReportOgrnPermission = "REPORT_OGRN",
  ReportAddressPermission = "REPORT_ADDRESS",
  ReportGrnPermission = "REPORT_GRN",
  ReportInfoPermission = "REPORT_INFO",
  ReportCreditRatingPermission = "REPORT_CREDIT_RATING",
  ReportPhotoByFioPermission = "REPORT_PHOTO_BY_FIO",
  ReportPhotoByFacePermission = "REPORT_PHOTO_BY_FACE",
  ReportScoringPermission = "REPORT_SCORING",

  AccessApiPermission = "ACCESS_API",
}

export interface Create {
  name: string;
}

export interface CreateResponse {
  group_id: number;
}

export interface Edit {
  group_id: number;
  name: string;
}

export interface GroupRequest {
  group_id: number;
}

export interface GroupsAddMemberRequest {
  group_id: number;
  name: string;
  login: string;
  password: string;
  request_quota: number;
  expires_at: string;
}

export interface GroupsAddMemberResponse {
  user_id: number;
}

export interface GroupsRemoveMemberRequest {
  group_id: number;
  user_id: number;
}

export type GroupsRemoveMemberResponse = undefined;

export interface GroupsGetPermissionsRequest {
  group_id: number;
}

export type GroupsGetPermissionsResponse = Permission[];

export interface GroupsGrantPermissionsRequest {
  group_id: number;
  permissions: Permission[];
}

export type GroupsGrantPermissionsResponse = undefined;

export interface GroupsGrantUserPermissionsRequest {
  group_id: number;
  user_id: number;
  permissions: Permission[];
}

export type GroupsGrantUserPermissionsResponse = undefined;

export interface GroupsSetNonRussianApiAllowedRequest {
  group_id: number;
  non_russian_ip_allowed: boolean;
}

export interface GroupsSetLoginDurationRequest {
  group_id: number;
  token_duration_seconds: number;
}

export interface EditMemberRequest {
  user_id: number;
  name: string;
  expires_at: string;
}

export interface User {
  user_id: number;
  name: string;
  login: string;
  permissions: Permission[];
  suspended: boolean;
}

export interface Group {
  group_id: number;
  name: string;
  contract_number: string;
  contract_date: string;
  request_usage: number;
  request_quota: null | string;
  expires_at: string;
  permissions: Permission[];
  users: User[];
  non_russian_ip_allowed: boolean;
  token_duration: string;
}

export interface CurrentUser {
  id: number;
  name: string;
  login: string;
  groups: {
    group_id: number;
    name: string;
    permissions: Permission[];
    request_usage: number;
    request_quota: null;
  }[];
  request_usage: number;
  request_quota: null;
  expires_at: string;
}

export interface ChangePasswordRequest {
  old_password: string;
  new_password: string;
}

export interface GroupsReportsTotalCountRequest {
  request_contains?: string;
  user_id?: number;
  group_id?: number;
}

export interface GroupsReportsTotalCountResponse {
  cursor_created_at: string;
  total_count: number;
}

export interface UsersSetQuotaRequest {
  user_id: number;
  request_quota: number;
}

export interface UsersSetQuotaResponse {}

export interface UsersSetSuspendedRequest {
  user_id: number;
  suspended: boolean;
}

export type UsersSetSuspendedResponse = undefined;

export interface UsersRegisterRequest {
  full_name: string;
  email: string;
  phone: string;
  password: string;
  organization_name: string;
  organization_inn: string;
  job_title: string;
}

export interface UsersRegisterResponse {}

export interface UsersConfirmEmailRequest {
  verification_code: string;
  email: string;
}

export interface UsersConfirmEmailResponse {}

export interface UsersResendEmailRequest {
  email: string;
}

export interface UsersResendEmailResponse {}

export type UsersProfilePictureRequest = FormData;

export interface UsersProfilePictureResponse {}

export interface UsersProfilePictureDeleteRequest {
  user_id: number;
}

export interface UsersProfilePictureDeleteResponse {}

export interface UsersProfilePictureRetrieveRequest {
  user_id: number;
}

export type UsersProfilePictureRetrieveResponse = Blob;
