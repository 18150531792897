import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { SearchAutoRegistrationNumberSearchRequest } from "@api/search";
import { useSearchAutoRegistrationNumber } from "@api/search/mutations/useSearchAutoRegistrationNumber";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants";
import { validationMessage } from "src/utils/constants";
import { correctAutoNumberRegexCallback } from "src/utils/validation";
import s from "./MainTabAutoNormal.module.scss";

interface SearchAutoRegistrationNumberFieldValues
  extends SearchAutoRegistrationNumberSearchRequest,
    MainTabFieldValues {}

const mapLatinToCyrillic: Record<string, string> = {
  A: "А",
  B: "В",
  E: "Е",
  K: "К",
  M: "М",
  H: "Н",
  O: "О",
  P: "Р",
  C: "С",
  T: "Т",
  Y: "У",
  X: "Х",
};
const allowedCyrillic = new Set(Object.values(mapLatinToCyrillic));
/**
 * TODO rewrite it
 */
const transformAutoNum = (v) => {
  if (typeof v !== "string") return "";
  const vArr = v.toUpperCase().split("");
  return vArr
    .filter(
      (char) =>
        Number.isInteger(Number(char)) ||
        mapLatinToCyrillic[char] ||
        allowedCyrillic.has(char),
    )
    .map((char) => mapLatinToCyrillic[char] || char)
    .join("");
};

const schema = yup
  .object<SearchAutoRegistrationNumberSearchRequest>()
  .shape({
    auto_registration_number: yup
      .string()
      .transform(transformAutoNum)
      .test(
        "digits-check",
        validationMessage.WrongAutoNumber,
        correctAutoNumberRegexCallback,
      )
      .required(validationMessage.Required),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabAutoNormalProps = MainTabProps;

export const MainTabAutoNormal: FC<MainTabAutoNormalProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      auto_registration_number: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useSearchAutoRegistrationNumber();

  const onSubmitInner = useCallback(
    (requestData: SearchAutoRegistrationNumberFieldValues) =>
      onSubmit({ requestData, search, withSopd: true }).then(() => reset()),
    [onSubmit, search, reset],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12}>
        <Controller
          name="auto_registration_number"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Номер авто"
              mask="a[a]000[0]a[a]00[0]"
              {...field}
              value={transformAutoNum(field.value)}
              onChange={(e) => {
                e.target.value = transformAutoNum(e.target.value);
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
