/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Flex } from "antd";
import { Input } from "src/ui/Input/Input";
import { Text } from "src/ui/Text/Text";
import s from "./ReportLayoutAsideMenu.module.scss";
import { getSourceTitleColor } from "../../../../../../../utils/reports/getSourceTitleColor";

interface ReportLayoutAsideMenuProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

const standardReports = [
  "Краткая сводка",
  "Отчеты по найденным лицам",
  "Открытые данные",
  "Пересечение границы",
  "Связанные лица",
  "Адреса",
  "Доходы",
  "Результаты скоринга",
  "Карта заказов",
  "Телефонная книга",
];

const getColoredItem = (value: string, search: string) => {
  const lowValue = value.toLocaleLowerCase();
  const lowSearch = search.toLocaleLowerCase();
  const start = lowValue.indexOf(lowSearch);
  const end = start + search.length;
  return start !== -1 ? (
    <>
      {value.slice(0, start)}
      <span className={s.colored}>{value.slice(start, end)}</span>
      {value.slice(end, value.length)}
    </>
  ) : (
    value
  );
};

export const ReportLayoutAsideMenu: FC<ReportLayoutAsideMenuProps> = ({
  collapsed,
  onCollapse,
}) => {
  const [search, setSearch] = useState<string>("");

  const [reports, setReports] = useState<string[]>([]);

  const searchHandler = (value: string) => setSearch(value);

  const getStandardReports = useMemo(() => {
    const elements = document.querySelectorAll("div[data-name='block-report']");
    const result: string[] = [];
    elements.forEach((value) => {
      if (standardReports.includes(value.id)) {
        result.push(value.id);
      }
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const loadReports = () => {
    const elements = document.querySelectorAll("div[data-name='block-report']");
    const result: string[] = [];
    elements.forEach((value) => {
      if (
        value.id.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
        !getStandardReports.includes(value.id)
      ) {
        result.push(value.id);
      }
    });
    setReports(result);
  };

  useEffect(loadReports, [search, getStandardReports, collapsed]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const summaryBlock = document.getElementById("Краткая сводка");
      if (summaryBlock) {
        onCollapse(false);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [onCollapse]);

  const scrollToBlock = useCallback((block = "") => {
    const elements = document.querySelectorAll("div[data-name='block-report']");
    elements.forEach((value) => {
      if (value.id === block) {
        value.scrollIntoView({
          behavior: "smooth",
        });
      }
    });
  }, []);

  return (
    <div>
      {!collapsed && (
        <Flex vertical gap={16} className={s.menu}>
          <Text variant="label-5">Навигация</Text>
          <Input
            placeholder="Поиск по отчёту"
            value={search}
            onUpdate={searchHandler}
          />
          {getStandardReports.map((value) => (
            <div
              onClick={() => scrollToBlock(value)}
              className={s.standard_item}
              key={value}
            >
              <Text variant="header-1">{value}</Text>
            </div>
          ))}
          <Flex className={s.items} vertical gap={16}>
            {reports.map((value, index) => (
              <div
                onClick={() => scrollToBlock(value)}
                className={s.item}
                // eslint-disable-next-line react/no-array-index-key
                key={`${value}${index}`}
              >
                <Text variant="body-2" color={getSourceTitleColor(value)}>
                  {getColoredItem(value, search)}
                </Text>
              </div>
            ))}
          </Flex>
        </Flex>
      )}
    </div>
  );
};
