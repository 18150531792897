import { MdSnippetFolder, MdAnalytics } from "react-icons/md";
// import { IoDocumentText } from "react-icons/io5";
import { TbShieldSearch } from "react-icons/tb";
import { ENavMenuItem, MAIN_TAB_QUERY, MainTab } from "@pages/Main/constants";
import { AppRoute } from "src/app/routes/constants";
import { ReactNode } from "react";
import { generatePath } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";

export enum MenuGroup {
  Checking = "checking",
  Cabinet = "cabinet",
  Organization = "organization",
  Info = "info",
}

type MenuItem = {
  key: string;
  title: string;
  meta: string;
  icon?: ReactNode;
} & (
  | {
      href: string;
      children?: never;
    }
  | {
      children: MenuSubItem[];
      href?: never;
    }
);

interface MenuSubItem {
  key: string;
  title: string;
  meta: string;
  href: string;
}

interface GetMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

export const getMenuItems = ({
  group_id,
  user_id,
}: GetMenuItemsProps): MenuItem[] => [
  {
    key: MenuGroup.Checking,
    title: "Проверка",
    meta: "проверка",
    icon: <TbShieldSearch size={24} />,
    children: [
      // {
      //   key: MainTab.Dossier,
      //   title: 'Полное досье 🔥',
      //   href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Dossier}`,
      // },
      {
        key: MainTab.Fio,
        title: "По ФИО",
        meta: "по фио,by fio",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Fio}`,
      },
      {
        key: MainTab.Phone,
        title: "По телефону",
        meta: "по телефону,by phone",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Phone}`,
      },
      {
        key: MainTab.Address,
        title: "По адресу",
        meta: "по адресу,by address",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Address}`,
      },
      {
        key: MainTab.InnOrSnils,
        title: "По ИНН или СНИЛС",
        meta: "по ИНН или СНИЛС,по СНИЛС или ИНН,by inn or snils,by snils or inn",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.InnOrSnils}`,
      },
      {
        key: MainTab.Passport,
        title: "По паспорту",
        meta: "по паспорту,by passport",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Passport}`,
      },
      {
        key: MainTab.Auto,
        title: "По номеру авто",
        meta: "по номеру авто,by auto number",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Auto}`,
      },
      {
        key: MainTab.DriverLicense,
        title: "По ВУ",
        meta: "по ВУ,by driver license",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.DriverLicense}`,
      },
      {
        key: MainTab.Company,
        title: "По юр. лицам",
        meta: "по юр. лицам,by company",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Company}`,
      },
      {
        key: MainTab.Photo,
        title: "По фото",
        meta: "по фото,by photo",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Photo}`,
      },
      {
        key: MainTab.CreditRating,
        title: "Кредитный рейтинг",
        meta: "кредитный рейтинг,credit rating",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.CreditRating}`,
      },
      {
        key: MainTab.Scoring,
        title: "Скоринг",
        meta: "скоринг,scoring",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Scoring}`,
      },
      {
        key: MainTab.Info,
        title: "По ключевому слову",
        meta: "по ключевому слову,by keyword",
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Info}`,
      },
    ],
  },
  {
    key: MenuGroup.Cabinet,
    title: "Кабинет",
    meta: "кабинет,cabinet",
    icon: <MdAnalytics size={24} />,
    children: [
      ...(group_id && user_id
        ? [
            {
              key: ENavMenuItem.Analytics,
              title: "Аналитика",
              meta: "аналитика,analytics",
              href: generatePath(AppRoute.Analytics, {
                group_id: String(group_id),
                user_id: String(user_id),
              }),
            },
          ]
        : []),
      ...(group_id
        ? [
            {
              key: ENavMenuItem.Employees,
              title: "Сотрудники",
              meta: "сотрудники,employees",
              href: generatePath(AppRoute.Employees, {
                group_id: String(group_id),
              }),
            },
            {
              key: ENavMenuItem.Accesses,
              title: "Доступы",
              meta: "доступы,accesses",
              href: generatePath(AppRoute.Accesses, {
                group_id: String(group_id),
              }),
            },
          ]
        : []),
    ],
  },
  {
    key: MenuGroup.Organization,
    title: "Организация",
    meta: "организация,organization",
    icon: <MdSnippetFolder size={24} />,
    children: [
      {
        key: ENavMenuItem.History,
        title: "История проверок",
        meta: "история проверок,history",
        href: AppRoute.History,
      },
      // { key: "16", title: 'Опция 2' },
    ],
  },
  {
    key: MenuGroup.Info,
    title: "Инфо",
    meta: "инфо,info,информация,information",
    icon: <IoDocumentText size={24} />,
    children: [
      {
        key: ENavMenuItem.Contacts,
        title: "Контакты",
        meta: "контакты,contacts",
        href: AppRoute.Contacts,
      },
      {
        key: ENavMenuItem.Support,
        title: "Поддержка",
        meta: "поддержка,support",
        href: AppRoute.Support,
      },
    ],
  },
  {
    key: ENavMenuItem.Telegram,
    title: "Телеграм бот",
    meta: "телеграм бот,telegram bot,telegram,bot",
    href: AppRoute.Telegram,
  },
  // { key: "20", title: 'Обновления' },
];

interface GetFlatMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

interface FlatMenuItem {
  key: string;
  title: string;
  meta: string;
  href: string;
  icon?: ReactNode;
}

export const getFlatMenuItems = ({
  group_id,
  user_id,
}: GetFlatMenuItemsProps): FlatMenuItem[] => {
  const tree = getMenuItems({ group_id, user_id });

  return tree
    .map((item) => {
      if (item.children) {
        return item.children.map((subItem) => ({
          ...subItem,
          icon: item.icon,
        }));
      }

      return item;
    })
    .flat();
};
