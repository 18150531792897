import { Text } from "src/ui/Text/Text";
import { DataItem } from "@pages/Analytics/components/TypeCheckPaper/types";
import {
  searchCriteriaColors,
  searchCriteriaDescriptions,
} from "@pages/Analytics/components/TypeCheckPaper/constants";
import s from "./Legend.module.scss";

export interface LegendProps {
  dataItems: DataItem[];
}

export const Legend = ({ dataItems }: LegendProps) => (
  <div className={s.container}>
    {dataItems.map((dataItem: DataItem) => (
      <div key={dataItem.name} className={s.container__item}>
        <div
          className={s.container__item__marker}
          style={{
            backgroundColor: searchCriteriaColors[dataItem.name],
          }}
        />
        <Text variant="body-2">
          {searchCriteriaDescriptions[dataItem.name]}
        </Text>
      </div>
    ))}
  </div>
);
