import { Flex } from "antd";
import { FC, useCallback, useRef, useState } from "react";
import { Block } from "src/ui/Block/Block";
import { useSearchParams } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { debounce } from "lodash";
import { Input } from "src/ui/Input/Input";
import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import s from "./Search.module.scss";
import { SearchAnalytics } from "./components/SearchAnalytics/SearchAnalytics";
import { SearchReports } from "./components/SearchReports/SearchReports";

export const Search: FC = () => {
  const [filterUi, setFilterUi] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const prevFilter = useRef<string>("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setSearchParams((currSearchParams) => ({
        ...currSearchParams,
        query: value,
      }));
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title={`Результаты поиска${query ? ` "${query}"` : ""}`}
        mapPathToTitle={{ [AppRoute.Search]: "Системный поиск" }}
        paths={[AppRoute.Search]}
      />
      <Block>
        <Flex vertical gap={12}>
          <Input
            placeholder="Поиск"
            value={filterUi}
            onUpdate={onFilterUpdate}
          />
          <SearchAnalytics query={query} prevQuery={prevFilter.current} />
          <SearchReports query={query} prevQuery={prevFilter.current} />
        </Flex>
      </Block>
    </Flex>
  );
};
