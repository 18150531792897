import { useMutation } from "@tanstack/react-query";

import { QueryKey } from "@api/constants";
import { queryClient } from "@api/client";
import { telegramUserBotActivate } from "../queries";

export const useTelegramUserBotActivate = () =>
  useMutation({
    mutationFn: telegramUserBotActivate,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QueryKey.TelegramUserBotAllClear(),
      }),
  });
