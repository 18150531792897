import { Flex } from "antd";
import { FC } from "react";
import { LuChevronLeft } from "react-icons/lu";
import { Link } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { BreadCrumps } from "src/components/BreadCrumps/BreadCrumps";
import { Button } from "src/ui/Button/Button";
import { Text } from "src/ui/Text/Text";
import s from "./PageHeader.module.scss";

interface PageHeaderProps {
  title: string;
  mapPathToTitle: Record<string, string>;
  paths?: string[];
  prev?: string;
  onClick?: () => void;
  hasBreadCrumbs?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  mapPathToTitle,
  paths,
  prev = AppRoute.Lk,
  onClick,
  hasBreadCrumbs = true,
}) => (
  <Flex vertical gap={8}>
    <Link to={prev} className={s.link}>
      <Flex gap={4} align="center">
        <Button onClick={onClick} type="flat" pin="round">
          <LuChevronLeft size={24} />
        </Button>
        <Text variant="header-8">{title}</Text>
      </Flex>
    </Link>
    {hasBreadCrumbs && (
      <BreadCrumps mapPathToTitle={mapPathToTitle} paths={paths} />
    )}
  </Flex>
);
