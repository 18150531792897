export enum AppRoute {
  Admin = "admin",
  Root = "/",

  Lk = "/lk",
  History = "/lk/history",
  Telegram = "/lk/telegram",
  User = "/lk/user",
  Documents = "/lk/documents",
  Search = "/lk/search",
  Contacts = "/lk/contacts",
  Analytics = "/lk/group/:group_id/analytics/user/:user_id",
  Accesses = "/lk/group/:group_id/accesses",
  Employees = "/lk/group/:group_id/employees",
  AccessesAdd = "/lk/group/:group_id/accesses/add",
  AccessesEdit = "/lk/group/:group_id/accesses/edit/user/:user_id",
  Alerts = "/lk/alerts",
  Support = "/lk/support",

  Report = "/report/:reportId",
  Tariffs = "/tariffs",
}
