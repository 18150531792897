import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { RetrieveCardPrint } from "src/components/RetrieveCard/types";
import {
  isReportsOpenSourcesIoSphere,
  ReportsPhoneRetrieveResponse,
  ReportStatus,
  ReportType,
} from "@api/report";
import { OpenedData } from "@entities/SearchFull/components/OpenedData/OpenedData";
import { PhoneBook } from "@entities/SearchPhone/components/PhoneBook/PhoneBook";
import { Data } from "../../components/Data/Data";

export interface GeneratePdfParams {
  report: ReportsPhoneRetrieveResponse;
  type: ReportType;
  reportId?: string;
}

export const generatePdf = ({
  report,
  type,
  reportId,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      {report?.person?.status && (
        <FullDossier
          print={RetrieveCardPrint.print}
          phones={report?.person?.person_info?.phones}
          snilses={report?.person?.person_info?.snilses}
          inns={report?.person?.person_info?.inns}
          passports={report?.person.person_info?.passports}
          emails={report?.person?.person_info?.emails}
          requestDescribe={report?.request_describe}
          autos={report?.person?.person_info?.autos}
          persons={report?.person?.person_info?.persons}
          otherLastNames={report?.other_last_names}
          type={type}
          status={report?.person?.status}
        />
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);

  const openData = ReactDOMServer.renderToString(
    <>
      {report?.open_sources && (
        <OpenedData
          print={RetrieveCardPrint.print}
          openSources={report?.open_sources}
          status={
            report?.open_sources &&
            isReportsOpenSourcesIoSphere(report?.open_sources)
              ? report?.open_sources?.iosphere?.status
              : ReportStatus.Finished
          }
        />
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", openData);

  const phoneBook = ReactDOMServer.renderToString(
    <>
      {report?.person?.status && (
        <PhoneBook
          print={RetrieveCardPrint.print}
          phoneBook={report?.person?.person_info?.phone_book}
          status={report?.person?.status}
        />
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", phoneBook);

  report?.person?.person_info?.data
    ?.slice(0, 10)
    .forEach((reportsPersonInfoData, index) => {
      const data = ReactDOMServer.renderToString(
        <Data
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          print={RetrieveCardPrint.print}
          reportsPersonInfoData={reportsPersonInfoData}
          status={report?.person?.status}
        />,
      );

      outputHtml.insertAdjacentHTML("beforeend", data);
    });

  const opt = {
    margin: 0.2,
    filename: `${reportId ?? "download"}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    pagebreak: { mode: ["css", "legacy"] },
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return html2pdf().set(opt).from(outputHtml).save();
};
