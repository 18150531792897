import { FC, useCallback, useMemo, useState } from "react";
import { Flex } from "antd";
import { Toggle } from "src/ui/Toggle/Toggle";
import { MainTabProps } from "../../types";
import { MainTabCreditRatingNormal } from "./components/MainTabCreditRatingNormal/MainTabCreditRatingNormal";
import { MainTabCreditRatingFull } from "./components/MainTabCreditRatingFull/MainTabCreditRatingFull";
import s from "./MainTabCreditRating.module.scss";

type MainTabCreditRatingProps = MainTabProps;

export const MainTabCreditRating: FC<MainTabCreditRatingProps> = ({
  onSubmit,
}) => {
  const [type, setType] = useState<"full" | "normal">("normal");

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("full");
    } else {
      setType("normal");
    }
  }, []);

  const ViewToggle = useMemo(
    () => (
      <Toggle
        className={s.checkbox_extended}
        value={type === "full"}
        onChange={onTypeChange}
        title="Расширенный кредитный рейтинг"
      />
    ),
    [onTypeChange, type],
  );

  return (
    <Flex vertical gap={24}>
      {type === "normal" ? (
        <MainTabCreditRatingNormal
          onSubmit={onSubmit}
          viewToggle={ViewToggle}
        />
      ) : (
        <MainTabCreditRatingFull onSubmit={onSubmit} viewToggle={ViewToggle} />
      )}
    </Flex>
  );
};
