import React from "react";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { RetrieveCardPrint } from "src/components/RetrieveCard/types";
import { ReportsCreditRatingRetrieveResponse, ReportType } from "@api/report";
import { CreditRatingResult } from "@entities/RetrieveCreditRating/components/CreditRatingResult/CreditRatingResult";

export interface GeneratePdfParams {
  report: ReportsCreditRatingRetrieveResponse;
  type: ReportType;
  reportId?: string;
}

export const generatePdf = ({
  report,
  type,
  reportId,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const fullDossier = ReactDOMServer.renderToString(
    <>
      {report?.request_describe && (
        <FullDossier
          print={RetrieveCardPrint.print}
          requestDescribe={report?.request_describe}
          type={type}
        />
      )}
    </>,
  );
  outputHtml.insertAdjacentHTML("beforeend", fullDossier);

  const creditRatingResult = ReactDOMServer.renderToString(
    <CreditRatingResult
      print={RetrieveCardPrint.print}
      creditRating={report?.credit_rating}
      status={report?.credit_rating?.status}
    />,
  );
  outputHtml.insertAdjacentHTML("beforeend", creditRatingResult);

  const opt = {
    margin: 0.2,
    filename: `${reportId ?? "download"}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    pagebreak: { mode: ["css", "legacy"] },
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return html2pdf().set(opt).from(outputHtml).save();
};
