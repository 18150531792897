import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
// import { AccessesApi } from './components/AccessesApi/AccessesApi';
import { AppRoute } from "src/app/routes/constants";
import { useParams } from "react-router-dom";
import { AccessesSettings } from "src/components/AccessesSettings/AccessesSettings";
import { FC } from "react";
import { useGroup } from "@api/groups/queries/useGroup";
import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import s from "./AccessesEdit.module.scss";

export const AccessesEdit: FC = () => {
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  if (!group) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Редактировать доступ"
        mapPathToTitle={{
          [AppRoute.Accesses]: "Доступы",
          [AppRoute.AccessesEdit]: "Редактировать доступ",
        }}
        paths={[AppRoute.Accesses, AppRoute.AccessesEdit]}
      />
      <Block>
        <Flex vertical gap={60}>
          <AccessesSettings group={group} />
        </Flex>
      </Block>
      <div className={s.wrapper__dummyDrawer} />
    </Flex>
  );
};
