import { useMutation } from "@tanstack/react-query";

import { QueryKey } from "@api/constants";
import { queryClient } from "@api/client";
import { telegramUserBotApprove } from "../queries";

export const useTelegramUserBotApprove = () =>
  useMutation({
    mutationFn: telegramUserBotApprove,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QueryKey.TelegramUserBotAllClear(),
      }),
  });
