import { FC, PropsWithChildren } from "react";

import { Flex, Modal as ModalComponent } from "antd";
import cn from "classnames";
import { ReactComponent as Close } from "src/assets/close.svg";
import styles from "./ModalView.module.scss";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";

export interface ModalViewProps {
  isModalOpen: boolean;
  onClose: () => void;
  maskClosable?: boolean;
  title?: string;
  closable?: boolean;
}

export const ModalView: FC<PropsWithChildren<ModalViewProps>> = ({
  isModalOpen,
  onClose,
  children,
  maskClosable,
  title,
  closable = true,
  ...rest
}) => (
  <ModalComponent
    open={isModalOpen}
    onCancel={onClose}
    footer={null}
    closable={false}
    maskClosable={maskClosable}
    centered
    className={cn(styles.modal, { [styles.modal_nonClosable]: !maskClosable })}
    {...rest}
  >
    {title || closable ? (
      <Flex align="center">
        {title ? <Text variant="header-7">{title}</Text> : null}
        {closable ? (
          <Button
            pin="round"
            type="flat"
            size="small"
            className={styles.modal__close}
            onClick={onClose}
          >
            <Close width={24} height={24} />
          </Button>
        ) : null}
      </Flex>
    ) : null}
    {children}
  </ModalComponent>
);
