import { FC } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdDownload } from "react-icons/md";
import { Button } from "src/ui/Button/Button";
import s from "./DownloadReportPdfButton.module.scss";

interface DownloadReportPdfButtonProps {
  isLoadingGeneratePdf?: boolean;
  handleGeneratePdf?: () => void;
}

export const DownloadReportPdfButton: FC<DownloadReportPdfButtonProps> = ({
  isLoadingGeneratePdf,
  handleGeneratePdf,
}) => (
  <Button
    size="small"
    disabled={isLoadingGeneratePdf}
    width="full"
    className={s.button}
    onClick={handleGeneratePdf}
  >
    {isLoadingGeneratePdf ? (
      <AiOutlineLoading3Quarters className={s.button__loader} />
    ) : (
      <>
        Скачать отчет
        <MdDownload size="20" />
      </>
    )}
  </Button>
);
