import { useCallback, useState, FC, useEffect, useRef, useMemo } from "react";
import { Flex, List } from "antd";
import { Input } from "src/ui/Input/Input";
import { Pagination } from "src/ui/Pagination/Pagination";
import debounce from "lodash/debounce";
import { motion, AnimatePresence } from "framer-motion";
import { useTelegramUserBotRetrieveAll } from "@api/telegram/queries/useTelegramUserBotRetrieveAll";
import { TelegramUserBot } from "@api/telegram/types";
import { TelegramListCard } from "./components/TelegramListCard/TelegramListCard";
import s from "./TelegramList.module.scss";

const DURATION_MS = 500;

interface TelegramListViewProps {
  bots: TelegramUserBot[];
}

const TelegramListView: FC<TelegramListViewProps> = ({ bots }) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated(true);

    setTimeout(() => {
      setIsAnimated(false);
    }, DURATION_MS);
  }, []);

  if (!isAnimated) {
    return (
      <>
        {bots.map((bot) => (
          <List.Item key={bot.id}>
            <TelegramListCard key={bot.id} bot={bot} />
          </List.Item>
        ))}
      </>
    );
  }

  return (
    <AnimatePresence>
      {bots.map((bot) => (
        <motion.li
          key={bot.id}
          layout
          transition={{ ease: "easeOut", duration: DURATION_MS / 1_000 }}
          initial={{ opacity: 0, y: -215 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 215 }}
        >
          <TelegramListCard key={bot.id} bot={bot} />
        </motion.li>
      ))}
    </AnimatePresence>
  );
};

interface TelegramListProps {
  withPagination?: boolean;
  filterBots?: (bot: TelegramUserBot) => boolean;
  limit?: number;
}

export const TelegramList: FC<TelegramListProps> = ({
  withPagination = false,
  filterBots,
  limit = 5,
}) => {
  const [filterUi, setFilterUi] = useState("");
  const [filter, setFilter] = useState("");
  const prevFilter = useRef<string>("");

  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
      if (value !== prevFilter.current) {
        setPage(1);
      }
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const requestData = useMemo(
    () => ({
      limit,
      offset: Number.isInteger(offset) ? offset : undefined,
    }),
    [limit, offset],
  );

  const { data: botsResponse, isSuccess: isBotsSuccess } =
    useTelegramUserBotRetrieveAll(requestData);
  /**
   * TODO move filter to backend
   */
  const bots = useMemo(() => {
    const filterLc = filter.toLowerCase();

    return botsResponse?.filter(
      (bot) =>
        (filterBots?.(bot) ?? true) &&
        (bot.telegram_username.toLowerCase().includes(filterLc) ||
          String(bot.id).toLowerCase().includes(filterLc)),
    );
  }, [botsResponse, filter, filterBots]);

  const totalCount = bots?.length;

  const pagination = useMemo(() => {
    if (withPagination && isBotsSuccess && totalCount) {
      const pagesCount = Math.ceil(totalCount / limit);

      if (pagesCount < 2) {
        return null;
      }

      return (
        <Pagination pagesCount={pagesCount} page={page} onUpdate={setPage} />
      );
    }

    return null;
  }, [withPagination, isBotsSuccess, limit, page, totalCount]);

  return (
    <Flex vertical gap={32}>
      <Input
        placeholder="Поиск по логину"
        value={filterUi}
        onUpdate={onFilterUpdate}
      />
      <Flex vertical gap={16} component="ul" className={s.list}>
        {isBotsSuccess && bots ? (
          <TelegramListView bots={bots} />
        ) : (
          Array.from({ length: 15 }).map((_v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <List.Item key={i}>
              <TelegramListCard isLoading />
            </List.Item>
          ))
        )}
      </Flex>
      {pagination}
    </Flex>
  );
};
