import { FC, useCallback } from "react";
import { Group } from "@api/groups";
import { MultipleInput } from "src/ui/MultipleInput/MultipleInput";
import {
  useAllowedIpCreateMutation,
  useAllowedIpDeleteMutation,
  useAllowedIpSearchQuery,
} from "@api/ip/mutations";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { isIp } from "src/utils/validation/isIp";

interface AllowedIpsInputProps {
  group: Group;
}

export const AllowedIpsInput: FC<AllowedIpsInputProps> = ({ group }) => {
  const { group_id } = group;

  const { data: allowedIps, isSuccess: isAllowedIpsSuccess } =
    useAllowedIpSearchQuery({ group_id });
  const { mutateAsync: createAllowedIp, isPending: isAllowedIpCreating } =
    useAllowedIpCreateMutation();
  const { mutateAsync: deleteAllowedIp, isPending: isAllowedIpDeleting } =
    useAllowedIpDeleteMutation();

  const [api] = useNotification();

  const onAdd = useCallback(
    async (ip: string) => {
      const isValid = await isIp(ip);

      if (!isValid) {
        api.error({ message: "Невалидный ip-адрес" });
        return;
      }

      createAllowedIp({
        group_id,
        ip,
      }).catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не создать разрешенный ip-адрес",
        });
      });
    },
    [createAllowedIp, group_id, api],
  );

  const onDelete = useCallback(
    async (ip: string) => {
      const isValid = await isIp(ip);

      if (!isValid) {
        api.error({ message: "Невалидный ip-адрес" });
        return;
      }

      deleteAllowedIp({
        ip,
      }).catch((error: unknown) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось удалить ip-адрес",
        });
      });
    },
    [deleteAllowedIp, api],
  );

  if (!isAllowedIpsSuccess) {
    return null;
  }

  return (
    <MultipleInput
      disabled={isAllowedIpCreating || isAllowedIpDeleting}
      values={(allowedIps || []).map(({ ip }) => ip)}
      onAdd={onAdd}
      onDelete={onDelete}
      placeholder="Введите IP, для разделения используйте пробел"
    />
  );
};
