import { FC, useCallback, useMemo } from "react";
import { Card as AntCard, Flex, Skeleton } from "antd";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { CommonModal } from "src/components/CommonModal/CommonModal";
import { Toggle } from "src/ui/Toggle/Toggle";
import { IoMdMore } from "react-icons/io";
import { Button } from "src/ui/Button/Button";
import { MenuProps } from "antd/lib";
import { Dropdown } from "src/ui/Dropdown/Dropdown";
import { useTelegramUserBotDelete } from "@api/telegram/mutations/useTelegramUserBotDelete";
import { TelegramUserBot, TelegramUserBotStatus } from "@api/telegram/types";
import { Text } from "src/ui/Text/Text";
import { toHumanReadableDate } from "src/utils/date/toHumanReadableDate";
import { useTelegramUserBotDecline } from "@api/telegram/mutations/useTelegramUserBotDecline";
import { useTelegramUserBotApprove } from "@api/telegram/mutations/useTelegramUserBotApprove";
import { useNotification } from "src/hooks/useNotification";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useTelegramUserBotDeactivate } from "@api/telegram/mutations/useTelegramUserBotDeactivate";
import { useTelegramUserBotActivate } from "@api/telegram/mutations/useTelegramUserBotActivate";
import { Status } from "./components/Status/Status";
import styles from "./TelegramListCard.module.scss";

interface TelegramListCardViewProps {
  bot: TelegramUserBot;
}

export const TelegramListCardView: FC<TelegramListCardViewProps> = ({
  bot,
}) => {
  const { created_at, id, status, telegram_username, active } = bot;
  const { openModal } = useModalMessage();
  const { mutateAsync: deleteBot, isPending: isBotDeleting } =
    useTelegramUserBotDelete();
  const { mutateAsync: declineBot, isPending: isBotDecline } =
    useTelegramUserBotDecline();
  const { mutateAsync: approveBot, isPending: isBotApprove } =
    useTelegramUserBotApprove();
  const { mutateAsync: deactivateBot, isPending: isBotDeactivating } =
    useTelegramUserBotDeactivate();
  const { mutateAsync: activateBot, isPending: isBotActivating } =
    useTelegramUserBotActivate();

  const [api] = useNotification();

  const onDelete = useCallback(() => {
    openModal({
      children: (
        <CommonModal
          type="normal"
          title={
            <>
              Вы уверены, что хотите удалить этого бота?
              <br />
              Это действие необратимо
            </>
          }
          primaryButtonProps={{
            children: "Да, удалить",
            onClick: async () => {
              if (telegram_username) {
                await deleteBot({ telegram_username });
              }
            },
            disabled: isBotDeleting,
          }}
          secondaryButtonProps={{ children: "Отменить" }}
        />
      ),
    });
  }, [telegram_username, openModal, isBotDeleting, deleteBot]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "delete",
        label: "Удалить",
        onClick: onDelete,
      },
    ],
    [onDelete],
  );

  const onToggleUser = useCallback(async () => {
    if (!telegram_username) return;

    if (active) {
      await deactivateBot({ telegram_username }).catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось деактивировать бота",
        });
      });
    } else {
      await activateBot({ telegram_username }).catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось активировать бота",
        });
      });
    }
  }, [telegram_username, api, deactivateBot, activateBot, active]);

  const onDeclineBot = useCallback(async () => {
    if (!telegram_username) return;

    await declineBot({ telegram_username })
      .then(() => {
        api.success({
          message: "Чат успешно подтвержден",
        });
      })
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось подтвердить чат",
        });
      });
  }, [telegram_username, api, declineBot]);

  const onApproveBot = useCallback(async () => {
    if (!telegram_username) return;

    await approveBot({ telegram_username })
      .then(() => {
        api.success({
          message: "Чат успешно отклонен",
        });
      })
      .catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось отклонить чат",
        });
      });
  }, [telegram_username, api, approveBot]);

  return (
    <Flex component="section" vertical gap={20}>
      <Flex
        className={styles.card__header}
        gap={20}
        align="center"
        justify="space-between"
      >
        <Flex gap={16} align="center" className={styles.card__permissions}>
          <Status status={status} active={Boolean(active)} />
          <Text variant="body-2" color="darkGray">
            {toHumanReadableDate(created_at)}
          </Text>
        </Flex>
        {status === TelegramUserBotStatus.AwaitingApproval ? null : (
          <Dropdown
            items={items}
            mouseLeaveDelay={0.6}
            placement="bottomRight"
            // eslint-disable-next-line react/no-unstable-nested-components
            body={({ onOpen }) => (
              <Button
                type="flat"
                pin="round"
                icon={<IoMdMore size={24} />}
                className={styles.card__header_dropdown}
                onClick={onOpen}
              />
            )}
          />
        )}
      </Flex>
      {id ? (
        <Text variant="body-2" color="darkGray">
          {id}
        </Text>
      ) : null}
      <Flex gap={8} align="center" justify="space-between">
        <Text variant="label-5">{telegram_username}</Text>
        {status === TelegramUserBotStatus.AwaitingApproval ? (
          <Flex gap={12}>
            <Button
              size="small"
              type="secondary"
              onClick={onDeclineBot}
              disabled={isBotDecline}
            >
              Отменить
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={onApproveBot}
              disabled={isBotApprove}
            >
              Подтвердить
            </Button>
          </Flex>
        ) : null}
        {TelegramUserBotStatus.Approved ? (
          <Toggle
            value={Boolean(active)}
            disabled={isBotDeactivating || isBotActivating}
            onChange={onToggleUser}
          />
        ) : null}
      </Flex>
    </Flex>
  );
};

interface TelegramListCardProps {
  bot?: TelegramUserBot;
  isLoading?: boolean;
}

export const TelegramListCard: FC<TelegramListCardProps> = ({
  bot,
  isLoading,
}) => (
  <AntCard className={styles.card}>
    <Skeleton loading={isLoading || !bot}>
      {bot ? <TelegramListCardView bot={bot} /> : null}
    </Skeleton>
  </AntCard>
);
