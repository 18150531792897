import React, { FC, PropsWithChildren } from "react";
import { Flex, Modal } from "antd";
import { MdFilterList } from "react-icons/md";
import s from "./TableSettingsModal.module.scss";
import { Text } from "../../../../ui/Text/Text";
import { Button } from "../../../../ui/Button/Button";
import { useBooleanState } from "../../../../hooks/useBooleanState";

interface ITableSettingsModalProps extends PropsWithChildren {
  modalTitle: string;
  title: string;
  onApply: () => void;
}

const TableSettingsModal: FC<ITableSettingsModalProps> = ({
  children,
  modalTitle,
  title,
  onApply,
}) => {
  const {
    state: isModalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  return (
    <>
      <Button type="secondary" size="small" onClick={showModal}>
        <Text variant="subheader-2" color="darkGray" className={s.title}>
          <MdFilterList /> {title}
        </Text>
      </Button>
      <Modal
        destroyOnClose
        className={s.modal}
        title={<Text variant="subheader-5">{modalTitle}</Text>}
        open={isModalVisible}
        onCancel={hideModal}
        footer={[
          <Text key="cancel" onClick={hideModal} className={s.modal_cancel}>
            Отменить
          </Text>,
          <Button
            key="submit"
            size="small"
            type="primary"
            onClick={() => {
              onApply();
              hideModal();
            }}
          >
            Применить
          </Button>,
        ]}
      >
        <Flex vertical gap={8}>
          {children}
        </Flex>
      </Modal>
    </>
  );
};

export default TableSettingsModal;
