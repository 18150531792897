import { FC } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Link } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { HistoryList } from "src/components/HistoryList/HistoryList";
import { ENavMenuItem, MainTab } from "@pages/Main/constants";

interface MainHistoryProps {
  tab: MainTab | ENavMenuItem;
}

export const MainHistory: FC<MainHistoryProps> = ({ tab }) => (
  <Flex vertical gap={32}>
    <Text variant="header-6">
      <Link to={AppRoute.History}>История проверок</Link>
    </Text>
    <HistoryList tab={tab} />
  </Flex>
);
