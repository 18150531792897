import { useEffect, useState } from "react";
import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
// import { UserSettingsApi } from './components/UserSettingsApi/UserSettingsApi';
import { AppRoute } from "src/app/routes/constants";
import { useDocumentDownload } from "@api/documents/mutations/useDocumentDownload";
import { useDocumentsRetrieveAll } from "@api/documents/mutations/useDocumentsRetrieveAll";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import { Document } from "@api/documents/types";
import { downloadFile } from "src/utils/download";
import s from "./Documents.module.scss";
import { DocumentCard } from "./components/DocumentCard/DocumentCard";

export const Documents = () => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const { data: user } = useCurrentUserQuery();
  const { mutate: documentsRetrieveAll } = useDocumentsRetrieveAll();
  const { mutate: documentDownload } = useDocumentDownload();
  const group = user?.groups?.[0];

  useEffect(() => {
    documentsRetrieveAll(
      { group_id: group?.group_id ?? 0 },
      {
        onSuccess(data) {
          setDocuments(data);
        },
      },
    );
  }, [documentsRetrieveAll, group?.group_id]);

  const onDownload = (file_name: string) => {
    documentDownload(
      {
        group_id: group?.group_id ?? 0,
        file_name,
      },
      {
        onSuccess(data) {
          downloadFile(data, file_name);
        },
      },
    );
  };
  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Мои документы"
        mapPathToTitle={{ [AppRoute.Documents]: "Мои документы" }}
        paths={[AppRoute.Documents]}
      />
      {documents.map((doc) => (
        <Block key={doc.file_name}>
          <DocumentCard
            onDownload={() => onDownload(doc.file_name)}
            title={doc.file_name}
            size={doc.size}
          />
        </Block>
      ))}
    </Flex>
  );
};
