import { FullDossier } from "src/components/FullDossier/FullDossier";
import { ReportCommonProps } from "@entities/types";
import React, { useCallback, useState } from "react";
import { ReportsCreditRatingRetrieveResponse, ReportStatus } from "@api/report";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { calculateReportStatus } from "src/utils/getReportStatus";
import { generatePdf } from "./generatePdf";
import { CreditRatingResult } from "./components/CreditRatingResult/CreditRatingResult";
import s from "./RetrieveCreditRating.module.scss";

export type RetrieveCreditRatingProps = {
  report: ReportsCreditRatingRetrieveResponse;
} & ReportCommonProps;

export const RetrieveCreditRating = ({
  report,
  type,
  reportId,
}: RetrieveCreditRatingProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.credit_rating?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <div className={s.container}>
        {report?.request_describe && (
          <FullDossier
            requestDescribe={report?.request_describe}
            type={type}
            handleGeneratePdf={handleGeneratePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
            title=""
          />
        )}
        <CreditRatingResult
          creditRating={report?.credit_rating}
          status={report?.credit_rating?.status}
        />
      </div>
    </RetrieveStatus>
  );
};
