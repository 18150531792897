import { Face } from "@api/report";
import cn from "classnames";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import {
  RetrieveCardPrint,
  RetrieveCardVariant,
} from "src/components/RetrieveCard/types";
import { Row } from "@entities/RetrievePhotoByFace/components/SearchResults/Row/Row";
import { Flex } from "antd";
import s from "./SearchResults.module.scss";

export type SearchResultsProps = {
  faces?: Face[] | null;
} & RetrieveCardCommonProps;

export const SearchResults = ({
  status,
  faces,
  print = RetrieveCardPrint.device,
}: SearchResultsProps) => (
  <RetrieveCard
    title="Результаты поиска"
    status={status}
    variant={RetrieveCardVariant.noBorder}
    print={print}
  >
    <Flex
      className={cn(
        s.container,
        print === RetrieveCardPrint.print && s.container_print,
      )}
      vertical
      gap={40}
    >
      {faces?.[0]?.candidates?.map((candidate) => (
        <Row candidate={candidate} key={candidate?.id} />
      ))}
    </Flex>
  </RetrieveCard>
);
