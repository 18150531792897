import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportStatus, useReportsPhotoByFaceRetrieve } from "@api/report";
import { RetrievePhotoByFace } from "@entities/RetrievePhotoByFace/RetrievePhotoByFace";
import { ReportCommonProps } from "@entities/types";

export const SearchPhotoWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: photoByFaceReport, isLoading } = useReportsPhotoByFaceRetrieve({
    id: reportId,
    status,
  });

  useEffect(
    () => setStatus(photoByFaceReport?.status),
    [photoByFaceReport?.status],
  );

  return (
    <Skeleton loading={isLoading}>
      {photoByFaceReport && (
        <RetrievePhotoByFace
          report={photoByFaceReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
