import { useParams } from "react-router-dom";
import { ReportStatus, useReportsScoringRetrieve } from "@api/report";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { RetrieveScoring } from "@entities/RetrieveScoring/RetrieveScoring";
import { useEffect, useState } from "react";

export const SearchScoringWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: scoringReport, isLoading } = useReportsScoringRetrieve({
    id: reportId,
    status,
  });

  useEffect(() => setStatus(scoringReport?.status), [scoringReport?.status]);

  return (
    <Skeleton loading={isLoading}>
      {scoringReport && (
        <RetrieveScoring
          report={scoringReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
