import { client } from "@api/client";

import { TelegramUserBotEndpoints } from "./endpoints";
import {
  TelegramUserBotActivateRequest,
  TelegramUserBotActivateResponse,
  TelegramUserBotApproveRequest,
  TelegramUserBotApproveResponse,
  TelegramUserBotDeactivateRequest,
  TelegramUserBotDeactivateResponse,
  TelegramUserBotDeclineRequest,
  TelegramUserBotDeclineResponse,
  TelegramUserBotDeleteRequest,
  TelegramUserBotDeleteResponse,
  TelegramUserBotRetrieveAllRequest,
  TelegramUserBotRetrieveAllResponse,
} from "./types";

export const telegramUserBotApprove = (data: TelegramUserBotApproveRequest) =>
  client.post<
    TelegramUserBotApproveResponse,
    TelegramUserBotApproveResponse,
    TelegramUserBotApproveRequest
  >(TelegramUserBotEndpoints.Approve, data);

export const telegramUserBotDecline = (data: TelegramUserBotDeclineRequest) =>
  client.post<
    TelegramUserBotDeclineResponse,
    TelegramUserBotDeclineResponse,
    TelegramUserBotDeclineRequest
  >(TelegramUserBotEndpoints.Decline, data);

export const telegramUserBotDelete = (data: TelegramUserBotDeleteRequest) =>
  client.post<
    TelegramUserBotDeleteResponse,
    TelegramUserBotDeleteResponse,
    TelegramUserBotDeleteRequest
  >(TelegramUserBotEndpoints.Delete, data);

export const telegramUserBotRetrieveAll = (
  data: TelegramUserBotRetrieveAllRequest,
) =>
  client.post<
    TelegramUserBotRetrieveAllResponse,
    TelegramUserBotRetrieveAllResponse,
    TelegramUserBotRetrieveAllRequest
  >(TelegramUserBotEndpoints.RetrieveAll, data);

export const telegramUserBotActivate = (data: TelegramUserBotActivateRequest) =>
  client.post<
    TelegramUserBotActivateResponse,
    TelegramUserBotActivateResponse,
    TelegramUserBotActivateRequest
  >(TelegramUserBotEndpoints.Activate, data);

export const telegramUserBotDeactivate = (
  data: TelegramUserBotDeactivateRequest,
) =>
  client.post<
    TelegramUserBotDeactivateResponse,
    TelegramUserBotDeactivateResponse,
    TelegramUserBotDeactivateRequest
  >(TelegramUserBotEndpoints.Deactivate, data);
