import { PieChart, Pie, Cell, Tooltip, PieProps } from "recharts";
import { DataItem } from "@pages/Analytics/components/TypeCheckPaper/types";

type PieChartComponentProps = {
  data: DataItem[];
  colors: string[];
  width?: number;
  height?: number;
} & PieProps;

export const PieChartComponent = ({
  data,
  colors,
  width,
  height,
  cx,
  cy,
  labelLine,
  outerRadius,
  innerRadius,
  fill,
  dataKey,
}: PieChartComponentProps) => (
  <PieChart width={width} height={height}>
    <Pie
      data={data}
      cx={cx}
      cy={cy}
      labelLine={labelLine}
      outerRadius={outerRadius}
      innerRadius={innerRadius}
      fill={fill}
      dataKey={dataKey}
    >
      {data?.map((entry, index) => (
        <Cell key={`cell-${entry.name}`} fill={colors[index]} />
      ))}
    </Pie>
    <Tooltip />
  </PieChart>
);
