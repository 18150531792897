import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "src/ui/Input/Input";
import { TextArea } from "src/ui/TextArea/TextArea";
import { ReactComponent as AddTicket } from "src/assets/add_ticket.svg";
import * as yup from "yup";
import { Button } from "src/ui/Button/Button";
import { useCreateChat } from "@api/support/mutations/useCreateChat";
import { AddTicketWrapper } from "./components/AddTicketWrapper/AddTicketWrapper";

interface AddTicketModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  getChats: () => void;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    message: yup.string().required(),
  })
  .required();

export const AddTicketModal: FC<AddTicketModalProps> = ({
  isModalOpen,
  onClose,
  getChats,
}) => {
  const { mutateAsync: createChat } = useCreateChat();

  const { control, watch, resetField } = useForm<{
    name: string;
    message: string;
    question?: string;
  }>({
    defaultValues: {
      name: "",
      message: "",
      question: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onCreateChatHandler = async () => {
    const message = watch("message");
    const name = watch("name");
    const data = await createChat({
      subject: name,
      question: message,
    });
    if (data) {
      resetField("name");
      resetField("message");
      getChats();
      onClose();
    }
  };

  return (
    <AddTicketWrapper isModalOpen={isModalOpen} onClose={onClose}>
      <>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              placeholder="Название тикета"
              required
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          render={({ field, fieldState }) => (
            <TextArea
              {...field}
              placeholder="Сообщение"
              required
              rows={10}
              validate={fieldState}
            />
          )}
        />
        <Button
          iconRight={<AddTicket width={20} height={20} />}
          onClick={onCreateChatHandler}
          size="big"
        >
          Создать тикет
        </Button>
      </>
    </AddTicketWrapper>
  );
};
