import { Layout } from "antd";
import { FC, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ModalMessageProvider } from "src/ui/Modal/ModalMessageProvider";
import { useClickOutside } from "src/hooks/useClickOutside";
import cn from "classnames";
import s from "./MainLayout.module.scss";
import { MainLayoutHeader } from "./components/MainLayoutHeader/MainLayoutHeader";
import { MainLayoutAside } from "./components/MainLayoutAside/MainLayoutAside";
import { MainLayoutUser } from "./components/MainLayoutUser/MainLayoutUser";
import { ReportLayoutAside } from "./components/ReportLayoutAside/ReportLayoutAside";
import { useBooleanState } from "../../../hooks/useBooleanState";
import MainLayoutAlerts from "./components/MainLayoutAlerts/MainLayoutAlerts";

const { Content } = Layout;

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [leftCollapsed, setLeftCollapsed] = useState(true);
  const [rightCollapsed, setRightCollapsed] = useState(true);
  const userButtonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const {
    state: alertsVisible,
    setTrue: showAlerts,
    setFalse: hideAlerts,
  } = useBooleanState();

  const { pathname } = useLocation();

  const { setWrapperRef } = useClickOutside({
    clickOutsideFn: (event: MouseEvent) => {
      if (!userButtonRef.current?.contains(event.target as Node)) {
        setRightCollapsed(true);
      }
    },
  });

  useLayoutEffect(() => {
    contentRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ModalMessageProvider>
      <Layout className={s.layout}>
        <MainLayoutHeader
          userButtonRef={userButtonRef}
          leftCollapsed={leftCollapsed}
          onLeftCollapse={setLeftCollapsed}
          rightCollapsed={rightCollapsed}
          onRightCollapse={setRightCollapsed}
          alertsVisible={alertsVisible}
          showAlerts={showAlerts}
          hideAlerts={hideAlerts}
        />
        <Layout className={s.mainLayout}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className={cn(
              s.alertsContainer,
              alertsVisible ? s.alertsContainer_show : s.alertsContainer_hide,
            )}
            onClick={hideAlerts}
          >
            <MainLayoutAlerts hideAlerts={hideAlerts} />
          </div>
          {pathname.includes("report") ? (
            <ReportLayoutAside
              collapsed={leftCollapsed}
              onCollapse={setLeftCollapsed}
            />
          ) : (
            <MainLayoutAside
              collapsed={leftCollapsed}
              onCollapse={setLeftCollapsed}
            />
          )}

          <Content className={s.content} ref={contentRef}>
            {children}
          </Content>
          <MainLayoutUser
            collapsed={rightCollapsed}
            onCollapse={setRightCollapsed}
            setWrapperRef={setWrapperRef}
          />
        </Layout>
      </Layout>
    </ModalMessageProvider>
  );
};
