import { FoundStatus, ReportStatus } from "@api/report/constants";
import { Text } from "src/ui/Text/Text";
import { Check } from "@api/report";
import { ReactComponent as Success } from "src/assets/success.svg";
import { ReactComponent as Cancel } from "src/assets/cancel.svg";
import s from "./Status.module.scss";

export const Status = ({ status, found, value }: Check) => {
  if (status === ReportStatus.InProgress)
    return <Text variant="subheader-3">Идет обработка</Text>;

  if (value && found === FoundStatus.found) {
    return (
      <div className={s.container}>
        <Success />
        <Text variant="subheader-3" color="green">
          {value}
        </Text>
      </div>
    );
  }

  return (
    <div className={s.container}>
      {found === FoundStatus.found && (
        <>
          <Success />
          <Text variant="subheader-3" color="green">
            Найден
          </Text>
        </>
      )}
      {found === FoundStatus.notFound && (
        <>
          <Success />
          <Text variant="subheader-3" color="green">
            Не найден
          </Text>
        </>
      )}
      {found === FoundStatus.error && (
        <>
          <Cancel />
          <Text variant="subheader-3" color="red">
            Что-то пошло не так
          </Text>
        </>
      )}
    </div>
  );
};
