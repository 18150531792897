import { validationRegex } from "src/utils/constants";
import { WordType, WordWithGuessedType } from "./types";

export const joinPassport = (
  pastedWords: WordWithGuessedType[],
): WordWithGuessedType[] => {
  const words = [...pastedWords];

  for (let i = 1; i < words.length; i += 1) {
    const prev = words[i - 1];
    const curr = words[i];

    // eslint-disable-next-line no-continue
    if (prev.type !== WordType.Other || curr.type !== WordType.Other) continue;

    const passportLike = `${prev.word} ${curr.word}`;
    const isPassport = validationRegex.passport.test(passportLike);
    if (isPassport) {
      words.splice(i - 1, 2, {
        word: passportLike,
        type: WordType.Passport,
      });
    }
  }

  return words;
};
