export const generatePassword = (): Promise<string> =>
  import("generate-password-browser").then(({ generate }) => {
    const generatedPassword = generate({
      symbols: true,
      lowercase: true,
      uppercase: true,
      numbers: true,
      exclude: "`,",
      length: 20,
    });

    return generatedPassword;
  });
