import { useParams } from "react-router-dom";
import { ReportStatus, useReportsCreditRatingRetrieve } from "@api/report";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { RetrieveCreditRating } from "@entities/RetrieveCreditRating/RetrieveCreditRating";
import { useEffect, useState } from "react";

export const SearchCreditRatingWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: creditRatingReport, isLoading } =
    useReportsCreditRatingRetrieve({
      id: reportId,
      status,
    });

  useEffect(
    () => setStatus(creditRatingReport?.status),
    [creditRatingReport?.status],
  );

  return (
    <Skeleton loading={isLoading}>
      {creditRatingReport && (
        <RetrieveCreditRating
          report={creditRatingReport}
          type={type}
          reportId={reportId}
        />
      )}
    </Skeleton>
  );
};
