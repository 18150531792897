import { FC, ReactNode } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Link } from "react-router-dom";
import s from "./SearchItem.module.scss";

interface SearchItemProps {
  title: string;
  icon?: ReactNode;
  description?: string;
  href: string;
  onClick: () => void;
}

export const SearchItem: FC<SearchItemProps> = ({
  title,
  icon,
  description,
  href,
  onClick,
}) => (
  <Link to={href} className={s.searchItem} onClick={onClick}>
    <Flex className={s.searchItem__content} gap={10}>
      {icon}
      <Flex vertical>
        <Text variant="header-2">{title}</Text>
        {description ? (
          <Text variant="body-5" color="darkGray">
            {description}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  </Link>
);
