import { FC, useCallback } from "react";
import { Alert, Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Input } from "src/ui/Input/Input";
import { Button } from "src/ui/Button/Button";
import { useBooleanState } from "src/hooks/useBooleanState";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useUsersChangePasswordMutation } from "@api/groups/mutations/useUsersChangePasswordMutation";
import { ChangePasswordRequest } from "@api/groups";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationMessage } from "src/utils/constants";
import { PiWarningBold } from "react-icons/pi";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { generatePassword } from "src/utils/generate/password";

interface UserPasswordViewProps {
  onEdit: () => void;
}

export const UserPasswordView: FC<UserPasswordViewProps> = ({ onEdit }) => (
  <Flex vertical gap={8}>
    <Text variant="body-5" color="darkGray">
      Текущий пароль
    </Text>
    <Input disabled placeholder="********" />
    <Button size="small" onClick={onEdit}>
      Сменить пароль
    </Button>
  </Flex>
);

interface ChangePasswordFormValues extends ChangePasswordRequest {
  new_password_confirm: string;
}

const schema = yup
  .object<ChangePasswordFormValues>()
  .shape({
    old_password: yup.string().required(validationMessage.Required),
    new_password: yup.string().required(validationMessage.Required),
    new_password_confirm: yup
      .string()
      .oneOf([yup.ref("new_password"), ""], "Пароль должен совпасть")
      .required(validationMessage.Required),
  })
  .required();

interface UserPasswordEditProps {
  onView: () => void;
}

export const UserPasswordEdit: FC<UserPasswordEditProps> = ({ onView }) => {
  const { control, handleSubmit, clearErrors, setValue } =
    useForm<ChangePasswordFormValues>({
      defaultValues: {
        old_password: "",
        new_password: "",
        new_password_confirm: "",
      },
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  const { mutateAsync: changePassword, error: changePasswordError } =
    useUsersChangePasswordMutation();

  const [api] = useNotification();

  const onGeneratePassword = useCallback(async () => {
    const generatedPassword = await generatePassword();

    clearErrors("new_password");
    clearErrors("new_password_confirm");
    setValue("new_password", generatedPassword);
    setValue("new_password_confirm", generatedPassword);
  }, [clearErrors, setValue]);

  const onSubmit = useCallback<SubmitHandler<ChangePasswordFormValues>>(
    (data) => {
      changePassword(data)
        .then(() => {
          api.success({
            message: "Пароль успешно изменён",
          });

          onView();
        })
        .catch((error) => {
          handleBackendErrors({
            api,
            error,
            message4xx: "Не удалось изменить пароль",
          });
        });
    },
    [changePassword, onView, api],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex vertical gap={16}>
        <Flex gap={8} vertical>
          <Text variant="body-5" color="darkGray">
            Текущий пароль
          </Text>
          <Controller
            name="old_password"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="Текущий пароль"
                required
                type="password"
                validate={fieldState}
                isError={!!changePasswordError}
              />
            )}
          />
        </Flex>
        <Flex gap={8} vertical>
          <Text variant="body-5" color="darkGray">
            Новый пароль
          </Text>
          <Controller
            name="new_password"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="Новый пароль"
                required
                validate={fieldState}
                isError={!!changePasswordError}
              />
            )}
          />
          <Button size="small" onClick={onGeneratePassword} type="secondary">
            Сгенерировать случайный пароль
          </Button>
        </Flex>
        <Flex gap={8} vertical>
          <Text variant="body-5" color="darkGray">
            Повторите пароль
          </Text>
          <Controller
            name="new_password_confirm"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder="Повторите пароль"
                required
                type="password"
                validate={fieldState}
                isError={!!changePasswordError}
              />
            )}
          />
        </Flex>
        {changePasswordError?.response?.status === 403 ? (
          <Alert
            icon={<PiWarningBold size={16} />}
            showIcon
            type="error"
            message="Текущий пароль не совпадает"
          />
        ) : null}
        <Flex gap={16}>
          <Button size="small" htmlType="submit">
            Сохранить новый пароль
          </Button>
          <Button size="small" onClick={onView} type="flat_secondary">
            Отменить
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

interface UserPasswordProps {}

export const UserPassword: FC<UserPasswordProps> = () => {
  const { state: isEdit, toggle } = useBooleanState(false);

  return (
    <Flex vertical gap={16}>
      <Text variant="subheader-4">Пароль</Text>
      {isEdit ? (
        <UserPasswordEdit onView={toggle} />
      ) : (
        <UserPasswordView onEdit={toggle} />
      )}
    </Flex>
  );
};
