import { ReportsFoundPersons } from "src/components/ReportsFoundPersons/ReportsFoundPersons";
import { Data } from "src/components/Data/Data";
import { RelatedPersons } from "src/components/RelatedPersons/RelatedPersons";
import { ReportCommonProps } from "@entities/types";
import {
  FoundStatus,
  isReportsOpenSourcesIoSphere,
  ReportsPhoneRetrieveResponse,
  ReportStatus,
} from "@api/report";
import { calculateReportStatus } from "src/utils/getReportStatus";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { OrderMap } from "@entities/SearchPhone/components/OrderMap/OrderMap";
import { OpenedData } from "@entities/SearchFull/components/OpenedData/OpenedData";
import { useCallback, useState } from "react";
import { PhoneBook } from "./components/PhoneBook/PhoneBook";
import s from "./SearchPhone.module.scss";
import { generatePdf } from "./generatePdf";

export type SearchPhoneProps = {
  report: ReportsPhoneRetrieveResponse;
} & ReportCommonProps;

export const SearchPhone = ({ report, type, reportId }: SearchPhoneProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [
            report?.person?.status,
            report?.sub_reports?.status,
            report?.possible_networks?.status,
            report?.open_sources?.iosphere?.status,
          ],
          person: report?.person?.status,
          found: report?.person?.found === FoundStatus.found,
        }) || report?.status
      }
    >
      <div className={s.container}>
        {report?.person?.status && (
          <FullDossier
            phones={report?.person?.person_info?.phones}
            snilses={report?.person?.person_info?.snilses}
            inns={report?.person?.person_info?.inns}
            passports={report?.person.person_info?.passports}
            emails={report?.person?.person_info?.emails}
            requestDescribe={report?.request_describe}
            autos={report?.person?.person_info?.autos}
            persons={report?.person?.person_info?.persons}
            type={type}
            status={report?.person?.status}
            otherLastNames={report?.other_last_names}
            handleGeneratePdf={handleGeneratePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
          />
        )}
        {report?.sub_reports?.status && (
          <ReportsFoundPersons
            report_list={report?.sub_reports?.report_list}
            status={report?.sub_reports?.status}
          />
        )}
        {report?.person?.status && (
          <OrderMap
            status={report?.order_map?.status}
            orderMap={report?.order_map?.data}
          />
        )}
        {report?.possible_networks?.status && (
          <RelatedPersons
            possibleNetworks={report?.possible_networks?.result}
            status={report?.possible_networks?.status}
          />
        )}
        {report?.open_sources && (
          <OpenedData
            openSources={report?.open_sources}
            status={
              report?.open_sources &&
              isReportsOpenSourcesIoSphere(report?.open_sources)
                ? report?.open_sources?.iosphere?.status
                : ReportStatus.Finished
            }
          />
        )}
        {report?.person?.status && (
          <PhoneBook
            phoneBook={report?.person?.person_info?.phone_book}
            status={report?.person?.status}
          />
        )}
        {report?.person?.person_info?.data?.map(
          (reportsPersonInfoData, index) => (
            <Data
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              reportsPersonInfoData={reportsPersonInfoData}
              status={report?.person?.status}
            />
          ),
        )}
      </div>
    </RetrieveStatus>
  );
};
