import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Input } from "src/ui/Input/Input";
import { Controller, useForm } from "react-hook-form";
import { Group } from "@api/groups";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationMessage } from "src/utils/constants";
import { useGroupsSetLoginDuration } from "@api/groups/mutations/useGroupsGrantPermissions";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";

const MINUTES_5_IN_MINUTES = 5;
const HOUR_4_IN_MINUTES = 240;
const DAY_30_IN_MINUTES = 43_200;

const nanosecondsToMinutes = (seconds: number) => seconds / 60_000_000_000;
const minutesToSeconds = (minutes: number) => minutes * 60;

interface SetLoginDurationFormValues {
  tokenDurationMinutes: number;
}

const schema = yup
  .object<SetLoginDurationFormValues>()
  .shape({
    tokenDurationMinutes: yup
      .number()
      .transform((v): number => {
        const numV = Number(v);
        return Number.isFinite(numV) ? numV : 0;
      })
      .positive(validationMessage.Required)
      .min(MINUTES_5_IN_MINUTES, validationMessage.MinNumber)
      .max(DAY_30_IN_MINUTES, validationMessage.MaxNumber)
      .required(validationMessage.Required),
  })
  .required();

interface UserInactionProps {
  group: Group;
}

export const UserInaction: FC<UserInactionProps> = ({ group }) => {
  const { group_id, token_duration } = group;

  const currentTokenDurationMinutes = useMemo<number>(() => {
    if (!token_duration) {
      return HOUR_4_IN_MINUTES;
    }

    const tokenDurationNumber = Number(token_duration);
    return Number.isFinite(tokenDurationNumber)
      ? nanosecondsToMinutes(tokenDurationNumber)
      : HOUR_4_IN_MINUTES;
  }, [token_duration]);

  const { control, handleSubmit, watch } = useForm<SetLoginDurationFormValues>({
    defaultValues: {
      tokenDurationMinutes: currentTokenDurationMinutes,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const watchFields = watch();

  const { mutateAsync: setTokenDuration, error: setLoginError } =
    useGroupsSetLoginDuration();

  const [api] = useNotification();

  const onSubmit = useCallback(
    (data: SetLoginDurationFormValues) => {
      if (data.tokenDurationMinutes === currentTokenDurationMinutes) return;

      setTokenDuration({
        group_id,
        token_duration_seconds: minutesToSeconds(data.tokenDurationMinutes),
      }).catch((error) => {
        handleBackendErrors({
          api,
          error,
          message4xx: "Не удалось установить длительность действия токена",
        });
      });
    },
    [setTokenDuration, currentTokenDurationMinutes, group_id, api],
  );

  const timerRef = useRef<number>();
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const newTimer = setTimeout(async () => {
      await handleSubmit(onSubmit)();
    }, 2000);

    timerRef.current = newTimer;

    return () => {
      clearTimeout(newTimer);
    };
  }, [watchFields.tokenDurationMinutes, handleSubmit, onSubmit]);

  return (
    <Flex vertical gap={16}>
      <Text variant="subheader-4">Время выхода из-за бездействия</Text>
      <Flex vertical gap={8}>
        <Text variant="body-5" color="darkGray">
          Введите количество минут
        </Text>
        <Controller
          name="tokenDurationMinutes"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              required
              validate={fieldState}
              isError={!!setLoginError}
            />
          )}
        />
      </Flex>
    </Flex>
  );
};
