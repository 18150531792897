import { useCallback, useState, FC, useEffect, useRef, useMemo } from "react";
import { Flex, List } from "antd";
import { Input } from "src/ui/Input/Input";
import { Pagination } from "src/ui/Pagination/Pagination";
import debounce from "lodash/debounce";
import { motion, AnimatePresence } from "framer-motion";
import { Group, User } from "@api/groups";
import { Button } from "src/ui/Button/Button";
import { IoPersonAdd } from "react-icons/io5";
import { AppRoute } from "src/app/routes/constants";
import { generatePath, useNavigate } from "react-router-dom";
import { AccessesListCard } from "./components/AccessesListCard/AccessesListCard";
import s from "./AccessesList.module.scss";

const DURATION_MS = 500;

interface AccessesListViewProps {
  users: User[];
  groupId: number;
}

const AccessesListView: FC<AccessesListViewProps> = ({ users, groupId }) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated(true);

    setTimeout(() => {
      setIsAnimated(false);
    }, DURATION_MS);
  }, []);

  if (!isAnimated) {
    return (
      <>
        {users.map((user) => (
          <List.Item key={user.user_id}>
            <AccessesListCard
              key={user.user_id}
              user={user}
              groupId={groupId}
            />
          </List.Item>
        ))}
      </>
    );
  }

  return (
    <AnimatePresence>
      {users.map((user) => (
        <motion.li
          key={user.user_id}
          layout
          transition={{ ease: "easeOut", duration: DURATION_MS / 1_000 }}
          initial={{ opacity: 0, y: -215 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 215 }}
        >
          <AccessesListCard key={user.user_id} user={user} groupId={groupId} />
        </motion.li>
      ))}
    </AnimatePresence>
  );
};

interface AccessesListProps {
  group?: Group;
  withPagination?: boolean;
  limit?: number;
}

export const AccessesList: FC<AccessesListProps> = ({
  group,
  withPagination,
  limit = 5,
}) => {
  const [filterUi, setFilterUi] = useState("");
  const [, setFilter] = useState("");
  const prevFilter = useRef<string>("");

  const [page, setPage] = useState(1);
  // const offset = (page - 1) * limit;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedFilterUpdate = useCallback(
    debounce((value: string) => {
      setFilter(value);
      if (value !== prevFilter.current) {
        setPage(1);
      }
      prevFilter.current = value;
    }, 1300),
    [],
  );

  const onFilterUpdate = useCallback(
    (value: string) => {
      setFilterUi(value);
      onDebouncedFilterUpdate(value);
    },
    [onDebouncedFilterUpdate],
  );

  const { users = [], group_id = 0 } = group || {};

  const navigate = useNavigate();
  const onCreate = useCallback(() => {
    navigate(
      generatePath(AppRoute.AccessesAdd, { group_id: String(group_id) }),
    );
  }, [navigate, group_id]);

  const pagination = useMemo(() => {
    if (withPagination && users?.length) {
      const pagesCount = Math.ceil(users.length / limit);

      if (pagesCount < 2) {
        return null;
      }

      return (
        <Pagination pagesCount={pagesCount} page={page} onUpdate={setPage} />
      );
    }

    return null;
  }, [limit, page, users?.length, withPagination]);

  if (!group_id) {
    return null;
  }

  return (
    <Flex vertical gap={32}>
      <Input
        placeholder="Поиск по логину"
        value={filterUi}
        onUpdate={onFilterUpdate}
      />
      <Flex gap={8}>
        <Button iconRight={<IoPersonAdd />} onClick={onCreate} size="small">
          Добавить доступ
        </Button>
      </Flex>
      <Flex vertical gap={16} component="ul" className={s.list}>
        {group ? (
          <AccessesListView users={users || []} groupId={group_id} />
        ) : (
          Array.from({ length: 15 }).map((_v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <List.Item key={i}>
              <AccessesListCard groupId={group_id} isLoading />
            </List.Item>
          ))
        )}
      </Flex>
      {pagination}
    </Flex>
  );
};
