import { Text } from "src/ui/Text/Text";
import { PersonInfoKeys } from "@api/report";
import cn from "classnames";
import { Flex } from "antd";
import s from "./Column.module.scss";
import { EMPTY_KEY } from "./constants";

export interface ColumnProps {
  keyProp: string;
  items?: string[] | null;
}

const titles: Record<string, string> = {
  [PersonInfoKeys.persons]: "Лица",
  [PersonInfoKeys.passports]: "Паспорт",
  [PersonInfoKeys.phones]: "Номера телефонов",
  [PersonInfoKeys.autos]: "Автомобили",
  [PersonInfoKeys.snilses]: "СНИЛС",
  [PersonInfoKeys.inns]: "ИНН",
  [PersonInfoKeys.emails]: "E-mail",
  [PersonInfoKeys.citizens]: "Двойное гражданство",
  otherLastNames: "Прошлые фамилии",
};

export const Column = ({ keyProp, items }: ColumnProps) => (
  <div className={cn(keyProp.startsWith(EMPTY_KEY) && s.empty)}>
    <Flex vertical gap={8}>
      <Text variant="label-1" color="darkGray">
        {titles[keyProp]}
      </Text>
      {items &&
        items.length > 0 &&
        items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={`${item}-${index}`} id={item} variant="body-5">
            {item}
          </Text>
        ))}
      {!keyProp.startsWith(EMPTY_KEY) &&
        (!items || (items && items?.length < 1)) && (
          <Text variant="body-5">Не найдено</Text>
        )}
    </Flex>
  </div>
);
