import { FoundStatus, ReportStatus } from "../constants";

export interface IoSphereData {
  source: string;
  found: FoundStatus;
}

export interface ReportsOpenSourcesIoSphere {
  data: IoSphereData[];
  status: ReportStatus;
}

export interface LeakCheck {
  found: FoundStatus;
  source: string[];
  status: ReportStatus;
}

export interface Check {
  status: ReportStatus;
  found: FoundStatus;
  value?: string;
}

export interface OpenSourcesFio {
  inn_check: Check;
  ip_check: Check;
  ul_check: Check;
  ul_restriction_check: Check;
  disqualified_check: Check;
  bank_ruptcy_check: Check;
  enforcement_proceedings_check: Check;
  self_employment_check: Check;
}

export type OpenSourcesModel = OpenSourcesFio | OpenSources | undefined;

export const isReportsOpenSourcesIoSphere = (
  data: OpenSourcesModel,
): data is OpenSources => Boolean(data && Object.hasOwn(data, "iosphere"));

export interface OpenSources {
  iosphere?: ReportsOpenSourcesIoSphere;
  leak_check?: LeakCheck;
}

export const isOpenSources = (data: object): data is OpenSources =>
  Object.hasOwn(data, "iosphere");
