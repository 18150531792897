import React, { FC } from "react";
import { ModalView } from "src/ui/Modal/ModalView";
import { useWindowSize } from "react-use";
import { Drawer } from "src/ui/Drawer/Drawer";
import s from "./AddTicketWrapper.module.scss";

interface AddTicketWrapperProps {
  isModalOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const AddTicketWrapper: FC<AddTicketWrapperProps> = ({
  isModalOpen,
  onClose,
  children,
}) => {
  const { width } = useWindowSize();
  return width < 744 ? (
    <Drawer
      placement="bottom"
      visible={isModalOpen}
      title="Создание тикета"
      closable
      onClose={onClose}
      height="fit-content"
    >
      <div className={s.drawer}>{children}</div>
    </Drawer>
  ) : (
    <ModalView
      isModalOpen={isModalOpen}
      onClose={onClose}
      closable
      title="Создание тикета"
    >
      <div className={s.body}>{children}</div>
    </ModalView>
  );
};
