import { Candidate } from "@api/report";
import { Text } from "src/ui/Text/Text";

import { Score } from "src/components/Score/Score";
import { Buttons } from "@entities/RetrievePhotoByFace/components/SearchResults/Row/Buttons/Buttons";
import s from "./Row.module.scss";

export interface RowProps {
  candidate: Candidate;
}

export const Row = ({ candidate }: RowProps) => (
  <div className={s.container}>
    <img
      src={candidate?.photos?.[0]}
      alt={candidate?.collection_obj?.description}
      className={s.container__photo}
    />
    <Score score={candidate?.score} />
    {candidate?.collection_obj?.description && (
      <Text>{candidate?.collection_obj?.description}</Text>
    )}
    <Buttons id={candidate?.id} meta={candidate?.meta} />
  </div>
);
