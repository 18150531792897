import React from "react";
import { IoIosWarning } from "react-icons/io";
import { generatePath, Link } from "react-router-dom";
import { SubReportListItem } from "@api/report";
import { FaArrowRight } from "react-icons/fa6";
import { ReportType } from "@api/report/constants";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import { Text } from "../../ui/Text/Text";
import s from "./ReportsFoundPersons.module.scss";
import { AppRoute } from "../../app/routes/constants";

export type ReportsFoundPersonsProps = {
  report_list?: SubReportListItem[];
} & RetrieveCardCommonProps;

export const ReportsFoundPersons = ({
  report_list,
  status,
}: ReportsFoundPersonsProps) => (
  <RetrieveCard title="Отчеты по найденным лицам" status={status}>
    <>
      <div className={s.container__alert}>
        <div className={s.container__alert__inner}>
          <IoIosWarning className={s.container__alert__inner__icon} />
          <Text variant="body-5">Бесплатно получите отчет по ФИО</Text>
        </div>
      </div>

      {report_list && (
        <div className={s.container__content}>
          {report_list.map((report) => (
            <Link
              key={report.report_task_id}
              replace
              to={`${generatePath(AppRoute.Report, {
                reportId: report.report_task_id,
              })}?type=${ReportType.SEARCH_FIO}`}
              state={{
                id: report.report_task_id,
                type: ReportType.SEARCH_FIO,
              }}
            >
              <div className={s.container__content__item}>
                <Text color="green" variant="subheader-2">
                  {report.request}
                </Text>
                <FaArrowRight color="#00CC69" />
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  </RetrieveCard>
);
