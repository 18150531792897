import React from "react";
import {
  ReportsPersonInfoData,
  ReportsPersonInfoDataEnum,
  reportsPersonInfoDataWithTitles,
} from "@api/report";
import cn from "classnames";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import { Text } from "../../ui/Text/Text";
import s from "./Data.module.scss";
import { RetrieveCardPrint } from "../RetrieveCard/types";

interface DataProps {
  reportsPersonInfoData: ReportsPersonInfoData;
}

export const Data = ({
  reportsPersonInfoData,
  status,
  print = RetrieveCardPrint.device,
}: DataProps & RetrieveCardCommonProps) => (
  <RetrieveCard
    title={reportsPersonInfoData?.source || "Данные"}
    status={status}
    print={print}
  >
    <div
      className={cn(
        s.container__content,
        print === RetrieveCardPrint.print && s.container__content_print,
      )}
    >
      {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.full_name] && (
        <div className={s.container__content__item}>
          <Text color="darkGray" variant="subheader-2">
            {
              reportsPersonInfoDataWithTitles[
                ReportsPersonInfoDataEnum.full_name
              ]
            }
          </Text>
          <Text>
            {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.full_name]}
          </Text>
        </div>
      )}

      {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.inn] && (
        <div className={s.container__content__item}>
          <Text color="darkGray" variant="subheader-2">
            {reportsPersonInfoDataWithTitles[ReportsPersonInfoDataEnum.inn]}
          </Text>
          <Text>{reportsPersonInfoData?.[ReportsPersonInfoDataEnum.inn]}</Text>
        </div>
      )}

      {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.passport] && (
        <div className={s.container__content__item}>
          <Text color="darkGray" variant="subheader-2">
            {
              reportsPersonInfoDataWithTitles[
                ReportsPersonInfoDataEnum.passport
              ]
            }
          </Text>
          <Text>
            {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.passport]}
          </Text>
        </div>
      )}

      {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.phone] && (
        <div className={s.container__content__item}>
          <Text color="darkGray" variant="subheader-2">
            {reportsPersonInfoDataWithTitles[ReportsPersonInfoDataEnum.phone]}
          </Text>
          <Text>
            {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.phone]}
          </Text>
        </div>
      )}

      {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.snils] && (
        <div className={s.container__content__item}>
          <Text color="darkGray" variant="subheader-2">
            {reportsPersonInfoDataWithTitles[ReportsPersonInfoDataEnum.snils]}
          </Text>
          <Text>
            {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.snils]}
          </Text>
        </div>
      )}

      {reportsPersonInfoData?.other_data &&
        Object.keys(reportsPersonInfoData?.other_data).map((otherData) =>
          reportsPersonInfoData?.other_data?.[otherData] ? (
            <div className={s.container__content__item} key={otherData}>
              <Text color="darkGray" variant="subheader-2">
                {otherData}
              </Text>
              <Text>{reportsPersonInfoData?.other_data?.[otherData]}</Text>
            </div>
          ) : null,
        )}
    </div>
  </RetrieveCard>
);
