import { Text } from "src/ui/Text/Text";
import { Legend } from "@pages/Analytics/components/TypeCheckPaper/Legend/Legend";
import { StatisticsResponse } from "@api/analytics/types";
import {
  DataItem,
  SearchCriteria,
} from "@pages/Analytics/components/TypeCheckPaper/types";
import {
  searchCriteriaColors,
  searchCriteriaDescriptions,
} from "@pages/Analytics/components/TypeCheckPaper/constants";
import { Interval } from "@pages/Analytics/types";
import { intervals } from "@pages/Analytics/constants";
import { PieChartComponent } from "./PieChartComponent/PieChartComponent";
import s from "./TypeCheckPaper.module.scss";

export interface TypeCheckPaperProps {
  statisticsGroupByType?: StatisticsResponse;
  intervalValue: Interval;
}

export const TypeCheckPaper = ({
  statisticsGroupByType,
  intervalValue,
}: TypeCheckPaperProps) => {
  const dataItems: DataItem[] = Array.from(
    Array.from(
      statisticsGroupByType?.labels.reduce(
        (acc: Map<string, number>, label: string, index: number) => {
          const value = statisticsGroupByType.datasets[0]?.data[index];

          const mapValue = acc.get(label);

          if (mapValue) {
            acc.set(label, mapValue + value);
          } else {
            acc.set(label, value);
          }
          return acc;
        },
        new Map<string, number>(),
      ) || [],
    )
      .map((item: [string, number]) => ({ name: item[0], value: item[1] }))
      .reduce((acc: Map<string, number>, dataItem: DataItem) => {
        if (dataItem.name === SearchCriteria.SEARCH_PASSPORT) {
          const mapValue = acc.get(SearchCriteria.SEARCH_CREDIT_REPORT);

          if (mapValue) {
            acc.set(
              SearchCriteria.SEARCH_CREDIT_REPORT,
              mapValue + dataItem.value,
            );
          } else {
            acc.set(SearchCriteria.SEARCH_CREDIT_REPORT, dataItem.value);
          }

          return acc;
        }

        if (dataItem.name === SearchCriteria.SEARCH_OGRN) {
          const mapValue = acc.get(SearchCriteria.SEARCH_INN_UL);

          if (mapValue) {
            acc.set(SearchCriteria.SEARCH_INN_UL, mapValue + dataItem.value);
          } else {
            acc.set(SearchCriteria.SEARCH_INN_UL, dataItem.value);
          }

          return acc;
        }

        if (dataItem.name === SearchCriteria.SEARCH_SNILS) {
          const mapValue = acc.get(SearchCriteria.SEARCH_INN_FL);

          if (mapValue) {
            acc.set(SearchCriteria.SEARCH_INN_FL, mapValue + dataItem.value);
          } else {
            acc.set(SearchCriteria.SEARCH_INN_FL, dataItem.value);
          }

          return acc;
        }

        if (dataItem.name === SearchCriteria.SEARCH_GRN) {
          const mapValue = acc.get(SearchCriteria.SEARCH_VIN);

          if (mapValue) {
            acc.set(SearchCriteria.SEARCH_VIN, mapValue + dataItem.value);
          } else {
            acc.set(SearchCriteria.SEARCH_VIN, dataItem.value);
          }

          return acc;
        }

        const mapValue = acc.get(dataItem.name);

        if (mapValue) {
          acc.set(dataItem.name, mapValue + dataItem.value);
        } else {
          acc.set(dataItem.name, dataItem.value);
        }

        return acc;
      }, new Map<string, number>()) || [],
  ).map((dataItem: [string, number]) => ({
    name: dataItem[0],
    value: dataItem[1],
  }));

  const data = dataItems.map((dataItem: DataItem) => ({
    name: searchCriteriaDescriptions[dataItem.name],
    value: dataItem.value,
  }));

  const colors: string[] = dataItems.map(
    (dataItem: DataItem): string =>
      searchCriteriaColors[dataItem.name as SearchCriteria] ?? "",
  );

  return (
    <div className={s.container}>
      <Text variant="header-6" className={s.container__title}>
        Типы проверок
      </Text>
      <div className={s.container__total}>
        <div className={s.container__total__item}>
          <Text variant="subheader-1">По всем типам проверок</Text>
          <Text variant="header-7">
            {dataItems.reduce(
              (acc: number, dataItem: DataItem) => acc + dataItem.value,
              0,
            )}
          </Text>
          <Text variant="body-1" color="darkGray">
            {intervals[intervalValue]}
          </Text>
        </div>
      </div>

      <div className={s.container__chart}>
        <PieChartComponent
          width={300}
          height={300}
          data={data}
          colors={colors}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={150}
          innerRadius={70}
          fill="#8884d8"
          dataKey="value"
        />
        <Legend dataItems={dataItems} />
        <div className={s.container__chart__check}>
          <Text
            variant="subheader-1"
            className={s.container__chart__check__title}
          >
            Популярные типы проверок
          </Text>
          <div className={s.container__chart__check__content}>
            {dataItems.map((dataItem: DataItem) => (
              <>
                <Text variant="subheader-2">{dataItem.value}</Text>
                <Text variant="body-2">
                  {searchCriteriaDescriptions[dataItem.name]}
                </Text>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
