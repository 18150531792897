import React, {
  useCallback,
  useState,
  ChangeEvent,
  useMemo,
  Fragment,
} from "react";
import { PossibleNetworkResults, ReportStatus } from "@api/report";
import { RetrieveCard } from "src/components/RetrieveCard/RetrieveCard";
import { Input } from "src/ui/Input/Input";
import debounce from "lodash/debounce";
import { Text } from "../../ui/Text/Text";
import { Button } from "../../ui/Button/Button";
import { pluralize } from "../../utils/pluralize";
import { useModalMessage } from "../../ui/Modal/useModalMessage";
import { RelatedPersonsModal } from "./RelatedPersonsModal/RelatedPersonsModal";
import s from "./RelatedPersons.module.scss";

interface RelatedPersonsProps {
  possibleNetworks?: PossibleNetworkResults[] | null;
  status?: ReportStatus;
}

export const RelatedPersons = ({
  possibleNetworks,
  status,
}: RelatedPersonsProps) => {
  const { openModal, closeModal } = useModalMessage();

  const groups: Map<string, PossibleNetworkResults[]> | undefined =
    possibleNetworks?.reduce((acc, item) => {
      const current = acc?.get(item.source);

      if (current) {
        acc.set(item.source, [...current, item]);
      } else {
        acc.set(item.source, [item]);
      }

      return acc;
    }, new Map<string, PossibleNetworkResults[]>());

  const show = useCallback(
    (selectedPossibleNetworks?: PossibleNetworkResults[]) =>
      openModal({
        children: (
          <RelatedPersonsModal
            possibleNetworks={selectedPossibleNetworks}
            onClose={closeModal}
          />
        ),
        maskClosable: true,
      }),
    [openModal, closeModal],
  );

  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 500),
    [],
  );

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
      onDebouncedSearch(e.currentTarget.value);
    },
    [onDebouncedSearch],
  );

  const groupsArrayFiltered: [string, PossibleNetworkResults[]][] =
    useMemo(() => {
      const searchTermLc = searchTerm?.toLowerCase();

      return Array.from(groups?.entries() || [])
        .map((group: [string, PossibleNetworkResults[]]) => {
          // eslint-disable-next-line no-param-reassign
          group[1] = group[1]?.filter((name: PossibleNetworkResults) =>
            `${name?.last_name} ${name?.first_name} ${name?.middle_name}`
              .toLowerCase()
              ?.includes(searchTermLc),
          );

          return group;
        })
        .filter((group) => group[1]?.length > 0);
    }, [searchTerm, groups]);

  if (
    status === ReportStatus.Finished &&
    (!possibleNetworks || (possibleNetworks && possibleNetworks?.length < 1))
  )
    return null;

  return (
    <RetrieveCard
      title="Связанные лица"
      status={status}
      subtitle={
        <Input value={search} placeholder="Поиск" onChange={onSearch} />
      }
    >
      <div className={s.container}>
        <div className={s.container__table}>
          {groupsArrayFiltered?.length > 0 && (
            <>
              <Text color="darkGray" variant="body-1">
                Источник связи
              </Text>
              <Text color="darkGray" variant="body-1">
                Количество связей
              </Text>
            </>
          )}

          {groupsArrayFiltered?.map((group, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <Text variant="body-5" key={group[0]}>
                {group[0]}
              </Text>
              <div>
                <Text variant="body-5" key={`${group[0]}-length`}>
                  {group[1]?.length}{" "}
                  {pluralize({
                    count: group[1]?.length,
                    oneLabel: "связанное лицо",
                    fromTwoTillFourLabel: "связанных лица",
                    label: "связанных лиц",
                  })}
                </Text>
                <Button type="text_secondary" onClick={() => show(group[1])}>
                  Показать
                </Button>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </RetrieveCard>
  );
};
