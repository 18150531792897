import { Flex, Image } from "antd";
import { FC, useMemo } from "react";
import { Block } from "src/ui/Block/Block";
import { Text } from "src/ui/Text/Text";
import { AppRoute } from "src/app/routes/constants";
import { Button } from "src/ui/Button/Button";
import { useTelegramUserBotRetrieveAll } from "@api/telegram/queries/useTelegramUserBotRetrieveAll";
import { TelegramUserBot, TelegramUserBotStatus } from "@api/telegram/types";
import { PageHeader } from "src/app/layouts/PageHeader/PageHeader";
import s from "./Telegram.module.scss";
import { TelegramList } from "./components/TelegramList/TelegramList";
import qrBotImage from "../../assets/images/telegram_qr_code.png";

const QR_BOT_LINK = "https://t.me/odyssey_search_v2_bot";

const filterAwaitingApprovalBots = (bot: TelegramUserBot) =>
  bot.status === TelegramUserBotStatus.AwaitingApproval;
const filterNotAwaitingApprovalBots = (bot: TelegramUserBot) =>
  bot.status !== TelegramUserBotStatus.AwaitingApproval;

export const Telegram: FC = () => {
  const { data: botsResponse, isSuccess: isBotsSuccess } =
    useTelegramUserBotRetrieveAll({ limit: 100 });
  /**
   * TODO move filter to backend
   */
  const bots = useMemo(
    () => botsResponse?.filter(filterAwaitingApprovalBots),
    [botsResponse],
  );

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <PageHeader
        title="Телеграм бот"
        mapPathToTitle={{ [AppRoute.Telegram]: "Телеграм бот" }}
        paths={[AppRoute.Telegram]}
      />
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">Вход по QR-коду</Text>
          <Flex vertical align="center" gap={10}>
            <Image
              src={qrBotImage}
              preview={false}
              alt={QR_BOT_LINK}
              height={212}
              width={212}
            />
            <Text variant="subheader-4">
              Отсканируйте для входа с мобильного устройства
            </Text>
            <Button
              width="full"
              size="small"
              type="flat_secondary"
              link={QR_BOT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text color="darkGray" variant="subheader-3">
                или перейдите по ссылке
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Block>
      {isBotsSuccess && bots?.length ? (
        <Block>
          <Flex vertical gap={32}>
            <Text variant="header-6">Ожидают подтверждения</Text>
            <TelegramList
              withPagination
              filterBots={filterAwaitingApprovalBots}
            />
          </Flex>
        </Block>
      ) : null}
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">Аккаунты</Text>
          <TelegramList
            withPagination
            filterBots={filterNotAwaitingApprovalBots}
          />
        </Flex>
      </Block>
    </Flex>
  );
};
