import { Flex } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { Text } from "src/ui/Text/Text";
import { generatePath } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { useGroups, User } from "@api/groups";
import { MdAnalytics } from "react-icons/md";
import { Pagination } from "src/ui/Pagination/Pagination";
import s from "./SearchAnalytics.module.scss";
import { SearchItem } from "../SearchItem/SearchItem";

const filterUsersByQuery = (query: string) => {
  const queryLc = query.toLowerCase();

  return ({ user_id, name }: User) =>
    String(user_id).toLowerCase().includes(queryLc) ||
    name.toLowerCase().includes(queryLc);
};

const LIMIT = 10;

interface SearchAnalyticsProps {
  query: string;
  prevQuery: string;
}

export const SearchAnalytics: FC<SearchAnalyticsProps> = ({
  query,
  prevQuery,
}) => {
  const [page, setPage] = useState(1);

  const offset = useMemo(() => (page - 1) * LIMIT, [page]);

  useEffect(() => {
    if (query !== prevQuery) {
      setPage(1);
    }
  }, [query, prevQuery]);

  const { data: groups } = useGroups();
  const users = useMemo(() => {
    const mappedUsers = groups
      ?.map((g) => g.users.map((u) => ({ ...u, group_id: g.group_id })))
      .flat();

    if (!query) {
      return mappedUsers;
    }

    const filteredUsers = mappedUsers?.filter(filterUsersByQuery(query));

    return filteredUsers?.sort((a, b) => a.user_id - b.user_id);
  }, [query, groups]);

  const totalCount = users?.length;

  const pagination = useMemo(() => {
    if (totalCount) {
      const pagesCount = Math.ceil(totalCount / LIMIT);

      if (pagesCount < 2) {
        return null;
      }

      return (
        <Pagination pagesCount={pagesCount} page={page} onUpdate={setPage} />
      );
    }

    return null;
  }, [totalCount, page]);

  return (
    <Flex vertical gap={8}>
      <Flex gap={8} align="center" className={s.item__header}>
        <MdAnalytics size={20} />
        <Text variant="subheader-5">Аналитика</Text>
      </Flex>
      <Flex vertical>
        {users?.length ? (
          users
            .slice(offset, offset + LIMIT)
            .map(({ user_id, name, group_id, login }) => {
              const href = generatePath(AppRoute.Analytics, {
                group_id: String(group_id),
                user_id: String(user_id),
              });

              return (
                <SearchItem
                  key={href}
                  title={name || login}
                  description={href}
                  href={href}
                />
              );
            })
        ) : (
          <Text variant="body-5" color="darkGray">
            Ничего не найдено
          </Text>
        )}
      </Flex>
      {pagination}
    </Flex>
  );
};
