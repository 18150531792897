import { client } from "@api/client";

import { endpoints } from "./endpoints";
import { ChatRequest, Chat, Message, ChatCreate, MessageCreate } from "./types";

export const getChats = (data: ChatRequest) =>
  client.post<undefined, Chat[], ChatRequest>(endpoints.GET_CHATS, data);

export const getMessages = (data: ChatRequest) =>
  client.post<undefined, Message[], ChatRequest>(endpoints.GET_MESSAGES, data);

export const createChat = (data: ChatCreate) =>
  client.post<undefined, Chat, ChatCreate>(endpoints.CREATE_CHAT, data);

export const createMessage = (data: MessageCreate) =>
  client.post<undefined, Message, MessageCreate>(
    endpoints.CREATE_MESSAGE,
    data,
  );
