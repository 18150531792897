import { FullDossier } from "src/components/FullDossier/FullDossier";
import { ReportsFoundPersons } from "src/components/ReportsFoundPersons/ReportsFoundPersons";
import { RelatedPersons } from "src/components/RelatedPersons/RelatedPersons";
import { Data } from "src/components/Data/Data";
import { ReportCommonProps } from "@entities/types";
import { ReportsFullRetrieve } from "@api/report/retrieve/retrieveFull";
import { Scoring } from "src/components/Scoring/Scoring";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import {
  FoundStatus,
  isReportsOpenSourcesIoSphere,
  ReportStatus,
} from "@api/report";
import { calculateReportStatus } from "src/utils/getReportStatus";
import { useCallback, useState } from "react";
import { generatePdf } from "@entities/SearchFull/generatePdf";
import { OpenedData } from "./components/OpenedData/OpenedData";
import { Addresses } from "./components/Addresses/Addresses";
import { CrossingBordersSection } from "./components/CrossingBorders/CrossingBordersSection";
import { Incomes } from "./components/Incomes/Incomes";
import s from "./SearchFull.module.scss";

export type SearchFullProps = {
  report: ReportsFullRetrieve;
} & ReportCommonProps;

export const SearchFull = ({ report, type, reportId }: SearchFullProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [
            report?.person?.status,
            report?.sub_reports?.status,
            report?.crossing_borders?.status,
            report?.other_last_names?.status,
            report?.possible_networks?.status,
            report?.photo?.status,
          ],
          person: report?.person?.status,
          found: report?.person?.found === FoundStatus.found,
        }) || report?.status
      }
    >
      <div className={s.container}>
        {report?.person?.status && (
          <FullDossier
            phones={report?.person?.person_info?.phones}
            snilses={report?.person?.person_info?.snilses}
            inns={report?.person?.person_info?.inns}
            passports={report?.person?.person_info?.passports}
            citizens={report?.person?.person_info?.citizens}
            otherLastNames={report?.other_last_names}
            emails={report?.person?.person_info?.emails}
            requestDescribe={report?.request_describe}
            persons={report?.person?.person_info?.persons}
            autos={report?.person?.person_info?.autos}
            photo={report?.photo}
            type={type}
            status={report?.person?.status}
            handleGeneratePdf={handleGeneratePdf}
            isLoadingGeneratePdf={isLoadingGeneratePdf}
          />
        )}
        {report?.sub_reports?.status && report?.sub_reports?.report_list && (
          <ReportsFoundPersons
            report_list={report?.sub_reports?.report_list}
            status={report?.sub_reports?.status}
          />
        )}
        {report?.open_sources && (
          <OpenedData
            openSources={report?.open_sources}
            status={
              (report?.open_sources &&
                isReportsOpenSourcesIoSphere(report?.open_sources) &&
                report?.open_sources?.iosphere?.status) ||
              ReportStatus.Finished
            }
          />
        )}
        {report?.crossing_borders?.status && (
          <CrossingBordersSection
            crossingBorders={report?.crossing_borders}
            status={report?.crossing_borders?.status}
          />
        )}

        {report?.possible_networks?.status && (
          <RelatedPersons
            possibleNetworks={report?.possible_networks?.result}
            status={report?.possible_networks?.status}
          />
        )}

        {report?.person?.status && report?.person?.person_info?.addresses && (
          <Addresses
            addresses={report?.person?.person_info?.addresses}
            status={report?.person?.status}
          />
        )}
        {report?.person?.status && report?.person?.person_info?.incomes && (
          <Incomes
            incomes={report?.person?.person_info?.incomes}
            status={report?.person?.status}
          />
        )}
        {report?.person?.status &&
          report?.person?.person_info?.scoring_result && (
            <Scoring
              scoringResult={report?.person?.person_info?.scoring_result}
              status={report?.person?.status}
            />
          )}
        {report?.person?.person_info?.data?.map(
          (reportsPersonInfoData, index) => (
            <Data
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              reportsPersonInfoData={reportsPersonInfoData}
              status={report?.person?.status}
            />
          ),
        )}
      </div>
    </RetrieveStatus>
  );
};
