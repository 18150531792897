import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import { ReportStatus, useReportsPhoneRetrieve } from "@api/report";
import { SearchPhone } from "@entities/SearchPhone/SearchPhone";
import { ReportCommonProps } from "@entities/types";
import { useEffect, useState } from "react";

export const SearchPhoneWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: phoneReport, isLoading } = useReportsPhoneRetrieve({
    id: reportId,
    status,
  });

  useEffect(() => setStatus(phoneReport?.status), [phoneReport?.status]);

  return (
    <Skeleton loading={isLoading}>
      {phoneReport && (
        <SearchPhone report={phoneReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
