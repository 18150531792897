import { useParams } from "react-router-dom";
import { useReportsVinRetrieve } from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { useEffect, useState } from "react";
import { ReportStatus } from "@api/report/constants";

export const SearchVinWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: vinReport, isLoading } = useReportsVinRetrieve({
    id: reportId,
    status,
  });

  useEffect(() => setStatus(vinReport?.status), [vinReport?.status]);

  return (
    <Skeleton loading={isLoading}>
      {vinReport && (
        <SearchFull report={vinReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
