import { useCallback } from "react";
import { FullInfoItem, ScoringResult } from "@api/report";
import cn from "classnames";
import { IoIosWarning } from "react-icons/io";
import { Text } from "src/ui/Text/Text";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import { useBooleanState } from "../../hooks/useBooleanState";
import { Expand } from "../../ui/Expand/Expand";
import { IndicatorIconEnum, IndicatorModel } from "../Indicator/Indicator";
import s from "./Scoring.module.scss";
import { Common } from "../Common/Common";
import { RetrieveCardPrint } from "../RetrieveCard/types";

type ScoringProps = {
  scoringResult?: ScoringResult | null;
} & RetrieveCardCommonProps;

export const Scoring = ({
  scoringResult,
  status,
  print = RetrieveCardPrint.device,
}: ScoringProps) => {
  const { state: stateInfo, toggle: toggleStateInfo } = useBooleanState(true);

  const scoringkeys = scoringResult?.scoring
    ? Object.keys(scoringResult?.scoring)
    : [];

  const getIndicatorModel = useCallback((marker?: number): IndicatorModel => {
    if (!marker)
      return {
        text: "плохо",
        indicatorIcon: IndicatorIconEnum.cancel,
        color: "red",
      };

    if (marker === 1)
      return {
        text: "отлично",
        indicatorIcon: IndicatorIconEnum.check,
        color: "green",
      };

    if (marker < 1 && marker > 0.5)
      return {
        text: "хорошо",
        indicatorIcon: IndicatorIconEnum.check,
        color: "green",
      };

    if (marker === 0.5)
      return {
        text: "нормально",
        indicatorIcon: IndicatorIconEnum.normal,
        color: "yellow",
      };

    return {
      text: "плохо",
      indicatorIcon: IndicatorIconEnum.cancel,
      color: "red",
    };
  }, []);

  return (
    <RetrieveCard title="Результаты скоринга" status={status} print={print}>
      <div
        className={cn(
          s.container__content,
          print === RetrieveCardPrint.print && s.container__content_print,
        )}
      >
        <div className={s.container__content__common}>
          <div className={s.container__content__common__header}>
            <Common
              marker={scoringResult?.over_all}
              getIndicatorModel={getIndicatorModel}
            />
            {print === RetrieveCardPrint.device && (
              <div className={s.container__content__common__header__label}>
                <IoIosWarning
                  className={s.container__content__common__header__label__icon}
                />
                <Text variant="body-5">
                  Показатель оценивается от 0,00 до 1,00. Показатель ниже 1,00
                  указывает на присутствие негатива.
                </Text>
              </div>
            )}
          </div>

          {Boolean(scoringkeys?.length > 0) && (
            <div className={s.container__content__common__categories}>
              <Text variant="label-3">Категории</Text>
              <div className={s.container__content__common__categories__table}>
                {scoringkeys.map((scoring: string, index) => (
                  <div
                    key={scoring}
                    className={cn(
                      s.container__content__common__categories__table__item,
                      index !== scoringkeys.length - 1 &&
                        s.container__content__common__categories__table__item_border,
                    )}
                  >
                    <Text
                      className={
                        s.container__content__common__categories__table__item__text
                      }
                    >
                      {scoring}
                    </Text>
                    <Text
                      className={cn(
                        s.container__content__common__categories__table__item__text,
                        s.container__content__common__categories__table__item__text__value,
                      )}
                    >
                      {scoringResult?.scoring[scoring]}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {scoringResult?.full_info && scoringResult?.full_info?.length > 0 && (
          <div className={s.container__content__info}>
            <Expand
              state={stateInfo}
              toggle={toggleStateInfo}
              className={s.container__content__info__header}
            >
              <Text variant="label-3">Информация</Text>
            </Expand>
            {stateInfo && (
              <div className={s.container__content__info__table}>
                {scoringResult?.full_info?.map(
                  (mockFullInfoItem: FullInfoItem, index: number) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={s.container__content__info__table__row}
                    >
                      <div
                        className={s.container__content__info__table__row__item}
                      >
                        {index === 0 && (
                          <Text variant="label-1" color="darkGray">
                            Номер статьи
                          </Text>
                        )}
                        <Text variant="body-5">{mockFullInfoItem.article}</Text>
                      </div>
                      <div
                        className={s.container__content__info__table__row__item}
                      >
                        {index === 0 && (
                          <Text variant="label-1" color="darkGray">
                            Категория
                          </Text>
                        )}
                        <Text variant="body-5">
                          {mockFullInfoItem.category}
                        </Text>
                      </div>
                      <div
                        className={s.container__content__info__table__row__item}
                      >
                        {index === 0 && (
                          <Text variant="label-1" color="darkGray">
                            Информация
                          </Text>
                        )}
                        <Text variant="body-5">
                          {mockFullInfoItem.information}
                        </Text>
                      </div>
                    </div>
                  ),
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </RetrieveCard>
  );
};
