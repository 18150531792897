import { FC } from "react";
import { Text } from "src/ui/Text/Text";
import cn from "classnames";
import s from "./CardMessage.module.scss";

interface CardMessageProps {
  date: string;
  message: string;
  isSupport?: boolean;
}

export const CardMessage: FC<CardMessageProps> = ({
  date,
  message,
  isSupport,
}) => (
  <div className={cn({ [s.user]: !isSupport }, s.wrap)}>
    <div className={s.card}>
      <Text variant="header-1" color="primary">
        {message}
      </Text>
      <div className={cn({ [s.user]: !isSupport }, s.wrap)}>
        <Text variant="body-1" color="darkGray">
          {date}
        </Text>
      </div>
    </div>
  </div>
);
