import React, { useCallback, useMemo, useState } from "react";
import { Flex, Radio, Skeleton, Table } from "antd";
import {
  DeleteEmployeeModal,
  EmployeeDataModal,
  EmployeeSummaryModal,
  TableSettingsModal,
} from "@pages/Employees/components";
import { IoPersonAdd } from "react-icons/io5";
import { useEmployees } from "@api/employees/queries/useEmployees";
import { IEmployeeDto, IEmployeeRequest } from "@api/employees";
import { ColumnsType } from "antd/es/table";
import { useEmployeeCreate } from "@api/employees/mutations/useEmployeeCreate";
import { useEmployeeUpdate } from "@api/employees/mutations/useEmployeeUpdate";
import { useEmployeeDelete } from "@api/employees/mutations/useEmployeeDelete";
import { MenuProps } from "antd/lib";
import { IoMdMore } from "react-icons/io";
import { Pagination } from "src/ui/Pagination/Pagination";
import { renderIcon } from "@pages/Employees/utils";
import { sortingRules, sortingRulesOrder } from "@pages/Employees/constants";
import { ESortType } from "@pages/Employees/types";
import s from "./Employees.module.scss";
import { PageHeader } from "../../app/layouts/PageHeader/PageHeader";
import { AppRoute } from "../../app/routes/constants";
import { Block } from "../../ui/Block/Block";
import { Text } from "../../ui/Text/Text";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { Input } from "../../ui/Input/Input";

const limit = 10;

const Employees = () => {
  const [page, setPage] = useState(1);
  const offset = useMemo(() => (page - 1) * limit, [page]);

  const {
    data: employees,
    isPending: waitEmployees,
    refetch: refetchEmployees,
  } = useEmployees({
    limit,
    offset: Number.isInteger(offset) ? offset : undefined,
  });

  const { mutateAsync: createEmployee, isPending: isEmployeeCreating } =
    useEmployeeCreate();
  const { mutateAsync: updateEmployee, isPending: isEmployeeUpdating } =
    useEmployeeUpdate();
  const { mutateAsync: deleteEmployee, isPending: isEmployeeDeleting } =
    useEmployeeDelete();

  const [searchText, setSearchText] = useState("");
  const [selectedSortingRule, setSelectedSortingRule] = useState<ESortType>(
    ESortType.ascend,
  );
  const [activeSortingRule, setActiveSortingRule] = useState<ESortType>(
    ESortType.ascend,
  );

  const sortedData = useMemo(() => {
    if (!employees) return [];

    const sortFn = {
      [ESortType.ascend]: (a: IEmployeeDto, b: IEmployeeDto) =>
        a.last_name.localeCompare(b.last_name),
      [ESortType.descend]: (a: IEmployeeDto, b: IEmployeeDto) =>
        b.last_name.localeCompare(a.last_name),
    }[activeSortingRule];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return [...employees].sort(sortFn);
  }, [activeSortingRule, employees]);

  const filteredData = useMemo(() => {
    if (!searchText) return sortedData;
    return sortedData.filter((employee) =>
      `${employee.last_name} ${employee.first_name} ${employee.middle_name}`
        .toLowerCase()
        .includes(searchText.toLowerCase()),
    );
  }, [sortedData, searchText]);

  const onCreate = useCallback(
    (data: IEmployeeRequest) => {
      createEmployee(data).then(() => refetchEmployees());
    },
    [createEmployee, refetchEmployees],
  );
  const onUpdate = useCallback(
    (data: IEmployeeRequest) => {
      updateEmployee(data).then(() => refetchEmployees());
    },
    [refetchEmployees, updateEmployee],
  );
  const onDelete = useCallback(
    (data: IEmployeeDto["id"]) => {
      deleteEmployee(data).then(() => refetchEmployees());
    },
    [refetchEmployees, deleteEmployee],
  );

  const columns: ColumnsType<IEmployeeDto> = [
    {
      title: (
        <Text variant="body-1" color="aqua">
          Сотрудник
        </Text>
      ),
      className: s.fioColumn,
      dataIndex: "last_name",
      key: "name",
      fixed: "left",
      render: (_, record) => (
        <Text variant="label-3">
          {record.last_name} {record.first_name} {record.middle_name}
        </Text>
      ),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Паспорт
        </Text>
      ),
      dataIndex: "passport_status",
      key: "passport",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ИНН
        </Text>
      ),
      dataIndex: "inn_status",
      key: "inn",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ФССП
        </Text>
      ),
      dataIndex: "fssp_check_status",
      key: "fssp",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ВУ
        </Text>
      ),
      dataIndex: "driver_license_status",
      key: "driver_license",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Залоги
        </Text>
      ),
      key: "pledge",
      render: () => (
        <Text variant="body-5" color="darkGray">
          —
        </Text>
      ),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          ЧС
        </Text>
      ),
      dataIndex: "black_list_check_status",
      key: "black_list",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Розыск
        </Text>
      ),
      dataIndex: "wanted_check_status",
      key: "wanted",
      render: (status: string) => renderIcon(status),
    },
    {
      title: (
        <Text variant="body-1" color="aqua">
          Скоринг
        </Text>
      ),
      dataIndex: "scoring_status",
      key: "scoring",
      render: (status: string) => renderIcon(status),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        const dropdownItems: MenuProps["items"] = [
          {
            key: "summary",
            label: <EmployeeSummaryModal employee={record} />,
          },
          {
            key: "update",
            label: (
              <EmployeeDataModal
                modalTitle="Изменить данные сотрудника"
                buttonTitle={<Text variant="body-2">Редактировать</Text>}
                employee={record}
                onSuccess={onUpdate}
                submitButtonText="Сохранить"
                isRequestLoading={isEmployeeUpdating}
              />
            ),
          },
          {
            key: "delete",
            label: (
              <DeleteEmployeeModal
                isRequestLoading={isEmployeeDeleting}
                onSuccess={onDelete}
                employeeId={record.id}
              />
            ),
          },
        ];

        return (
          <Dropdown
            items={dropdownItems}
            mouseLeaveDelay={0.6}
            placement="bottomRight"
            // eslint-disable-next-line react/no-unstable-nested-components
            body={() => (
              <Text variant="subheader-5" className={s.dropdownOpenButton}>
                <IoMdMore />
              </Text>
            )}
          />
        );
      },
    },
  ];

  const pagination = useMemo(() => {
    if (!employees || employees.length === 0) return null;

    const totalItems = employees.length;
    const pagesCount = Math.ceil(totalItems / limit);

    if (pagesCount < 2) return null;

    return (
      <Pagination
        pagesCount={pagesCount}
        page={page}
        onUpdate={(newPage) => {
          setPage(newPage);
          refetchEmployees();
        }}
      />
    );
  }, [employees, page]);

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Flex className={s.header} gap={24}>
        <PageHeader
          title="Сотрудники"
          mapPathToTitle={{ [AppRoute.Employees]: "Сотрудники" }}
          paths={[AppRoute.Employees]}
        />
        <EmployeeDataModal
          modalTitle="Добавить сотрудника"
          buttonTitle={
            <Button size="small" width="full">
              Добавить сотрудника <IoPersonAdd />
            </Button>
          }
          onSuccess={onCreate}
          submitButtonText="Добавить"
          isRequestLoading={isEmployeeCreating}
        />
      </Flex>

      <Block>
        <Flex vertical className={s.searchBar} gap={12}>
          <Input
            placeholder="Поиск по сотруднику"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Flex>
            <TableSettingsModal
              modalTitle="Сортировка"
              title="Сортировка"
              onApply={() => setActiveSortingRule(selectedSortingRule)}
            >
              {sortingRulesOrder.map((sortingRule: ESortType) => (
                <Radio
                  key={sortingRule}
                  checked={selectedSortingRule === sortingRule}
                  onChange={(e) => {
                    if (e.target.checked) setSelectedSortingRule(sortingRule);
                  }}
                >
                  {sortingRules[sortingRule]}
                </Radio>
              ))}
            </TableSettingsModal>
          </Flex>
        </Flex>

        <Skeleton loading={waitEmployees}>
          <Table
            rowClassName={s.table}
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            pagination={false}
            scroll={{ x: 1000 }}
          />
        </Skeleton>

        <Flex className={s.pagination}>{pagination}</Flex>
      </Block>
    </Flex>
  );
};

export default Employees;
