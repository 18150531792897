import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import { ReportsPhotoByFaceRetrieveResponse, ReportType } from "@api/report";
import { SearchResults } from "@entities/RetrievePhotoByFace/components/SearchResults/SearchResults";
import { RetrieveCardPrint } from "../../components/RetrieveCard/types";

export interface GeneratePdfParams {
  report: ReportsPhotoByFaceRetrieveResponse;
  type: ReportType;
  reportId?: string;
}

export const generatePdf = ({
  report,
  reportId,
}: GeneratePdfParams): Promise<void> => {
  const outputHtml: HTMLDivElement = document.createElement("div");
  outputHtml.style.width = "800px";

  const searchResults = ReactDOMServer.renderToString(
    <SearchResults
      print={RetrieveCardPrint.print}
      status={report?.photo_response?.status}
      faces={report?.photo_response?.result?.faces}
    />,
  );
  outputHtml.insertAdjacentHTML("beforeend", searchResults);

  const opt = {
    margin: 0.2,
    filename: `${reportId ?? "download"}.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    pagebreak: { mode: ["css", "legacy"] },
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  return html2pdf().set(opt).from(outputHtml).save();
};
