import { Text } from "src/ui/Text/Text";
import { ReportCommonProps } from "@entities/types";
import { ReportStatus, ReportType } from "@api/report/constants";
import cn from "classnames";
import {
  OtherLastNames,
  PersonInfo,
  PersonInfoKeys,
  ReportsPersonInfoPerson,
} from "@api/report";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import { mapReportTypeToLabel } from "src/utils/reports/mapReportTypeToLabel";
import { Flex } from "antd";
import { Photo } from "@api/report/retrieve/photo";
import { Column } from "./Column/Column";
import s from "./FullDossier.module.scss";
import { PhotoGallery } from "../PhotoGallery/PhotoGallery";
import { EMPTY_KEY } from "./Column/constants";
import { DownloadReportPdfButton } from "./components/DownloadReportPdfButton/DownloadReportPdfButton";
import { RetrieveCardPrint } from "../RetrieveCard/types";

const PHOTO = "photo";
const OTHER_LAST_NAMES = "otherLastNames";

export type FullDossierProps = PersonInfo &
  ReportCommonProps & {
    requestDescribe?: string;
    photo?: Photo | null;
    [OTHER_LAST_NAMES]?: OtherLastNames;
    handleGeneratePdf?: () => void;
    isLoadingGeneratePdf?: boolean;
    title?: string;
  } & RetrieveCardCommonProps;

const COLUMN_IN_ROW = 4;

const ORDER = [
  PHOTO,
  PersonInfoKeys.persons,
  PersonInfoKeys.phones,
  PersonInfoKeys.emails,
  PersonInfoKeys.passports,
  PersonInfoKeys.snilses,
  PersonInfoKeys.inns,
  PersonInfoKeys.citizens,
  PersonInfoKeys.autos,
  OTHER_LAST_NAMES,
];

export const FullDossier = ({
  requestDescribe,
  type,
  status,
  handleGeneratePdf,
  isLoadingGeneratePdf,
  print = RetrieveCardPrint.device,
  title = "Краткая сводка",
  ...props
}: FullDossierProps) => {
  const label = mapReportTypeToLabel[type];
  const isRequestDescribeHasDob = !!requestDescribe?.match(
    /\b\d{2}\.\d{2}\.\d{4}\b/,
  );

  const propKeys = ORDER?.filter((propKey) =>
    Object.hasOwn(props, propKey),
  ).filter((propKey) => {
    if (type === ReportType.SEARCH_PHONE && propKey === PersonInfoKeys.phones)
      return false;

    if (type === ReportType.SEARCH_SNILS && propKey === PersonInfoKeys.snilses)
      return false;

    if (
      (type === ReportType.SEARCH_INN_FL ||
        type === ReportType.SEARCH_INN_UL) &&
      propKey === PersonInfoKeys.inns
    )
      return false;

    if (
      type === ReportType.SEARCH_PASSPORT &&
      propKey === PersonInfoKeys.passports
    )
      return false;

    if (type === ReportType.SEARCH_EMAIL && propKey === PersonInfoKeys.emails)
      return false;

    if (
      (type === ReportType.SEARCH_GRN || type === ReportType.SEARCH_VIN) &&
      propKey === PersonInfoKeys.autos
    )
      return false;

    if (
      ((type === ReportType.SEARCH_FIO && isRequestDescribeHasDob) ||
        type === ReportType.SEARCH_PHOTO_BY_IMAGE) &&
      propKey === PersonInfoKeys.persons
    )
      return false;

    if (print === RetrieveCardPrint.print && propKey === PHOTO) return false;

    return true;
  });
  const remainder =
    Number(propKeys?.length) % COLUMN_IN_ROW === 0
      ? 0
      : COLUMN_IN_ROW - (Number(propKeys?.length) % COLUMN_IN_ROW);
  const propKeysWithFake = [...propKeys];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < remainder; i++) {
    propKeysWithFake.push(EMPTY_KEY + i);
  }

  return (
    <RetrieveCard
      print={print}
      title={title}
      status={status}
      header={
        <div className={s.container__header}>
          <Flex vertical gap={16}>
            <Text color="aqua">{label}</Text>
            {requestDescribe && (
              <div>
                <Text
                  className={cn(
                    print === RetrieveCardPrint.device &&
                      s.container__header__name,
                    print === RetrieveCardPrint.print &&
                      s.container__header__name_print,
                  )}
                >
                  Введенные данные запроса
                  <br />
                  {requestDescribe}
                </Text>
              </div>
            )}
          </Flex>
          {print === RetrieveCardPrint.device && (
            <div className={s.container__header__options}>
              <DownloadReportPdfButton
                handleGeneratePdf={handleGeneratePdf}
                isLoadingGeneratePdf={isLoadingGeneratePdf}
              />
            </div>
          )}
        </div>
      }
    >
      <div
        className={cn(
          print === RetrieveCardPrint.device && s.container__row,
          print === RetrieveCardPrint.print && s.container__row_print,
        )}
      >
        {propKeysWithFake.map((propKey: string) => {
          if (propKey === OTHER_LAST_NAMES)
            return (
              <Column
                key={propKey}
                keyProp={propKey}
                items={
                  props[propKey]?.status === ReportStatus.InProgress
                    ? ["В процессе сборки..."]
                    : props[propKey]?.result
                }
              />
            );

          if (propKey === PHOTO) {
            const photo = props?.[propKey];
            return (
              <Flex key={propKey} vertical gap={8}>
                <Text variant="label-1" color="darkGray">
                  Фотографии
                </Text>
                {print === RetrieveCardPrint.device &&
                photo?.photos &&
                photo.photos?.length > 0 ? (
                  <PhotoGallery photos={photo.photos} />
                ) : (
                  "Не найдено"
                )}
              </Flex>
            );
          }

          if (propKey === PersonInfoKeys.persons)
            return (
              <Column
                key={propKey}
                keyProp={propKey}
                items={props[propKey]?.map(
                  (person: ReportsPersonInfoPerson) =>
                    `${person.last_name} ${person.first_name} ${person.middle_name} ${person.birth_date}`,
                )}
              />
            );

          return (
            <Column key={propKey} keyProp={propKey} items={props[propKey]} />
          );
        })}
      </div>
    </RetrieveCard>
  );
};
