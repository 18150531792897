import { ChangeEventHandler, FC, forwardRef, useCallback, useId } from "react";
import { Input as AntdInput } from "antd";
import type { TextAreaRef, TextAreaProps } from "antd/lib/input/TextArea";
import cn from "classnames";
import { ControllerFieldState } from "react-hook-form";
import { parseError } from "src/utils/parseError";
import styles from "./TextArea.module.scss";

const { TextArea: AntdTextArea } = AntdInput;

interface InputProps extends TextAreaProps {
  validate?: Partial<ControllerFieldState>;
  className?: string;
  label?: string;
  supportingText?: string;
  onUpdate?: (value: string) => void;
  isError?: boolean;
  definitions?: Record<string, string>;
}

export const TextArea: FC<InputProps> = forwardRef<TextAreaRef, InputProps>(
  ({ onUpdate, isError, ...props }, ref) => {
    const {
      validate,
      className,
      label,
      supportingText,
      disabled,
      variant = "filled",
      placeholder,
      onChange,
      required,
    } = props;
    const { error } = validate || {};
    const id = useId();

    const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
      (e) => {
        e.target.value = e.target.value.trim();
        if (e.currentTarget !== e.target) {
          e.currentTarget.value = e.currentTarget.value.trim();
        }
        onChange?.(e);
        onUpdate?.(e.target.value);
      },
      [onUpdate, onChange],
    );
    return (
      <div
        className={cn(
          styles.input_wrapper,
          { disabled, [styles.input_wrapper_placeholder]: placeholder },
          className,
        )}
      >
        <AntdTextArea
          {...props}
          ref={ref}
          onChange={handleChange}
          variant={variant}
          className={cn(props.className, styles.input, {
            [styles.input_wrapper_error]: isError || error,
          })}
          id={id}
        />
        {placeholder && (
          <label
            htmlFor={id}
            className={cn({ [styles.input_required]: required })}
          >
            {placeholder}
          </label>
        )}
        {label && <span className={styles.error}>{label}</span>}
        {error && (
          <span className={styles.input_error_text}>{parseError(error)}</span>
        )}
        {supportingText && (
          <span className={styles.supporting_text}>{supportingText}</span>
        )}
      </div>
    );
  },
);

TextArea.displayName = "TextArea";
