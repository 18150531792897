import { ReportsPhotoByFaceRetrieveResponse, ReportStatus } from "@api/report";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { SearchResults } from "@entities/RetrievePhotoByFace/components/SearchResults/SearchResults";
import { calculateReportStatus } from "src/utils/getReportStatus";
import { FC, useCallback, useState } from "react";
import { PhotoGallery } from "src/components/PhotoGallery/PhotoGallery";
import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
import { Text } from "src/ui/Text/Text";
import { DownloadReportPdfButton } from "src/components/FullDossier/components/DownloadReportPdfButton/DownloadReportPdfButton";
import { ReportCommonProps } from "@entities/types";
import s from "./RetrievePhotoByFace.module.scss";
import { generatePdf } from "./generatePdf";

export type RetrievePhotoByFaceProps = {
  report: ReportsPhotoByFaceRetrieveResponse;
} & ReportCommonProps;

export const RetrievePhotoByFace: FC<RetrievePhotoByFaceProps> = ({
  report,
  type,
  reportId,
}) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] =
    useState<boolean>(false);

  const handleGeneratePdf = useCallback(async () => {
    setIsLoadingGeneratePdf(true);
    await generatePdf({ report, type, reportId }).then(() =>
      setIsLoadingGeneratePdf(false),
    );
  }, [report, type, reportId]);

  return (
    <RetrieveStatus
      status={
        calculateReportStatus({
          sourceStatuses: [report?.photo_response?.status],
          found: report?.status === ReportStatus.Finished,
        }) || report?.status
      }
    >
      <Flex className={s.container} vertical gap={32}>
        <Block>
          <Flex vertical gap={16}>
            <Text color="aqua">По фото</Text>
            {(report.photo_response?.result?.faces.length || 0) > 0 ? (
              <Flex justify="space-between">
                <PhotoGallery
                  photos={
                    report?.photo_response?.result?.faces
                      .map((f) =>
                        f.candidates.map((c) => ({
                          faces: c.photos,
                          score: c.score || 0,
                          source: "",
                          description: c.collection_obj.description,
                        })),
                      )
                      .flat() || []
                  }
                />
                <DownloadReportPdfButton
                  handleGeneratePdf={handleGeneratePdf}
                  isLoadingGeneratePdf={isLoadingGeneratePdf}
                />
              </Flex>
            ) : (
              "Не найдено"
            )}
          </Flex>
        </Block>
        <SearchResults
          status={report?.photo_response?.status}
          faces={report?.photo_response?.result?.faces}
        />
      </Flex>
    </RetrieveStatus>
  );
};
