import { EStatus } from "@pages/Employees/types";
import React from "react";
import { ReactComponent as Success } from "src/assets/success_circle.svg";
import { ReactComponent as Cancel } from "src/assets/close_circle.svg";
import { ReactComponent as Alert } from "src/assets/alert.svg";
import { ReactComponent as Loading } from "src/assets/loading.svg";
import { Text } from "../../../ui/Text/Text";

export const renderIcon = (status?: string) => {
  if (status === EStatus.Error || status === EStatus.Invalid) return <Alert />;
  if (status === EStatus.No_data) return <Cancel />;
  if (status === EStatus.Completed) return <Success />;
  if (status === EStatus.In_progress) return <Loading />;
  return <Text color="darkGray">—</Text>;
};
