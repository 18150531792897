import { FC, useLayoutEffect } from "react";
import Sider from "antd/es/layout/Sider";
import s from "./ReportLayoutAside.module.scss";

import { ReportLayoutAsideMenu } from "./components/ReportLayoutAsideMenu/ReportLayoutAsideMenu";

interface ReportLayoutAsideProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export const ReportLayoutAside: FC<ReportLayoutAsideProps> = ({
  collapsed,
  onCollapse,
}) => {
  useLayoutEffect(() => onCollapse(true), [onCollapse]);

  return (
    <Sider
      className={s.sider}
      defaultCollapsed
      width={280}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <ReportLayoutAsideMenu collapsed={collapsed} onCollapse={onCollapse} />
    </Sider>
  );
};
