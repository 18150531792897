import { client } from "@api/client";

import { DocumentsEndpoints } from "./endpoints";
import { Document, DocumentRequest } from "./types";

export const documentDownload = (data: DocumentRequest) =>
  client.post<string, string, DocumentRequest>(
    DocumentsEndpoints.Download,
    data,
    {
      responseType: "blob",
    },
  );

export const documentsRetrieveAll = (data: DocumentRequest) =>
  client.post<Document[], Document[], DocumentRequest>(
    DocumentsEndpoints.RetrieveAll,
    data,
  );
