import { useParams } from "react-router-dom";
import { ReportStatus, useReportsInnFlRetrieve } from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { useEffect, useState } from "react";

export const SearchInnFlWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: innFlReport, isLoading } = useReportsInnFlRetrieve({
    id: reportId,
    status,
  });

  useEffect(() => setStatus(innFlReport?.status), [innFlReport?.status]);

  return (
    <Skeleton loading={isLoading}>
      {innFlReport && (
        <SearchFull report={innFlReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
