import { useParams } from "react-router-dom";
import { ReportStatus, useReportsInnUlRetrieve } from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { useEffect, useState } from "react";

export const SearchInnUlWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: innUlReport, isLoading } = useReportsInnUlRetrieve({
    id: reportId,
    status,
  });

  useEffect(() => setStatus(innUlReport?.status), [innUlReport?.status]);

  return (
    <Skeleton loading={isLoading}>
      {innUlReport && (
        <SearchFull report={innUlReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};
