import { Text } from "src/ui/Text/Text";
import { IoIosWarning } from "react-icons/io";
import {
  isReportsOpenSourcesIoSphere,
  OpenSourcesModel,
  ReportStatus,
} from "@api/report";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import {
  RetrieveCardPrint,
  RetrieveCardVariant,
} from "src/components/RetrieveCard/types";
import { Content } from "@entities/SearchFull/components/OpenedData/Content/Content";
import s from "./OpenedData.module.scss";

export type OpenedDataProps = {
  openSources: OpenSourcesModel;
} & RetrieveCardCommonProps;

export const OpenedData = ({
  openSources,
  status,
  print = RetrieveCardPrint.device,
}: OpenedDataProps) => {
  if (
    openSources &&
    isReportsOpenSourcesIoSphere(openSources) &&
    openSources?.iosphere?.status === ReportStatus.NoData
  )
    return null;

  if (status === ReportStatus.Canceled) return null;

  return (
    <RetrieveCard
      variant={RetrieveCardVariant.noBorder}
      title="Открытые данные"
      status={status}
      print={print}
    >
      <>
        {print === RetrieveCardPrint.device && (
          <div className={s.container__alert}>
            <div className={s.container__alert__inner}>
              <IoIosWarning className={s.container__alert__inner__icon} />
              <Text variant="body-5">
                Сбор открытых данных занимает до 5 минут. Данные подгружаются в
                отчет автоматически.
              </Text>
            </div>
          </div>
        )}
        {openSources && (
          <Content
            print={print}
            openSources={
              isReportsOpenSourcesIoSphere(openSources)
                ? openSources?.iosphere?.data
                : openSources
            }
          />
        )}
      </>
    </RetrieveCard>
  );
};
