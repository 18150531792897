import { Meta } from "@api/report";
import { Text } from "src/ui/Text/Text";
import s from "./PhotoData.module.scss";

export interface PhotoDataProps {
  id?: string;
  meta?: Meta;
}

export const PhotoData = ({ id, meta }: PhotoDataProps) => (
  <div className={s.container}>
    <Text variant="subheader-5">Данные фото</Text>
    <div className={s.container__list}>
      <div className={s.container__list__item}>
        <Text variant="subheader-1" color="darkGray">
          Id
        </Text>
        <Text variant="label-5">{id}</Text>
      </div>
      <div className={s.container__list__item}>
        <Text variant="subheader-1" color="darkGray">
          Имя
        </Text>
        <Text variant="label-5">{meta?.name}</Text>
      </div>
      <div className={s.container__list__item}>
        <Text variant="subheader-1" color="darkGray">
          Дата
        </Text>
        <Text variant="label-5">{meta?.birthday}</Text>
      </div>
    </div>
  </div>
);
