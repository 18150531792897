import React, { useEffect, useState } from "react";
import { ReportCommonProps } from "@entities/types";
import { useParams } from "react-router-dom";
import { ReportStatus, useReportsKadNumberRetrieve } from "@api/report";
import { Skeleton } from "antd";
import { SearchFull } from "@entities/SearchFull/SearchFull";

const SearchKadNumberWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();
  const [status, setStatus] = useState<ReportStatus | undefined>(undefined);

  const { data: kadNumberReport, isLoading } = useReportsKadNumberRetrieve({
    id: reportId,
    status,
  });

  useEffect(
    () => setStatus(kadNumberReport?.status),
    [kadNumberReport?.status],
  );

  return (
    <Skeleton loading={isLoading}>
      {kadNumberReport && (
        <SearchFull report={kadNumberReport} type={type} reportId={reportId} />
      )}
    </Skeleton>
  );
};

export default SearchKadNumberWidget;
