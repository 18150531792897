import { FC, useState } from "react";

import { Button, Input, Select } from "antd";
import cn from "classnames";
import {
  useGroupsCreate,
  useGroupsEdit,
  useGroupsAddMember,
  useGroupsRemoveMember,
  useUsersEdit,
  useGroupsGrantPermissions,
  useGroups,
  Permission,
} from "@api/groups";
import s from "./Groups.module.scss";

export const Groups: FC = () => {
  const [newGroup, setNewGroup] = useState("");
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { data: groups } = useGroups();
  const { mutate: create } = useGroupsCreate();
  const { mutate: edit } = useGroupsEdit();
  const { mutate: editMember } = useUsersEdit();
  const { mutate: addMember } = useGroupsAddMember();
  const { mutate: removeMember } = useGroupsRemoveMember();
  const { mutate: grantPermissions } = useGroupsGrantPermissions();
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPermission, setSelectedPermission] = useState<Permission>(
    Permission.SuperuserPermission,
  );

  const createGroup = () => {
    create({
      name: newGroup,
    });
    setNewGroup("");
  };

  const editGroup = () => {
    edit({
      // @ts-expect-error TODO fix it
      groupId: selectedGroup,
      name: newGroup,
    });
    setNewGroup("");
  };

  const selectGroup = (groupId: number) => {
    setSelectedGroup(groupId);
  };

  const selectPermissions = () => {
    grantPermissions({
      // @ts-expect-error TODO fix it
      groupId: selectedGroup,
      userId: selectedUser,
      permissions: [selectedPermission],
    });
  };

  const createUser = () => {
    addMember({
      // @ts-expect-error TODO fix it
      groupId: selectedGroup,
      name,
      login,
      password,
    });
    setName("");
    setLogin("");
    setPassword("");
  };

  const editUser = () => {
    // @ts-expect-error TODO fix it
    editMember({
      user_id: Number(selectedUser),
      name,
    });
    setName("");
    setPassword("");
  };

  const removeUser = () => {
    removeMember({
      // @ts-expect-error TODO fix it
      groupId: selectedGroup,
      userId: selectedUser,
    });
    setSelectedUser("");
  };

  const selectUser = (userId: string) => {
    setSelectedUser(userId);
  };
  return (
    <div className={s.wrapper}>
      <div className={s.redact_panel}>
        <Input
          required
          value={newGroup}
          placeholder="Название группы"
          onChange={(e) => {
            setNewGroup(e.currentTarget.value);
          }}
        />
        {selectedGroup ? (
          <Button onClick={editGroup}>Изменить группу</Button>
        ) : (
          <Button onClick={createGroup}>Добавить группу</Button>
        )}
        {groups?.map((group) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            key={group.group_id}
            className={cn(s.item, {
              [s.selected_item]: group.group_id === Number(selectedGroup),
            })}
            onClick={() => {
              selectGroup(group.group_id);
            }}
          >
            {group.name}
          </div>
        ))}
      </div>
      {selectedGroup && (
        <div className={s.redact_panel}>
          <Input
            required
            value={name}
            placeholder="Имя пользователя"
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
          />
          <Input
            required
            value={login}
            placeholder="Логин"
            onChange={(e) => {
              setLogin(e.currentTarget.value);
            }}
          />
          <Input
            required
            value={password}
            placeholder="Пароль"
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
          />
          {selectedUser ? (
            <div className={s.buttons}>
              <Button onClick={editUser}>Изменить пользователя</Button>
              <Button onClick={removeUser}>Удалить пользователя</Button>
            </div>
          ) : (
            <Button onClick={createUser}>Добавить пользователя</Button>
          )}
          {selectedUser && (
            <div>
              <Select
                defaultValue={selectedPermission}
                onChange={(value) => {
                  setSelectedPermission(value);
                }}
                options={[
                  { value: "SUPERUSER", label: "SUPERUSER" },
                  { value: "MEMBER", label: "MEMBER" },
                  { value: "ADMIN", label: "ADMIN" },
                ]}
              />
              <Button onClick={selectPermissions}>
                Изменить права доступа
              </Button>
            </div>
          )}
          {groups
            ?.find((group) => group.group_id === selectedGroup)
            ?.users.map((user) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                key={user.user_id}
                className={cn(s.item, {
                  // @ts-expect-error FIXME
                  [s.selected_item]: user.user_id === selectedUser,
                })}
                onClick={() => {
                  // @ts-expect-error FIXME
                  selectUser(user.user_id);
                }}
              >
                {user.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
