import { useRef } from "react";
import { Flex, Tooltip } from "antd";
import { Button } from "src/ui/Button/Button";
import { AiFillQuestionCircle } from "react-icons/ai";
import { DropZoneFile } from "src/ui/DropZone/DropZone";
import { Text } from "src/ui/Text/Text";
import { useBooleanState } from "src/hooks/useBooleanState";
import { SOPDModal } from "../SOPDModal/SOPDModal";
import s from "./SubmitButton.module.scss";

interface SubmitButtonProps {
  disabled?: boolean;
  fileList?: DropZoneFile[];
  onFileListChange?: (fileList: DropZoneFile[]) => void;
}

export const SubmitButton = ({
  disabled,
  fileList,
  onFileListChange,
}: SubmitButtonProps) => {
  const file = fileList?.[0]?.originFileObj;

  const {
    state: isSopdOpen,
    setTrue: openSopd,
    setFalse: closeSopd,
  } = useBooleanState(false);
  const buttonRef = useRef<HTMLElement>(null);

  return (
    <Flex vertical gap={12} ref={buttonRef}>
      <Button className={s.button_submit} disabled={disabled} htmlType="submit">
        Проверить
      </Button>
      {onFileListChange ? (
        <>
          <Button
            className={s.button_sopd}
            type="text_secondary"
            disabled={false}
            htmlType="button"
            onClick={openSopd}
          >
            <Flex gap={6} align="center">
              <Text color={file ? "primary" : "darkGray"}>
                {file ? "Приложено СОПД" : "Загрузить СОПД"}
              </Text>
              <Tooltip
                title="Согласие на обработку персональных данных"
                trigger="hover"
              >
                <AiFillQuestionCircle />
              </Tooltip>
            </Flex>
          </Button>
          <SOPDModal
            fileList={fileList || []}
            onFileListChange={onFileListChange}
            open={isSopdOpen}
            onClose={closeSopd}
          />
        </>
      ) : null}
    </Flex>
  );
};
