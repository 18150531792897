import { ClipboardEventHandler, FC, ReactNode, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import { useReportsCreditRatingSearch } from "@api/report";
import {
  validationBounds,
  validationMessage,
  validationRegex,
} from "src/utils/constants";
import dayjs from "dayjs";
import { correctLettersSpacesHyphenDashRegexCallback } from "src/utils/validation";
import s from "./MainTabCreditRatingNormal.module.scss";
import { MainTabFieldValues, MainTabProps } from "../../../../types";
import { dropZoneFileListSchema } from "../../../../constants";
import { guessWordTypes } from "../../../../utils/guessWordTypes";
import { WordType } from "../../../../utils/types";
import { POSSIBLE_DATE_FORMATS } from "../../../../utils/isValidDate";
import { getOtherWords } from "../../../../utils/getOtherWords";

interface ReportsCreditRatingSearchForm extends MainTabFieldValues {
  last_name: string;
  first_name: string;
  middle_name?: string;
  birth_day: number;
  birth_month: number;
  birth_year: number;
}

const schema = yup
  .object<ReportsCreditRatingSearchForm>()
  .shape({
    last_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongLastName,
        correctLettersSpacesHyphenDashRegexCallback,
      )
      .required(),
    first_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongFirstName,
        correctLettersSpacesHyphenDashRegexCallback,
      )
      .required(),
    middle_name: yup
      .string()
      .test(
        "test-symbols",
        validationMessage.WrongMiddleName,
        correctLettersSpacesHyphenDashRegexCallback,
      ),
    birth_day: yup
      .number()
      .integer(validationMessage.BirthDay)
      .min(validationBounds.Day.Min, validationMessage.BirthDay)
      .max(validationBounds.Day.Max, validationMessage.BirthDay)
      .required(),
    birth_month: yup
      .number()
      .integer(validationMessage.BirthMonth)
      .min(validationBounds.Month.Min, validationMessage.BirthMonth)
      .max(validationBounds.Month.Max, validationMessage.BirthMonth)
      .required(),
    birth_year: yup
      .number()
      .integer(validationMessage.BirthYear)
      .min(validationBounds.Year.Min, validationMessage.BirthYear)
      .max(validationBounds.Year.Max, validationMessage.BirthYear)
      .required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

interface MainTabCreditRatingNormalProps extends MainTabProps {
  viewToggle: ReactNode;
}

export const MainTabCreditRatingNormal: FC<MainTabCreditRatingNormalProps> = ({
  onSubmit,
  viewToggle,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    setValue,
    trigger,
  } = useForm<ReportsCreditRatingSearchForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      middle_name: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsCreditRatingSearch();

  const onSubmitInner = useCallback(
    (data: ReportsCreditRatingSearchForm) => {
      const requestData = {
        ...data,
        /**
         * DD.MM.YYYY
         */
        birth_date: `${String(data.birth_day).padStart(2, "0")}.${String(data.birth_month).padStart(2, "0")}.${data.birth_year}`,
      };

      return onSubmit({ requestData, search, withSopd: true }).then(() =>
        reset(),
      );
    },
    [search, onSubmit, reset],
  );

  const pasteDate = useCallback(
    (dateWord: string): boolean => {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < POSSIBLE_DATE_FORMATS.length; i += 1) {
        const format = POSSIBLE_DATE_FORMATS[i];
        if (dayjs(dateWord, format, true).isValid()) {
          const date = dayjs(dateWord, format);

          const year = date.year();
          setValue("birth_year", year);

          const month = date.month();
          setValue("birth_month", month + 1);

          const day = date.date();
          setValue("birth_day", day);

          return true;
        }
      }

      return false;
    },
    [setValue],
  );

  const onDayPaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedDate = e.clipboardData.getData("text");
      if (pasteDate(pastedDate)) {
        e.preventDefault();
      }

      await trigger().catch(() => {});
    },
    [pasteDate, trigger],
  );

  const onLastNamePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
    async (e) => {
      const pastedText = e.clipboardData.getData("text");
      const pastedWords = pastedText.split(" ");
      if (!pastedWords.length) return;

      e.preventDefault();

      const wordsByType = guessWordTypes({ pastedWords });

      const dateWord = wordsByType[WordType.Date].shift();
      if (dateWord) {
        pasteDate(dateWord);
      }

      const probableOrderOfPastedWords: (keyof ReportsCreditRatingSearchForm)[] =
        [];

      const lastName = wordsByType[WordType.LastName].shift();
      if (lastName) {
        setValue("last_name", lastName);
      } else {
        probableOrderOfPastedWords.push("last_name");
      }
      const firstName = wordsByType[WordType.FirstName].shift();
      if (firstName) {
        setValue("first_name", firstName);
      } else {
        probableOrderOfPastedWords.push("first_name");
      }
      const middleName = wordsByType[WordType.MiddleName].shift();
      if (middleName) {
        setValue("middle_name", middleName);
      } else {
        probableOrderOfPastedWords.push("middle_name");
      }

      const otherWords = getOtherWords(wordsByType);
      const maxLen = probableOrderOfPastedWords.length;
      if (otherWords.length > maxLen) {
        const restPastedWords = otherWords.slice(maxLen - 1);
        otherWords[maxLen - 1] = restPastedWords.join(" ");
        otherWords.length = maxLen;
      }

      otherWords.forEach((pastedWord, index) => {
        setValue(probableOrderOfPastedWords[index], pastedWord);
      });

      await trigger().catch(() => {});
    },
    [pasteDate, setValue, trigger],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} wrap="wrap">
        <Controller
          name="last_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Фамилия"
              onPaste={onLastNamePaste}
              {...field}
              value={field.value.replace(
                validationRegex.lettersSpacesHyphenDashExcept,
                "",
              )}
              onChange={(e) => {
                e.target.value = e.target.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                );
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="first_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Имя"
              {...field}
              value={field.value.replace(
                validationRegex.lettersSpacesHyphenDashExcept,
                "",
              )}
              onChange={(e) => {
                e.target.value = e.target.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                );
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="middle_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              placeholder="Отчество"
              {...field}
              value={field.value?.replace(
                validationRegex.lettersSpacesHyphenDashExcept,
                "",
              )}
              onChange={(e) => {
                e.target.value = e.target.value.replace(
                  validationRegex.lettersSpacesHyphenDashExcept,
                  "",
                );
                field.onChange(e);
              }}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_day"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min={validationBounds.Day.Min}
              max={validationBounds.Day.Max}
              step="1"
              placeholder="День"
              onPaste={onDayPaste}
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_month"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min={validationBounds.Month.Min}
              max={validationBounds.Month.Max}
              step="1"
              placeholder="Месяц"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_year"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min={validationBounds.Year.Min}
              max={validationBounds.Year.Max}
              step="1"
              placeholder="Год"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <div className={s.noop} />
        {viewToggle}
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
